import { isEmpty } from "lodash-es";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function Sidebar() {
    const history = useHistory();
    const dispatch = useDispatch();
    const authProps = useSelector(state => state.auth);
    const stepsProps = useSelector(state => state.steps);

    useEffect(() => {
        if (isEmpty(stepsProps)) {
            dispatch({
                type: "GET_USER_STEPS",
                payload: {}
            });
        } else {
            if (stepsProps?.step1 === false || stepsProps?.step2 === false) {
                dispatch({
                    type: "GET_USER_STEPS",
                    payload: {}
                });
            }
        }
    }, [authProps]);

    function ToggleDropdown() {
        let sidebar = document.getElementsByClassName('has-dropdown')[0];
        if(sidebar.classList.contains('is-active')) {
            sidebar.classList.remove('is-active');
        } else {
            sidebar.classList.add('is-active');
        }
    }

    return (
        <aside className="menu sidebar">
            <div className="is-flex is-flex-direction-column h-full sidebar-in">
                <div className="navbar-brand is-hidden-mobile is-hidden-tablet-only has-background-primary">
                    <div className="navbar-item brand-text mx-auto" onClick={() => history.push('/dashboard')}>
                        <img src="/images/logo.png" alt="" /> <span className="has-text-white is-size-4">Shop Share Paid</span>
                    </div>
                </div>
                <div className="pt-5">
                    <p className="menu-label px-4 has-text-white mt-1"> General </p>
                    <ul className="menu-list">
                        <li className={`${window.location.href.includes('dashboard') ? 'active' : ''}`}>
                            <a className="is-flex" onClick={(e) => history.push('/dashboard')}>
                                <span className="icon-text">
                                    <span className="icon">
                                        <i className="fa fa-home"></i>
                                    </span>
                                    <span>Home</span>
                                </span>
                            </a>
                        </li>
                        <li className={`${window.location.href.includes('how-it-works') ? 'active' : ''}`}>
                            <a className="is-flex" onClick={(e) => history.push('/how-it-works')}>
                                <span className="icon-text">
                                    <span className="icon">
                                        <i className="fa fa-question"></i>
                                    </span>
                                    <span>How it works</span>
                                </span>
                            </a>
                        </li>
                    </ul>
                    <p className="menu-label px-4 has-text-white mt-5"> Affiliates </p>
                    <ul className="menu-list">
                        <li className={`${window.location.href.includes('my-affiliates') ? 'active' : ''}`}>
                            <a className="is-flex" onClick={(e) => history.push('/my-affiliates')}>
                                <span className="icon-text">
                                    <span className="icon">
                                        <i className="fa fa-users"></i>
                                    </span>
                                    <span>My Affiliates</span>
                                </span>
                            </a>
                        </li>
                        <li className={`${window.location.href.includes('manual-affiliates') ? 'active' : ''}`}>
                            <a className="is-flex" onClick={(e) => history.push('/manual-affiliates')}>
                                <span className="icon-text">
                                    <span className="icon">
                                        <i className="fa fa-pencil-square-o"></i>
                                    </span>
                                    <span>Create Affiliates Manually</span>
                                </span>
                            </a>
                        </li>
                        <li className={`${window.location.href.includes('super-affiliates') ? 'active' : ''}`}>
                            <a className="is-flex" onClick={(e) => history.push('/super-affiliates')}>
                                <span className="icon-text">
                                    <span className="icon">
                                        <i className="fa fa-user"></i>
                                    </span>
                                    <span>Super Affiliates</span>
                                </span>
                            </a>
                        </li>
                        <li className="navbar-item has-dropdown is-flex-direction-column">
                            <a className="is-flex" onClick={() => ToggleDropdown()}>
                                <span className="icon-text">
                                    <span className="icon">
                                        <i className="fa fa-refresh"></i>
                                    </span>
                                    <span>Back Populate</span>
                                </span>
                                <span className="icon ml-auto">
                                    <i className="fa fa-chevron-down"></i>
                                </span>
                            </a>
                            <div className="navbar-dropdown is-relative">
                                <li className={`navbar-item ${window.location.href.includes('manual-populate-affiliates') ? 'active' : ''}`} >
                                    <a className="w-full" onClick={(e) => history.push('/manual-populate-affiliates')}>
                                        Back Populate
                                    </a>
                                </li>
                                <li className={`navbar-item ${window.location.href.includes('premium-populate-affiliates') ? 'active' : ''}`}>
                                    <a className="w-full" onClick={(e) => history.push('/premium-populate-affiliates')}>
                                        Back Populate Premium
                                    </a>
                                </li>
                            </div>
                        </li>
                        <li className={`${window.location.href.includes('affiliate-settings') ? 'active' : ''}`}>
                            <a className="is-flex" onClick={(e) => history.push('/affiliate-settings')}>
                                <span className="icon-text">
                                    <span className="icon">
                                        <i className="fa fa-gears"></i>
                                    </span>
                                    <span>Affiliate Settings</span>
                                </span>
                                {
                                    stepsProps?.step1 ? "" : <span className="pending-step has-tooltip-arrow" data-tooltip="Complete Affiliate Settings">
                                        <span className="step-text has-background-warning has-text-black has-text-weight-bold">Step 1</span>
                                    </span>
                                }
                            </a>
                        </li>
                        <li className={`${window.location.href.includes('customize-messages') ? 'active' : ''}`}>
                            <a className="is-flex" onClick={(e) => history.push('/customize-messages')}>
                                <span className="icon-text">
                                    <span className="icon">
                                        <i className="fa fa-envelope"></i>
                                    </span>
                                    <span>Customize Messages</span>
                                </span>
                            </a>
                        </li>
                    </ul>
                    <p className="menu-label px-4 has-text-white mt-5"> Payments </p>
                    <ul className="menu-list">
                        <li className={`${window.location.href.includes('payouts') ? 'active' : ''}`}>
                            <a className="is-flex" onClick={(e) => history.push('/payouts')}>
                                <span className="icon-text">
                                    <span className="icon">
                                        <i className="fa fa-dollar"></i>
                                    </span>
                                    <span>Payouts</span>
                                </span>
                            </a>
                        </li>
                        <li className={`${window.location.href.includes('payments') ? 'active' : ''}`}>
                            <a className="is-flex" onClick={(e) => history.push('/payments')}>
                                <span className="icon-text">
                                    <span className="icon">
                                        <i className="fa fa-gear"></i>
                                    </span>
                                    <span>Payment Settings</span>
                                </span>
                                {
                                    stepsProps?.step2 ? "" : <span className="pending-step has-tooltip-arrow" data-tooltip="Add Card Details">
                                        <span className="step-text has-background-warning has-text-black has-text-weight-bold">Step 2</span>
                                    </span>
                                }
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="mt-auto px-2 mb-4">
                    <ul className="menu-list">
                        <li className="my-5">
                            <img className="image max-w-100 mx-auto" src="images/affiliate-market-graphic.svg" alt="Affiliate MArketing" />
                        </li>
                        <li>
                            <a className="is-flex p-2" href="https://paytron.com.au/" target="_blank" rel="noopener noreferrer">
                                <img className="image max-w-80 mx-auto" src="images/paytron-logo.png" alt="paytron logo" />
                            </a>
                        </li>
                        <li className="pb-4">
                            <a className="is-flex p-0" href="mailto:contact@shopsharepaid.com" target="_blank" rel="noopener noreferrer">
                                <span className="icon-text">
                                    <span className="icon"><i className="fa fa-question-circle"></i></span>
                                    <span>Help</span>
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </aside>
    )
}

export default Sidebar;