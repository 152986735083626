import React from 'react';

import AffiliateLayout from '../layouts/affiliate-layout';

function HowToGetPaid(props) {

    return (
        <AffiliateLayout {...props} >
            <section className="pb-3">
                <div className="container is-max-widescreen">
                    <h1 className="title is-size-4 mb-2 has-text-primary"> How to get paid </h1>
                </div>
            </section>

            <div className="container is-max-widescreen">
                <div className="box p-0">
                    <div className="box-content p-4">
                        <div className="mb-2">
                            Please follow one of the modes below as stores can opt for both cash based or credit based payouts
                        </div>

                        <div className="is-size-4 has-text-info-dark has-text-weight-bold mb-2">Get paid in Cash</div>
                        <div className="is-size-5m6 has-text-danger has-text-weight-semibold mt-3">Step 1</div>
                        <img className="mt-1" src={`${process.env.REACT_APP_APP_ENDPOINT}/images/tutorials/1-1.png`} alt="how it works" />
                        <div className="is-size-5m6 has-text-danger has-text-weight-semibold mt-3">Step 2</div>
                        <img className="mt-1" src={`${process.env.REACT_APP_APP_ENDPOINT}/images/tutorials/1-2.png`} alt="how it works" />
                        <div className="is-size-5m6 has-text-danger has-text-weight-semibold mt-3">Step 3</div>
                        <img className="mt-1" src={`${process.env.REACT_APP_APP_ENDPOINT}/images/tutorials/1-3.png`} alt="how it works" />

                        <div className="is-size-4 has-text-info-dark has-text-weight-bold mt-5 mb-2">Get paid in Store Credits</div>
                        <div className="is-size-5m6 has-text-danger has-text-weight-semibold mt-3">Step 1</div>
                        <img className="mt-1" src={`${process.env.REACT_APP_APP_ENDPOINT}/images/tutorials/2-1.png`} alt="how it works" />
                        <div className="is-size-5m6 has-text-danger has-text-weight-semibold mt-3">Step 2</div>
                        <img className="mt-1" src={`${process.env.REACT_APP_APP_ENDPOINT}/images/tutorials/2-2.png`} alt="how it works" />
                        <div className="is-size-5m6 has-text-danger has-text-weight-semibold mt-3">Step 3</div>
                        <img className="mt-1" src={`${process.env.REACT_APP_APP_ENDPOINT}/images/tutorials/2-3.png`} alt="how it works" />
                    </div>
                </div>
            </div>
        </AffiliateLayout>
    )
}

export default HowToGetPaid;