import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import moment from "moment";

import { Price } from "../helpers";
import DashboardLayout from '../layouts/dashboard-layout';

function Payouts(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const authProps = useSelector(state => state.auth);
    const payoutsProps = useSelector(state => state.payouts);
    const [payouts, setPayouts] = useState({});

    useEffect(() => {
        dispatch({
            type: "FETCH_PAYOUTS",
            payload: {}
        });
    }, []);

    useEffect(() => {
        if (!isEmpty(payoutsProps)) {
            setPayouts(payoutsProps);
        }
    }, [payoutsProps]);

    return (
        <DashboardLayout {...props}>
            <section className="pb-3">
                <div className="is-md-flex is-align-items-center is-justify-content-space-between">
                    <div>
                        <h1 className="title is-size-4 is-size-4-tablet mb-2 has-text-primary"> Payouts </h1>
                    </div>
                </div>
            </section>

            <div className="columns is-multiline">
                <div className="column is-12">
                    <div className="box">
                        <div className="table-container">
                            <table className="table is-striped is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>Affiliate</th>
                                        <th>Customer</th>
                                        <th>Discount Code</th>
                                        <th>Discount Value</th>
                                        <th>Affiliate Commission <span className="has-text-info pending-step has-tooltip-bottom has-tooltip-arrow" data-tooltip="This is what we pay your affiliates"><i className="fa fa-lg fa-info-circle"></i></span></th>
                                        <th>Transaction Fee <span className="has-text-info pending-step has-tooltip-bottom has-tooltip-arrow" data-tooltip="This is our service fee which includes the bank fee charged to us"><i className="fa fa-lg fa-info-circle"></i></span></th>
                                        <th>Total Charged</th>
                                        <th>Payout Method</th>
                                        <th>Payout Status</th>
                                        <th>Created</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        Object.values(payouts).map((payout, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{payout?.affiliate?.firstname} {payout?.affiliate?.lastname}</td>
                                                    <td>{payout?.buyer?.firstname} {payout?.buyer?.lastname}</td>
                                                    <td className="has-text-weight-bold">{payout?.coupon?.title}</td>
                                                    <td className="has-text-weight-semibold">{payout?.coupon?.type == "fixed_amount" ? `${payout?.coupon?.value} ${payout?.payout?.currency}` : `${payout?.coupon?.value}%`}</td>
                                                    <td className="has-text-weight-semibold">{Price.ConvertTemplate(payout?.payout?.affiliate_payout, `{{amount}} ${payout?.payout?.currency}`)}</td>
                                                    <td className="has-text-weight-semibold">{payout?.payout?.charge_method === "bycash" ? Price.ConvertTemplate(payout?.payout?.app_payout, `{{amount}} ${payout?.payout?.currency}`) : "N/A"}</td>
                                                    <td className="has-text-weight-semibold">{payout?.payout?.charge_method === "bycash" ? Price.ConvertTemplate(payout?.payout?.affiliate_payout + payout?.payout?.app_payout, `{{amount}} ${payout?.payout?.currency}`) : Price.ConvertTemplate(payout?.payout?.affiliate_payout + payout?.payout?.app_payout, `{{amount}} ${payout?.payout?.currency}`)}</td>
                                                    <td>
                                                        {payout?.payout?.charge_method === "bycash" ? <span className="tag is-primary is-light has-text-weight-semibold border border-solid border-color-primary">By Cash</span> : ""}
                                                        {payout?.payout?.charge_method === "bycredits" ? <span className="tag is-link is-light has-text-weight-semibold border border-solid border-color-link">By In-store credits</span> : ""}
                                                    </td>
                                                    <td>
                                                        {payout.status === "pending" ? <span className="tag is-warning is-light has-text-weight-semibold border border-solid border-color-warning">Pending</span> : ""}
                                                        {payout.status === "complete" ? <span className="tag is-success is-light has-text-weight-semibold border border-solid border-color-success">Complete</span> : ""}
                                                        {payout.status === "cancelled" ? <span className="tag is-dark is-light has-text-weight-semibold border border-solid border-color-primary">Order Cancelled</span> : ""}
                                                        {payout.status === "managed" || payout.status === "refunded" ? <span className="tag is-dark is-light has-text-weight-semibold border border-solid border-color-primary">Refunded (Order Cancelled)</span> : ""}
                                                    </td>
                                                    <td>{moment(payout.created_at).format('D MMM, YYYY')}</td>
                                                    <td>
                                                        <a href={`https://${authProps?.shop}/admin/orders/${payout?.affiliate?.order_id}`} target="_blank" rel="noopener noreferrer" className="button is-danger has-text-white is-small mb-1">Affiliate Order</a> <br />
                                                        <a href={`https://${authProps?.shop}/admin/orders/${payout?.buyer?.order_id}`} target="_blank" rel="noopener noreferrer" className="button is-primary has-text-white is-small mb-1">Customer Order</a> <br />
                                                        {
                                                            payout?.payout?.charge_method === "bycash" ?
                                                                <a href={payout.payout.charge_data.receipt_url} target="_blank" rel="noopener noreferrer" className="button is-success has-text-white is-small">Invoice</a>
                                                                : ""
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            {
                                isEmpty(payouts) ? <p className="pb-5 has-text-centered is-fullwidth">No payout requests made</p> : ""
                            }
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}

export default Payouts;