import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function ToggleSidebar() {
    let sidebar = document.getElementsByClassName('sidebar')[0];
    if(sidebar.classList.contains('show')) {
        sidebar.classList.remove('show');
    } else {
        sidebar.classList.add('show');
    }
}

function AdminHeader() {
    const history = useHistory();
    const authProps = useSelector(state => state.auth);

    return (
        <div className="header py-2 px-4">
            <nav className="navbar" role="navigation" aria-label="main navigation">
                <div className="is-flex is-justify-content-space-between w-full is-align-items-center header-minimal">
                    <a role="button" className="navbar-burger burger ml-0" data-target="navMenu" aria-label="menu" aria-expanded="false" onClick={() => ToggleSidebar()}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </a>
                    <div className="navbar-brand is-flex-grow-1">
                        <div className="navbar-item brand-text" onClick={() => history.push('/admin/dashboard')}>
                            <img src="/images/logo.png" alt="" />
                        </div>
                    </div>
                    <div className="dropdown is-right is-hoverable">
                        <div className="dropdown-trigger">
                            <button className="button is-primary" aria-haspopup="true" aria-controls="dropdown-menu">
                                <span className="user-name-character is-block has-text-white">{authProps?.firstname.charAt(0).toUpperCase()}</span>
                                <span className="icon">
                                    <i className="is-size-5 fa fa-angle-down" aria-hidden="true"></i>
                                </span>
                            </button>
                        </div>
                        <div className="dropdown-menu" id="dropdown-menu" role="menu">
                            <div className="dropdown-content">
                                <p className="pt-4 px-5 mb-4 has-text-weight-bold is-size-5m6">Hi, {authProps.firstname}</p>
                                <a onClick={() => history.push('/admin/profile')} className="is-clickable dropdown-item pr-3"><span className="icon-text"><span className="icon"><i className="fa fa-user"></i></span><span>Profile Settings</span></span></a>
                                <hr className="dropdown-divider" />
                                <a className="dropdown-item has-text-danger" onClick={() => history.push('/admin/logout')}> <span className="icon-text"><span className="icon"><i className="fa fa-power-off"></i></span><span>Logout</span></span> </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="navMenu" className="navbar-menu">
                    <div className="navbar-end">
                        <div className="navbar-item has-dropdown is-hoverable">
                            <div className="navbar-link">
                                <span className="user-name-character is-block has-text-white">{authProps?.firstname.charAt(0).toUpperCase()}</span>
                            </div>
                            <div className="navbar-dropdown is-right loggedin-user-dd">
                                <p className="pt-4 px-5 mb-4 has-text-weight-bold is-size-5m6">Hi, {authProps.firstname}</p>
                                <a onClick={() => history.push('/admin/profile')} className="is-clickable dropdown-item pr-3"> <span className="icon-text"><span className="icon"><i className="fa fa-user"></i></span><span>Profile Settings</span></span> </a>
                                <hr className="navbar-divider" />
                                <a onClick={() => history.push('/admin/logout')} className="navbar-item is-clickable dropdown-item pr-3 has-text-danger"> <span className="icon-text"><span className="icon"><i className="fa fa-power-off"></i></span><span>Logout</span></span> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default AdminHeader;