import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

import AdminLayout from '../layouts/admin-layout';

function AdminDashboard(props) {
    const dispatch = useDispatch();
    const authProps = useSelector(state => state.auth);
    const detailsProps = useSelector(state => state.details);

    const [details, setDetails] = useState({});

    useEffect(() => {
        if (!isEmpty(detailsProps)) {
            setDetails(detailsProps);
        }
    }, [detailsProps]);

    useEffect(() => {
        dispatch({
            type: "ADMIN_DASHBOARD_DETAILS",
            payload: {}
        });
    }, []);

    return (
        <AdminLayout {...props} >
            <section className="welcome pb-3">
                <h1 className="title is-size-4 has-text-primary mb-2"> Hello, {authProps.firstname}  {authProps.lastname} </h1>
                <p className="is-size-5m6">We hope you are having a great day!</p>
            </section>
            <section className="mt-5">
                <div className="columns is-multiline">
                    <div className="column is-half-tablet is-one-quarter-desktop">
                        <div className="box has-background-blue-light h-full is-flex is-justify-content-center is-align-items-center has-text-centered">
                            <div>
                                <p className="title is-size-2">{details.totalUsers}</p>
                                <p className="subtitle is-size-6 has-text-weight-medium">No. of Users</p>
                            </div>
                            <div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-half-tablet is-one-quarter-desktop">
                        <div className="box has-background-pink-light h-full is-flex is-justify-content-center is-align-items-center has-text-centered">
                            <div>
                                <p className="title is-size-2">{details.activeUsers}</p>
                                <p className="subtitle is-size-6 has-text-weight-medium">Active Users</p>
                            </div>
                            <div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-half-tablet is-one-quarter-desktop">
                        <div className="box has-background-purple-light h-full is-flex is-justify-content-center is-align-items-center has-text-centered">
                            <div>
                                <p className="title is-size-2">{details.inactiveUsers}</p>
                                <p className="subtitle is-size-6 has-text-weight-medium">Inactive Users</p>
                            </div>
                            <div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-half-tablet is-one-quarter-desktop">
                        <div className="box has-background-orange-light h-full is-flex is-justify-content-center is-align-items-center has-text-centered">
                            <div>
                                <p className="title is-size-2">{details.totalAffiliates}</p>
                                <p className="subtitle is-size-6 has-text-weight-medium">No. of Affiliates</p>
                            </div>
                            <div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </AdminLayout>
    )
}

export default AdminDashboard;