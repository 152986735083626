import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { isEmpty, startCase } from "lodash";
import moment from "moment";

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import DashboardLayout from '../layouts/dashboard-layout';

function Dashboard(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const authProps = useSelector(state => state.auth);
    const detailsProps = useSelector(state => state.details);
    const affiliateProps = useSelector(state => state.affiliates);
    const [details, setDetails] = useState({});
    const [affiliates, setAffiliates] = useState({});

    let location = new URLSearchParams(useLocation().search);
    let chargeID = location.get("charge_id");

    useEffect(() => {
        if (!isEmpty(detailsProps)) {
            setDetails(detailsProps);
        }
    }, [detailsProps]);

    useEffect(() => {
        if (!isEmpty(affiliateProps)) {
            setAffiliates(affiliateProps.results);
        }
    }, [affiliateProps]);

    useEffect(() => {
        dispatch({
            type: "DASHBOARD_DETAILS",
            payload: {}
        });

        dispatch({
            type: "FETCH_AFFILIATES",
            payload: {
                type: "all",
                page: 0,
                limit: 10
            }
        });
    }, []);

    useEffect(() => {
        if (chargeID !== null) {
            dispatch({
                type: "SET_RECURRING_CHARGE",
                payload: {
                    id: chargeID
                }
            });
        }
    }, [chargeID]);

    return (
        <DashboardLayout {...props}>
            <section className="pb-3">
                <h1 className="title is-size-4 is-size-4-tablet mb-2 has-text-primary"> Hello, {authProps.firstname}  {authProps.lastname} </h1>
                <p className="is-size-5m6"> We hope you are having a great day! </p>
            </section>

            <section>
                <div className="message is-info mb-3">
                    <div className="message-body">
                        <b>New features coming soon</b>
                        <ul>
                            <li>- You will soon be able to create a discount based on individual products.</li>
                        </ul>
                    </div>
                </div>
                <div className="columns is-multiline">
                    <div className="column">
                        <div className="columns is-multiline">
                            <div className="column is-half-tablet is-one-quarter-desktop">
                                <div className="box has-background-blue-light h-full is-flex is-justify-content-center is-align-items-center has-text-centered">
                                    <div>
                                        <p className="title is-size-2">{details.ordersToday}</p>
                                        <p className="subtitle is-size-6 has-text-weight-medium">Orders from affiliates today</p>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-half-tablet is-one-quarter-desktop">
                                <div className="box has-background-pink-light h-full is-flex is-justify-content-center is-align-items-center has-text-centered">
                                    <div>
                                        <p className="title is-size-2">{details.ordersWeek}</p>
                                        <p className="subtitle is-size-6 has-text-weight-medium">Orders from affiliates this week</p>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-half-tablet is-one-quarter-desktop">
                                <div className="box has-background-purple-light is-flex h-full is-justify-content-center is-align-items-center has-text-centered">
                                    <div>
                                        <p className="title is-size-2">{details.ordersMonth}</p>
                                        <p className="subtitle is-size-6 has-text-weight-medium">Orders from affiliates this month</p>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-half-tablet is-one-quarter-desktop">
                                <div className="box has-background-orange-light h-full is-flex is-justify-content-center is-align-items-center has-text-centered">
                                    <div>
                                        <p className="title is-size-2">{details.affiliatesTotal}</p>
                                        <p className="subtitle is-size-6 has-text-weight-medium">No. of Affiliates</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box">
                            <div className="is-flex is-align-items-center is-justify-content-space-between">
                                <h1 className="title is-size-5 has-text-primary mb-2">Order Analytics</h1>
                            </div>
                            <div className="box-content">
                                <ResponsiveContainer width={'100%'} height={400}>
                                    <LineChart
                                        width={500}
                                        height={300}
                                        data={details.ordersGraph}
                                        margin={{ top: 30, right: 20, left: 0, bottom: 0, }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="date" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Line type="monotone" dataKey="count" name="Order Count" dot={{ stroke: '#DE4A7B', strokeWidth: 5 }} strokeWidth={4} stroke="#29348E" />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        <div className="box">
                            <div className="is-md-flex is-align-items-center is-justify-content-space-between">
                                <div>
                                    <h1 className="title is-size-5 has-text-primary mb-2">Recent Affiliates</h1>
                                </div>
                                <div><button className="button is-primary is-xsmall with-shadow" onClick={() => history.push('/my-affiliates')}>View All Affiliates</button></div>
                            </div>
                            <div className="table-container">
                                <table className="table is-striped is-fullwidth">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>First name</th>
                                            <th>Last name</th>
                                            <th>Email Address</th>
                                            <th>Discount Code</th>
                                            <th>Type</th>
                                            <th>Value</th>
                                            <th>Discount Status</th>
                                            <th>Created On</th>
                                            <th>Expires On</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !isEmpty(affiliates) && Object.values(affiliates).map((affiliate, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{affiliate.affiliate.firstname}</td>
                                                        <td>{affiliate.affiliate.lastname}</td>
                                                        <td>{affiliate.affiliate.email}</td>
                                                        <td className="has-text-weight-bold"><span className="is-flex">{affiliate.coupon.title} <span data-tooltip="Copy Discount Code"><i className="fa fa-clone is-clickable ml-1 has-text-danger" onClick={() => navigator.clipboard.writeText(affiliate.coupon.title)}></i></span></span></td>
                                                        <td>{startCase(affiliate.coupon.type)}</td>
                                                        <td>{affiliate.coupon.type === "fixed_amount" ? `$${affiliate.coupon.value}` : `${affiliate.coupon.value}%`}</td>
                                                        <td>{affiliate.status === "expired" ? <span className="tag is-danger is-light has-text-weight-semibold border border-solid border-color-danger">{startCase(affiliate.status)}</span> : <span className="tag is-success is-light has-text-weight-semibold border border-solid border-color-success">{startCase(affiliate.status)}</span>}</td>
                                                        <td>{moment(affiliate.coupon.starts_at).format('D MMM, YYYY')}</td>
                                                        <td>{affiliate.coupon.ends_at === null ? "N/A" : moment(affiliate.coupon.ends_at).format('D MMM, YYYY')}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                {
                                    isEmpty(affiliates) ? <p className="pb-5 has-text-centered is-fullwidth">No Affiliates found</p> : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </DashboardLayout>
    )
}

export default Dashboard;