import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import Swal from "sweetalert2";

import { LoaderContext } from '../loader';
import DashboardLayout from '../layouts/dashboard-layout';

function ManualPopulateAffiliates(props) {
    const dispatch = useDispatch();
    const authProps = useSelector(state => state.auth);
    const fileProps = useSelector(state => state.file);
    const importProps = useSelector(state => state.import);
    const { setLoading } = useContext(LoaderContext);

    const [isExport, setisExport] = useState(false);

    useEffect(() => {
        setLoading(false);
        if (!isEmpty(fileProps) && isExport) {
            window.open(fileProps.file);
        }
    }, [fileProps]);

    useEffect(() => {
        setLoading(false);
    }, [importProps]);

    function ExportList() {
        if (authProps?.plan_details?.name !== "Starter") {
            Swal.fire({
                title: 'Please be patient',
                html: `<b>It may take a few minutes to generate this CSV. Please do not refresh your page</b>. <br><br> <div className="border border-2 border-dashed border-primary p-4"><span className="has-text-danger has-text-weight-bold">Important: </span> Please ensure popup blockers for this page are disabled otherwise your CSV will not download. Many browsers having blocking turned on by default and you will need to allow popups from this site</div>`,
                icon: 'warning',
                showConfirmButton: true,
                confirmButtonText: "Ok"
            }).then((result) => {
                if (result.isConfirmed) {
                    setisExport(true);
                    setLoading(true);

                    dispatch({
                        type: "EXPORT_CUSTOMERS_LIST",
                        payload: {}
                    });
                }
            });
        } else {
            Swal.fire({
                icon: "error",
                title: "Manual Back Populate",
                html: "This feature is only available on the Pro and Enterprise Plan. Please upgrade your plan if you wish to use this feature."
            });
        }
    }

    function ImportList(e) {
        if (authProps?.plan_details?.name !== "Starter") {
            setLoading(true);
            var allFiles = Array.from(e.target.files);

            const formData = new FormData();
            for (let i = 0; i < allFiles.length; i++) {
                formData.append('data', allFiles[i], allFiles[i].name);
                formData.append('type', e.target.name);
            }

            dispatch({
                type: "IMPORT_CUSTOMERS_LIST",
                payload: formData
            });
        } else {
            Swal.fire({
                icon: "error",
                title: "Manual Back Populate",
                html: "This feature is only available on the Pro and Enterprise Plan. Please upgrade your plan if you wish to use this feature."
            });
        }
    }

    function StartPopulating() {
        if (authProps.plan_details.name !== "Starter") {
            setLoading(true);

            dispatch({
                type: "POPULATE_CUSTOMERS_LIST",
                payload: {}
            });

            setTimeout(() => {
                setLoading(false);

                Swal.fire({
                    icon: "info",
                    title: "Processing Successfull",
                    html: "Please allow one hour before sending your email campaign."
                });
            }, 5000);
        } else {
            Swal.fire({
                icon: "error",
                title: "Manual Back Populate",
                html: "This feature is only available on the Pro and Enterprise Plan. Please upgrade your plan if you wish to use this feature."
            });
        }
    }

    return (
        <DashboardLayout {...props} >
            <section className="pb-3">
                <div className="container is-max-widescreen">
                    <h1 className="title is-size-4 mb-2 has-text-primary"> Back Populate </h1>
                </div>
            </section>

            <div className="columns is-multiline">
                <div className="column is-12">
                    <div className="container is-max-widescreen">
                        <div className="box px-0 pt-0">
                            <div className="head py-4 px-5">
                                <p className="title is-size-4 has-text-primary">Instructions</p>
                            </div>
                            <div className="box-content px-5">
                                <div className="is-flex is-size-5m6">
                                    <div className="is-flex is-align-items-center">
                                        <span className="tag is-danger is-light has-text-weight-bold px-2 py-4 is-size-6 mr-2">Step 1:</span>
                                        <div> Download CSV of your entire customer list by clicking the Download CSV button. </div>
                                    </div>
                                    <button className="button is-info-dark with-shadow mb-0 ml-auto is-size-6" onClick={() => ExportList()}><i className="fa fa-download fa-lg mr-2"></i> Download CSV</button>
                                </div>
                                <hr />
                                <div className="is-flex is-size-5m6">
                                    <div className="is-flex">
                                        <span className="tag is-danger is-light has-text-weight-bold px-2 py-4 is-size-6 mr-2">Step 2:</span>
                                        <div> Remove any contacts from your CSV that you do not want to be part of your affiliate program. Add any additional contacts you want to invite to your affiliate program, such as influencers and so on. </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="is-flex is-size-5m6">
                                    <div className="is-flex is-align-items-center">
                                        <span className="tag is-danger is-light has-text-weight-bold px-2 py-4 is-size-6 mr-2">Step 3:</span>
                                        <div> Import your edited CSV file. </div>
                                    </div>
                                    <div className="file is-primary is-centered is-boxed ml-auto is-size-6">
                                        <label className="file-label pb-3">
                                            <input className="file-input" type="file" name="csv_file" onChange={(e) => ImportList(e)} multiple />
                                            <span className="button is-info-dark with-shadow">
                                                <span className="file-icon"><i className="fa fa-upload"></i></span>
                                                <span className="file-label">Upload CSV</span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <hr />
                                <div className="is-flex is-size-5m6">
                                    <div className="is-flex is-align-items-center">
                                        <span className="tag is-danger is-light has-text-weight-bold px-2 py-4 is-size-6 mr-2">Step 4:</span>
                                        <div> Click the start back populating button. </div>
                                    </div>
                                    <button className="button is-success with-shadow mb-0 ml-auto is-size-5m6" onClick={() => StartPopulating()}><i className="fa fa-arrow-right fa-lg mr-2"></i> Start Back Populating</button>
                                </div>
                                <hr />
                                <div className="is-flex is-size-5m6">
                                    <div className="is-flex is-align-items-center">
                                        <span className="tag is-danger is-light has-text-weight-bold px-2 py-4 is-size-6 mr-2">Step 5:</span>
                                        <div> Import your CSV file into your chosen email platform such as Klaviyo, Active Campaign, Mailchimp etc. </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="is-flex is-size-5m6">
                                    <div className="is-flex">
                                        <span className="tag is-danger is-light has-text-weight-bold px-2 py-4 is-size-6 mr-2">Step 6:</span>
                                        <div className="pr-3">
                                            Copy the affiliate activation link below and include it in your email. This is the link they need to click to join your program. You mustn't share this link with anyone not in your CSV as it will not work since we use the CSV to create accounts in our database and then link it to the email when they register. If you forgot to add someone, create a new CSV with the missing names, import it again in Step 3 and continue doing Step 4 to 7.
                                            <div className="mt-5 is-flex is-justify-content-flex-end is-align-items-center">
                                                <span className="border border-1 border-solid border-color-success has-background-success-light p-2 mr-2">{process.env.REACT_APP_APP_ENDPOINT}/affiliate/register?shop={authProps?.shop}</span>
                                                <button className="button is-success with-shadow mb-0 is-size-5m6" onClick={() => navigator.clipboard.writeText(`${process.env.REACT_APP_APP_ENDPOINT}/affiliate/register?shop=${authProps?.shop}`)}><i className="fa fa-copy fa-lg mr-2"></i>Copy</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="is-flex is-size-5m6">
                                    <div className="is-flex">
                                        <span className="tag is-danger is-light has-text-weight-bold px-2 py-4 is-size-6 mr-2">Step 7:</span>
                                        <div> Send your campaign to your new list. You will start getting affiliates signing up. If not continue to email them with a daily email flow. Just keep it short, honest and straight to the point. Tell them how easy it is to earn whilst promoting your products. They already love your products since they are existing customers so it shouldn't be hard to convince them. </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="mb-3 is-size-5m6 border border-2 border-dashed border-color-primary has-background-info-light p-4">
                                    <span className="has-text-danger has-text-weight-bold">Tips:</span> To avoid landing in spam, you should verify your domain with your email provider by adding txt or cname records to your domain DNS settings. If you have more than 1000 emails and don't normally send many emails, I suggest sending 100 per hour. Klaviyo allows you to do this, and many other email platforms do also have this feature. If they don't, just split your list and send a little each time. This will make a huge difference and help you land directly in their inbox. Please don't add a heap of graphics and images either, as this will lower the chance of making it to their inbox. In the subject line, you should avoid using all upper case letters. Try and write the email as you would to your friends, not like a marketing email unless your domain has been warmed up and sent many emails daily
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}

export default ManualPopulateAffiliates;