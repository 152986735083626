import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import AffiliateLayout from '../layouts/affiliate-layout';
import moment from "moment";

function AffiliateDashboard(props) {
    const dispatch = useDispatch();
    const authProps = useSelector(state => state.auth);
    const detailsProps = useSelector(state => state.details);

    const [details, setDetails] = useState({});

    useEffect(() => {
        if (!isEmpty(detailsProps)) {
            setDetails(detailsProps);
        }
    }, [detailsProps]);

    useEffect(() => {
        dispatch({
            type: "AFFILIATE_DASHBOARD_DETAILS",
            payload: {}
        });
    }, []);

    return (
        <AffiliateLayout {...props} >
            <section className="pb-3">
                <h1 className="title is-size-4 is-size-4-tablet mb-2 has-text-primary"> Hello, {authProps.firstname}  {authProps.lastname} </h1>
                <p className="is-size-5m6"> We hope you are having a great day! </p>
            </section>

            <section className="mt-5">
                <div className="columns is-multiline">
                    <div className="column">
                        <div className="columns is-multiline">
                            <div className="column is-half-tablet is-one-quarter-desktop">
                                <div className="box has-background-blue-light h-full is-flex is-justify-content-center is-align-items-center has-text-centered">
                                    <div>
                                        <p className="title is-size-2">{details.salesToday}</p>
                                        <p className="subtitle is-size-6 has-text-weight-medium">Sales Today</p>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-half-tablet is-one-quarter-desktop">
                                <div className="box has-background-pink-light h-full is-flex is-justify-content-center is-align-items-center has-text-centered">
                                    <div>
                                        <p className="title is-size-2">{details.salesWeek}</p>
                                        <p className="subtitle is-size-6 has-text-weight-medium">Sales this Week</p>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-half-tablet is-one-quarter-desktop">
                                <div className="box has-background-purple-light h-full is-flex is-justify-content-center is-align-items-center has-text-centered">
                                    <div>
                                        <p className="title is-size-2">{details.salesMonth}</p>
                                        <p className="subtitle is-size-6 has-text-weight-medium">Sales this Month</p>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-half-tablet is-one-quarter-desktop">
                                <div className="box has-background-orange-light h-full is-flex is-justify-content-center is-align-items-center has-text-centered">
                                    <div>
                                        <p className="title is-size-2">{details.salesYear}</p>
                                        <p className="subtitle is-size-6 has-text-weight-medium">Sales this Year</p>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box">
                            <div className="is-flex is-align-items-center is-justify-content-space-between">
                                <h1 className="title is-size-5 has-text-primary mb-2">Order Analytics</h1>
                            </div>
                            <div className="box-content">
                                <ResponsiveContainer width={'100%'} height={520}>
                                    <LineChart
                                        width={500}
                                        height={300}
                                        data={details.ordersGraph}
                                        margin={{ top: 30, right: 20, left: 0, bottom: 0, }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="date" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Line type="monotone" dataKey="count" name="Order Count" dot={{ stroke: '#DE4A7B', strokeWidth: 5 }} strokeWidth={4} stroke="#29348E" />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </AffiliateLayout>
    )
}

export default AffiliateDashboard;