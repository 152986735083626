import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

function Logout() {
    const authProps = useSelector(state => state.auth);

    useEffect(() => {
        sessionStorage.clear();
        window.location.href = `https://${authProps.shop}/admin/apps`;
    });

    return null;
}

export default Logout;