import React from 'react';

import DashboardLayout from '../layouts/dashboard-layout';

function HowItWorks(props) {

    return (
        <DashboardLayout {...props} >
            <section className="pb-3">
                <div className="container is-max-widescreen">
                    <h1 className="title is-size-4 mb-2 has-text-primary"> How it works </h1>
                </div>
            </section>

            <div className="container is-max-widescreen">
                <div className="box p-0">
                    <div className="box-content p-4">
                        <div className="mb-2">
                            Shop Share Paid offers an affiliate program to all your customers once they complete their purchase. Upon completing their purchase, they will see an invitation to join your affiliate program, as seen in the image below.
                        </div>
                        <img className="p-5" src={`${process.env.REACT_APP_APP_ENDPOINT}/images/howitworks.png`} alt="how it works" />
                        <div className="mb-2">
                            Once they click this link, they are taken to a sign-up page, and their account is created. They are provided with a discount link they need to share with their friends, family and social network. When anyone purchases from your store using their discount code, they are paid a commission that you set.
                        </div>

                        <div className="is-size-5m6 has-text-info-dark has-text-weight-bold mb-2">Commission Settings</div>
                        <div className="mb-2">
                            You choose the commission you want to pay them every time they bring you a sale, and we handle paying them. You also offer them a discount code that they can promote to bring you new sales.
                        </div>
                        <div className="mb-2">
                            Approximately 1 in 4 affiliates bring in a new sale, and Shop Share Paid only charge a transaction fee plus the commission you need to pay your affiliate when you get a sale. Your affiliate is also not paid for 30 days just in case the sale is refunded, cancelled or returned, in which case they are not eligible for the commission, and we refund it back to you as credit. This way, the affiliate cannot do fraud and get their friends to buy from you and refund the product just to get paid. Once they activate their affiliate account, they can monitor how much they have earned.
                        </div>
                        <div className="mb-2">
                            We charge your credit card once they bring you a sale. Depending on the plan you choose will depend on the transaction fee. We charge 5% of the total order on the free plan and whatever commission you set to pay your affiliate. The price we charge is based on the payment gateways fees incurred to do this transaction.
                        </div>

                        <div className="is-size-5m6 has-text-info-dark has-text-weight-bold mb-2">New feature available from 19th July 2021</div>
                        <div className="mb-2">
                            Instead of rewarding your affiliates with cash you can reward them with in-store credits which means they will only be able to redeem this credit in your store. When they redeem their credits, we create a discount code tied to their email address. For some stores it makes sense to pay cash whilst others in-store credit works better such as apparel and food.
                        </div>

                        <div className="is-size-5m6 has-text-info-dark has-text-weight-bold mb-2">Manual affiliates</div>
                        <div className="mb-2">
                            Suppose you want to invite people who are not customers of your store, such as influencers, to become affiliates. In that case, you will need to create affiliates manually, and you will need to upgrade to the $29.95 Pro plan, which only has a 3.5% transaction fee.
                        </div>

                        <div className="is-size-5m6 has-text-info-dark has-text-weight-bold mb-2">Back Populate your existing customers</div>
                        <div className="mb-2">
                            You can also back populate all your past customers and invite them to join your affiliate program on this plan.
                        </div>

                        <div className="is-size-5m6 has-text-info-dark has-text-weight-bold mb-2">Premium Affiliate management</div>
                        <div className="mb-2">
                            If you want your affiliate program managed entirely by us with custom campaigns and email flows, we recommend an enterprise plan. Email us at contact@shopsharepaid.com to learn more.
                        </div>

                        <div className="is-size-5m6 has-text-info-dark has-text-weight-bold mb-2">Which plan is right for me? </div>
                        <div className="mb-2">
                            If you do not need to back populate or create affiliates manually, then the free plan is perfect for you. However, if you are getting a high volume of sales from your affiliates, we recommend upgrading to the pro plan to lower your transaction fee.
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout >
    )
}

export default HowItWorks;