import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isEmpty, startCase } from "lodash";
import Pagination from "react-js-pagination";
import moment from "moment";
import Swal from "sweetalert2";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { LoaderContext } from '../loader';
import DashboardLayout from '../layouts/dashboard-layout';

function SuperAffiliates(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const { setLoading } = useContext(LoaderContext);
    const [addModal, setAddModal] = useState('');
    const [regenerateModal, setRegenerateModal] = useState('');
    const [selectedCode, setSelectedCode] = useState('');
    const [requests, setRequests] = useState({});
    const [affiliate, setAffiliate] = useState({});
    const [affiliates, setAffiliates] = useState({});
    const affiliateProps = useSelector(state => state.affiliates);
    const couponProps = useSelector(state => state.coupon);
    const requestProps = useSelector(state => state.requests);
    const [search, setSearch] = useState('');

    // Pagination
    const limit = 50;
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        PullData();
    }, [page]);

    useEffect(() => {
        dispatch({
            type: "FETCH_AFFILIATE_REQUESTS",
            payload: {}
        });
    }, []);

    useEffect(() => {
        setRequests(requestProps);
    }, [requestProps]);

    function PullData() {
        setLoading(true);
        dispatch({
            type: "FETCH_AFFILIATES",
            payload: {
                type: "super",
                page: page - 1,
                limit: limit
            }
        });
    }

    useEffect(() => {
        if (!isEmpty(affiliateProps) && affiliateProps.length > 0) {
            setAffiliates(affiliateProps);
            setTotal(affiliateProps[0].total);
        } else {
            setAffiliates([]);
            setTotal(0);
        }

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, [affiliateProps]);

    // Add Affiliate
    const schema = yup.object().shape({
        firstname: yup.string().required(),
        lastname: yup.string().required(),
        email: yup.string().required(),
        affiliate_type: yup.string().required(),
        affiliate_value: yup.number().required(),
        buyer_type: yup.string().required(),
        buyer_value: yup.number().required(),
        buyer_expiry: yup.number().required()
    });

    const { register, handleSubmit, errors, reset } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (form, e) => {
        var appendForm = Object.assign({}, form);
        appendForm.type = 'manual';

        setLoading(true);
        setAddModal('');
        dispatch({
            type: "CREATE_AFFILIATE",
            payload: [appendForm]
        });
        reset();

        setTimeout(() => {
            PullData();

            dispatch({
                type: "FETCH_AFFILIATE_REQUESTS",
                payload: {}
            });
        }, 8000);
    }

    // Regenerate Code
    const schema3 = yup.object().shape({
        id: yup.string(),
        code: yup.string().required(),
        affiliate_type: yup.string().required(),
        affiliate_value: yup.number().required(),
        buyer_type: yup.string().required(),
        buyer_value: yup.number().required(),
        buyer_expiry: yup.number().required()
    });

    const { register: register3, handleSubmit: handleSubmit3, errors: errors3, reset: reset3 } = useForm({
        resolver: yupResolver(schema3)
    });

    const onSubmit3 = (form) => {
        setLoading(true);
        setRegenerateModal('');

        dispatch({
            type: "REGENERATE_DISCOUNT_CODE",
            payload: form
        });

        reset3();
        setTimeout(() => {
            PullData();
            setLoading(false);
        }, 4000);
    }

    function SearchParam(e) {
        setSearch(e.target.value);
    }

    function SearchAffiliates() {
        dispatch({
            type: "FETCH_AFFILIATES",
            payload: {
                type: "super",
                search: search
            }
        });
    }

    function RegenerateDiscountCode(coupon) {
        setRegenerateModal('is-active');
        setSelectedCode(coupon);
    }

    function ExpireDiscountCode(coupon) {
        Swal.fire({
            title: 'Are you sure?',
            html: `This will expire the discount code '<b>${coupon}</b>' immediately.`,
            icon: 'warning',
            showConfirmButton: true,
            confirmButtonText: "Ok"
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);

                dispatch({
                    type: "EXPIRE_DISCOUNT_CODE",
                    payload: {
                        coupon: coupon
                    }
                });
            }
        });
    }

    function RemoveDiscountCode(coupon) {
        Swal.fire({
            title: 'Are you sure?',
            html: `This will remove the discount code '<b>${coupon}</b>' immediately.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: "No"
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);

                dispatch({
                    type: "REMOVE_DISCOUNT_CODE",
                    payload: {
                        coupon: coupon
                    }
                });
            }
        });
    }

    useEffect(() => {
        if (!isEmpty(couponProps)) {
            PullData();
        }
    }, [couponProps]);

    function ApproveAffiliate(data) {
        setAffiliate(data);
        setAddModal('is-active');
    }

    function DeclineAffiliate(data) {
        Swal.fire({
            title: 'Are you sure?',
            html: `This will reject the affiliate request to become an affiliate for your store.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: "No"
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);

                dispatch({
                    type: "REJECT_AFFILIATE_REQUEST",
                    payload: {
                        id: data.affiliate_id
                    }
                });

                setTimeout(() => {
                    setLoading(false);
                    dispatch({
                        type: "FETCH_AFFILIATE_REQUESTS",
                        payload: {}
                    });
                }, 3000);
            }
        });
    }

    return (
        <DashboardLayout {...props} >
            {
                !isEmpty(requests) ?
                    <div className="columns is-multiline mb-2">
                        <div className="column is-12">
                            <h1 className="title is-size-4 is-size-4-tablet mb-2 has-text-primary"> Super Affiliate Requests </h1>
                            <p className="is-size-5m6">Here are your super affiliates, approve or cancel your super affiliates below.</p>
                        </div>
                        <div className="column is-12">
                            <div className="box m-0 py-0 px-3">
                                <table className="table is-fullwidth align-top has-background-transparent">
                                    <thead>
                                        <th>#</th>
                                        <th>Username</th>
                                        <th>Email Address</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </thead>
                                    <tbody>
                                        {
                                            Object.values(requests).map((request, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{request?.affiliate.firstname} {request?.affiliate.lastname}</td>
                                                        <td>{request?.affiliate?.email}</td>
                                                        <td><span className="tag is-warning is-light has-text-weight-semibold border border-solid border-color-warning">{startCase(request?.status)}</span></td>
                                                        <td>
                                                            <button className="button is-success is-small mr-2" onClick={(e) => ApproveAffiliate(request)}>Approve</button>
                                                            <button className="button is-danger is-small" onClick={(e) => DeclineAffiliate(request)}>Decline</button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> : ""
            }

            <section>
                <div className="is-md-flex is-align-items-center is-justify-content-space-between">
                    <div>
                        <h1 className="title is-size-4 is-size-4-tablet mb-2 has-text-primary"> Super Affiliates </h1>
                        <p className="is-size-6 mb-4">Super affiliates are affiliates of multiple stores and usually the best performing. <br />To allow your store to have super affiliates <a className="has-text-weight-bold has-text-danger" onClick={() => history.push('/affiliates/affiliate-settings')}>click here</a> or go to the affiliate settings.</p>
                    </div>
                    <div className="is-flex">
                        <div className="field has-addons mr-2">
                            <div className="control">
                                <input className="input" type="text" onChange={(e) => SearchParam(e)} placeholder="Search for affiliates" />
                            </div>
                            <div className="control">
                                <a className="button is-info h-full" onClick={() => SearchAffiliates()}> <i className="fa fa-search"></i> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="message is-warning mb-5">
                <div className="message-body">
                    <span className="has-text-weight-bold has-text-danger">Warning:</span> Do not delete your discount code from the Shopify Discount section. Instead, click the <button className="button is-small is-danger" style={{ "top": "-5px" }}>Expire Discount Code</button> to disable the discount code that was created using Shop Share Paid.
                </div>
            </div>

            <div className="columns is-multiline mb-0">
                <div className="column is-12">
                    <div className="pull-left">Showing <b>{affiliates.length}</b> of <b>{total}</b> Affiliates</div>
                    <Pagination activePage={page} itemsCountPerPage={limit} totalItemsCount={total} pageRangeDisplayed={5} onChange={(e) => setPage(e)} />
                </div>

                <div className="column is-12 pb-0">
                    <div className="table-container">
                        <table className="table is-fullwidth align-top has-background-transparent">
                            <tbody>
                                {
                                    !isEmpty(affiliates) && Object.values(affiliates).map((affiliate, index) => {
                                        return (
                                            <tr key={index} className="box pb-0 pt-2 mb-3">
                                                <td className="p-0 m-0" width="50%">
                                                    <table className="affiliate-table w-full">
                                                        <thead>
                                                            <th>#</th>
                                                            <th>Username</th>
                                                            <th>Email Address</th>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>{index + 1}</td>
                                                                <td>{affiliate?.results[0]?.affiliate?.firstname} {affiliate?.results[0]?.affiliate?.lastname}</td>
                                                                <td>{affiliate?.results[0]?.affiliate?.email}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td className="p-0 m-0" width="100%">
                                                    <table className="affiliate-table w-full">
                                                        <thead>
                                                            <th>Discount Code</th>
                                                            <th>Affiliate Commission</th>
                                                            <th>Discount Code Value</th>
                                                            <th>Discount Status</th>
                                                            <th>Created On</th>
                                                            <th>Expires On</th>
                                                            <th align="right">Actions</th>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                !isEmpty(affiliate.results) && Object.values(affiliate.results).map((coupon, index) => {
                                                                    return (
                                                                        <tr>
                                                                            <td className="has-text-weight-bold"><span className="is-flex">{coupon.coupon.title} <span data-tooltip="Copy Discount Code"><i className="fa fa-clone is-clickable ml-1 has-text-danger" onClick={() => navigator.clipboard.writeText(coupon.coupon.title)}></i></span></span></td>
                                                                            <td className="has-text-weight-bold">{coupon.commission.type === "fixed_amount" ? `$${coupon.commission.value}` : `${coupon.commission.value}%`}</td>
                                                                            <td className="has-text-weight-bold">{coupon.coupon.type === "fixed_amount" ? `$${coupon.coupon.value}` : `${coupon.coupon.value}%`}</td>
                                                                            <td>{coupon.status === "expired" || coupon.status === "inactive" ? <span className="tag is-danger is-light has-text-weight-semibold border border-solid border-color-danger">{startCase(coupon.status)}</span> : <span className="tag is-success is-light has-text-weight-semibold border border-solid border-color-success">{startCase(coupon.status)}</span>}</td>
                                                                            <td>{moment(coupon.coupon.starts_at).format('D MMM, YYYY')}</td>
                                                                            <td>{coupon.coupon.ends_at === null ? "N/A" : moment(coupon.coupon.ends_at).format('D MMM, YYYY')}</td>
                                                                            <td>
                                                                                <div className="is-flex is-justify-content-flex-end">
                                                                                    {
                                                                                        coupon.status === "inactive" ? <button className="button is-small is-danger" onClick={() => RemoveDiscountCode(coupon.coupon.title)}>Remove Discount Code</button> : ""
                                                                                    }
                                                                                    {
                                                                                        coupon.status === "active" ? <button className="button is-small is-danger" onClick={() => ExpireDiscountCode(coupon.coupon.title)}>Expire Discount Code</button> : ""
                                                                                    }
                                                                                </div>
                                                                                <div className="is-flex is-justify-content-flex-end mt-1">
                                                                                    {
                                                                                        coupon.status === "active" ? <button className="button is-small is-info-dark" onClick={() => RegenerateDiscountCode(coupon.coupon.title)}>Generate New Discount Code</button> : ""
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {
                isEmpty(affiliates) ? <div className="box"><p className="py-5 has-text-centered is-fullwidth box-content">No Affiliates found</p></div> : ""
            }

            <div className="columns is-multiline mb-0">
                <div className="column is-12">
                    <div className="pull-left">Showing <b>{affiliates.length}</b> of <b>{total}</b> Affiliates</div>
                    <Pagination activePage={page} itemsCountPerPage={limit} totalItemsCount={total} pageRangeDisplayed={5} onChange={(e) => setPage(e)} />
                </div>
            </div>

            <div className={`modal ${addModal}`}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <header className="modal-card-head">
                            <p className="modal-card-title is-size-5 has-text-weight-semibold">Add Affiliate</p>
                            <a className="delete" aria-label="close" onClick={() => { setAddModal(''); setAffiliate({}); }}>x</a>
                        </header>
                        <section className="modal-card-body py-0">
                            <h1 className="is-size-5m6 has-text-weight-bold mb-2">User Details</h1>
                            <div className="field">
                                <div className="field-body is-flex">
                                    <div className="field">
                                        <label className="label">First name</label>
                                        <input className="input" type="text" name="firstname" defaultValue={affiliate?.affiliate?.firstname} placeholder="Enter first name" ref={register} />
                                        <p className="help is-danger">{errors.firstname && 'First name is required'}</p>
                                    </div>
                                    <div className="field">
                                        <label className="label">Last name</label>
                                        <input className="input" type="text" name="lastname" defaultValue={affiliate?.affiliate?.lastname} placeholder="Enter last name" ref={register} />
                                        <p className="help is-danger">{errors.lastname && 'Last name is required'}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Email Address</label>
                                <input className="input" type="email" name="email" defaultValue={affiliate?.affiliate?.email} placeholder="Enter email address" ref={register} />
                                <p className="help is-danger">{errors.email && 'Email address is required.'}</p>
                            </div>
                            <hr />
                            <h1 className="is-size-5m6 has-text-weight-bold mb-2">Commission/Affiliate Settings</h1>
                            <div className="field">
                                <div className="field-body is-flex">
                                    <div className="field is-flex-basis-0">
                                        <label className="label">Type</label>
                                        <div className="select is-fullwidth">
                                            <select ref={register} name="affiliate_type">
                                                <option value=''>Choose Type</option>
                                                <option value='fixed_amount'>Fixed Amount</option>
                                                <option value='percentage'>Percentage</option>
                                            </select>
                                        </div>
                                        <p className="help is-danger">{errors.affiliate_type && 'Type is required'}</p>
                                    </div>
                                    <div className="field is-flex-basis-0">
                                        <label className="label">Value</label>
                                        <div className="control">
                                            <input className="input" name="affiliate_value" type="number" placeholder="Enter value" ref={register} />
                                        </div>
                                        <p className="help is-danger">{errors.affiliate_value && 'Value is required'}</p>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <h1 className="is-size-5m6 has-text-weight-bold mb-2">Discount Code Settings</h1>
                            <div className="field">
                                <div className="field-body is-flex">
                                    <div className="field is-flex-basis-0">
                                        <label className="label">Type</label>
                                        <div className="select is-fullwidth">
                                            <select ref={register} name="buyer_type">
                                                <option value=''>Choose Type</option>
                                                <option value='fixed_amount'>Fixed Amount</option>
                                                <option value='percentage'>Percentage</option>
                                            </select>
                                        </div>
                                        <p className="help is-danger">{errors.buyer_type && 'Type is required'}</p>
                                    </div>
                                    <div className="field is-flex-basis-0">
                                        <label className="label">Value</label>
                                        <div className="control">
                                            <input className="input" name="buyer_value" type="number" placeholder="Enter value" ref={register} />
                                        </div>
                                        <p className="help is-danger">{errors.buyer_value && 'Value is required'}</p>
                                    </div>
                                    <div className="field is-flex-basis-0">
                                        <label className="label">Expiry Days</label>
                                        <div className="control">
                                            <input className="input" name="buyer_expiry" type="number" placeholder="Enter value" ref={register} />
                                        </div>
                                        <p className="help is-danger">{errors.buyer_expiry && 'Days are required'}</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <footer className="modal-card-foot is-justify-content-flex-end">
                            <a className="button has-background-white-bis border border-sold border-color-white-bis has-text-dark" onClick={() => { setAddModal(''); setAffiliate({}); }}>Cancel</a>
                            <button className="button is-primary" type="submit">Submit</button>
                        </footer>
                    </form>
                </div>
            </div>

            <div className={`modal ${regenerateModal}`}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <form onSubmit={handleSubmit3(onSubmit3)} noValidate>
                        <header className="modal-card-head">
                            <p className="modal-card-title is-size-5 has-text-weight-semibold">Regenerate Coupon</p>
                            <a className="delete" aria-label="close" onClick={() => setRegenerateModal('')}>x</a>
                        </header>
                        <section className="modal-card-body py-0">
                            <input className="input" type="hidden" name="code" placeholder="Enter coupon code" defaultValue={selectedCode} ref={register3} />
                            <h1 className="is-size-5m6 has-text-weight-bold mb-2">Commission/Affiliate Settings</h1>
                            <div className="field">
                                <div className="field-body is-flex">
                                    <div className="field is-flex-basis-0">
                                        <label className="label">Type</label>
                                        <div className="select is-fullwidth">
                                            <select ref={register3} name="affiliate_type">
                                                <option value=''>Choose Type</option>
                                                <option value='fixed_amount'>Fixed Amount</option>
                                                <option value='percentage'>Percentage</option>
                                            </select>
                                        </div>
                                        <p className="help is-danger">{errors3.affiliate_type && 'Type is required'}</p>
                                    </div>
                                    <div className="field is-flex-basis-0">
                                        <label className="label">Value</label>
                                        <div className="control">
                                            <input className="input" name="affiliate_value" type="number" placeholder="Enter value" ref={register3} />
                                        </div>
                                        <p className="help is-danger">{errors3.affiliate_value && 'Value is required'}</p>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <h1 className="is-size-5m6 has-text-weight-bold mb-2">Discount Code Settings</h1>
                            <div className="field">
                                <div className="field-body is-flex">
                                    <div className="field is-flex-basis-0">
                                        <label className="label">Type</label>
                                        <div className="select is-fullwidth">
                                            <select ref={register3} name="buyer_type">
                                                <option value=''>Choose Type</option>
                                                <option value='fixed_amount'>Fixed Amount</option>
                                                <option value='percentage'>Percentage</option>
                                            </select>
                                        </div>
                                        <p className="help is-danger">{errors3.buyer_type && 'Type is required'}</p>
                                    </div>
                                    <div className="field is-flex-basis-0">
                                        <label className="label">Value</label>
                                        <div className="control">
                                            <input className="input" name="buyer_value" type="number" placeholder="Enter value" ref={register3} />
                                        </div>
                                        <p className="help is-danger">{errors3.buyer_value && 'Value is required'}</p>
                                    </div>
                                    <div className="field is-flex-basis-0">
                                        <label className="label">Expiry Days</label>
                                        <div className="control">
                                            <input className="input" name="buyer_expiry" type="number" placeholder="Enter value" ref={register3} />
                                        </div>
                                        <p className="help is-danger">{errors3.buyer_expiry && 'Days are required'}</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <footer className="modal-card-foot is-justify-content-flex-end">
                            <a className="button has-background-white-bis border border-sold border-color-white-bis has-text-dark" onClick={() => setRegenerateModal('')}>Cancel</a>
                            <button className="button is-primary" type="submit">Submit</button>
                        </footer>
                    </form>
                </div>
            </div>
        </DashboardLayout>
    )
}

export default SuperAffiliates;