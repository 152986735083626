import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

import { LoaderContext } from '../loader';
import AffiliateLayout from '../layouts/affiliate-layout';

function PayoutsCreditGrouped(props) {
    const dispatch = useDispatch();
    const authProps = useSelector(state => state.auth);
    const payoutsProps = useSelector(state => state.grouped);
    const voucherProps = useSelector(state => state.voucher);
    const [payouts, setPayouts] = useState({});
    const [payoutsNotReady, setPayoutsNotReady] = useState({});
    const { setLoading } = useContext(LoaderContext);

    useEffect(() => {
        dispatch({
            type: "AFFILIATE_FETCH_PAYOUTS_GROUPED",
            payload: {
                id: authProps._id,
                type: "bycredits"
            }
        });
    }, [voucherProps]);

    useEffect(() => {
        setLoading(false);
        if(payoutsProps !== undefined) {
            setPayouts(payoutsProps.payoutsReady);
            setPayoutsNotReady(payoutsProps.payoutsNotReady);
        }
    }, [payoutsProps]);

    function GenerateVoucher(shop) {
        setLoading(true);

        dispatch({
            type: "AFFILIATE_CREATE_VOUCHERS",
            payload: {
                shop: shop
            }
        });
    }

    return (
        <AffiliateLayout {...props}>
            <section className="pb-3">
                <div className="is-md-flex is-align-items-center is-justify-content-space-between">
                    <div>
                        <h1 className="title is-size-4 is-size-4-tablet mb-2 has-text-primary"> Payouts (In-Store credit)</h1>
                    </div>
                </div>
            </section>

            <div className="columns is-multiline">
                <div className="column is-12">
                    <div className="message is-danger mb-3">
                        <div className="message-body"> <b>Note : </b>Voucher codes can be redeemeded after 30 days from when the sale was made. We do this to protect the merchant from fraudulent sales. If the customer cancels, returns or refunds their order the commission will be retracted. Multiple Transactions will be grouped together to create a single voucher for a store.</div>
                    </div>

                    <h2 className="is-size-5 has-text-primary has-text-weight-semibold">Ready to be redeemeded</h2>
                    <div className="box">
                        <div className="table-container">
                            <table className="table is-striped is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>Store</th>
                                        <th>Payout Amount</th>
                                        <th align="right">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !isEmpty(payouts) && Object.values(payouts).map((payout, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{payout?.company ? payout.company : payout.shop}</td>
                                                    <td className="has-text-weight-bold">{payout?.payout} {payout?.currency}</td>
                                                    <td align="right">
                                                        <button className="button is-primary with-shadow" onClick={() => GenerateVoucher(payout.shop)}>Generate Voucher</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            {
                                isEmpty(payouts) ? <p className="pb-5 has-text-centered is-fullwidth">No Redeemable Transactions - They will show here after 30 days from the day of the sale</p> : ""
                            }
                        </div>
                    </div>

                    <h2 className="is-size-5 has-text-primary has-text-weight-semibold">Pending Voucher Payouts</h2>
                    <p className="is-size5m6 mb-2">You can redeem the vouchers after 30 days from the day of purchase.</p>
                    <div className="box">
                        <div className="table-container">
                            <table className="table is-striped is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>Store</th>
                                        <th>Pending Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !isEmpty(payoutsNotReady) && Object.values(payoutsNotReady).map((payout, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{payout?.company ? payout.company : payout.shop}</td>
                                                    <td className="has-text-weight-bold">{payout?.payout.toFixed(2)} {payout?.currency}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            {
                                isEmpty(payoutsNotReady) ? <p className="pb-5 has-text-centered is-fullwidth">No Transactions - They will show here after 30 days from the day of the sale</p> : ""
                            }
                        </div>
                    </div>
                </div>
            </div>
        </AffiliateLayout>
    )
}

export default PayoutsCreditGrouped;