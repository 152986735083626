import React from "react";
import { useHistory } from "react-router-dom";

function AffiliateSidebar() {
    const history = useHistory();

    const moveTo = (page) => {
        history.push(page);
    }

    function ToggleDropdown() {
        let sidebar = document.getElementsByClassName('has-dropdown')[0];
        if (sidebar.classList.contains('is-active')) {
            sidebar.classList.remove('is-active');
        } else {
            sidebar.classList.add('is-active');
        }
    }

    return (
        <aside className="menu sidebar">
            <div className="is-flex is-flex-direction-column h-full sidebar-in">
                <div className="navbar-brand is-hidden-mobile is-hidden-tablet-only has-background-primary">
                    <div className="navbar-item brand-text mx-auto" onClick={() => history.push('/affiliate/dashboard')}>
                        <img src="/images/logo.png" alt="" /> <span className="has-text-white is-size-4">Shop Share Paid</span>
                    </div>
                </div>
                <div className="p-0">
                    <p className="menu-label px-4 has-text-white mt-1"> General </p>
                    <ul className="menu-list">
                        <li className={`${window.location.href.includes('dashboard') ? 'active' : ''}`}>
                            <a onClick={() => moveTo('/affiliate/dashboard')}>
                                <span className="icon-text">
                                    <span className="icon">
                                        <i className="fa fa-home"></i></span>
                                    <span>Home</span>
                                </span>
                            </a>
                        </li>
                        <li className={`${window.location.href.includes('coupons') ? 'active' : ''}`}>
                            <a onClick={() => moveTo('/affiliate/coupons')}>
                                <span className="icon-text">
                                    <span className="icon">
                                        <i className="fa fa-ticket"></i></span>
                                    <span>Get Started</span>
                                </span>
                            </a>
                        </li>
                        <li className="navbar-item has-dropdown is-flex-direction-column">
                            <a className="is-flex" onClick={() => ToggleDropdown()}>
                                <span className="icon-text">
                                    <span className="icon">
                                        <i className="fa fa-dollar"></i>
                                    </span>
                                    <span>Get Paid</span>
                                </span>
                                <span className="icon ml-auto">
                                    <i className="fa fa-chevron-down"></i>
                                </span>
                            </a>
                            <div className="navbar-dropdown is-relative">
                                <li className={`navbar-item ${window.location.href.includes('payouts-cash') ? 'active' : ''}`} >
                                    <a className="w-full" onClick={(e) => moveTo('/affiliate/payouts-cash')}>
                                        Cash
                                    </a>
                                </li>
                                <li className={`navbar-item ${window.location.href.includes('payouts-credit') ? 'active' : ''}`}>
                                    <a className="w-full" onClick={(e) => moveTo('/affiliate/payouts-credit')}>
                                        In-Store credit
                                    </a>
                                </li>
                            </div>
                        </li>
                    </ul>
                    <p className="menu-label px-4 has-text-white mt-5">Become a super affiliate to <br />increase your revenue</p>
                    <ul className="menu-list">
                        <li className={`${window.location.href.includes('stores') ? 'active' : ''}`}><a onClick={() => moveTo('/affiliate/stores')}><span className="icon-text"><span className="icon"><i className="fa fa-user"></i></span><span>Super Affiliate Program</span></span></a></li>
                    </ul>
                    <p className="menu-label px-4 has-text-white mt-5">Customers</p>
                    <ul className="menu-list">
                        <li className={`${window.location.href.includes('payments') ? 'active' : ''}`}><a onClick={() => moveTo('/affiliate/payments')}><span className="icon-text"><span className="icon"><i className="fa fa-gear"></i></span><span>Payment Settings</span></span></a></li>
                    </ul>
                    <p className="menu-label px-4 has-text-white mt-5">Tutorial</p>
                    <ul className="menu-list">
                        <li className={`${window.location.href.includes('how-to-get-paid') ? 'active' : ''}`}><a onClick={() => moveTo('/affiliate/how-to-get-paid')}><span className="icon-text"><span className="icon"><i className="fa fa-dollar"></i></span><span>How to get paid</span></span></a></li>
                    </ul>
                </div>
                <div className="mt-auto px-2 mb-4">
                    <ul className="menu-list">
                        <li className="my-5">
                            <img className="image max-w-140 mx-auto" src={`${process.env.REACT_APP_APP_ENDPOINT}/images/affiliate-market-graphic.svg`} alt="" />
                        </li>
                        <li>
                            <a className="is-flex p-2" href="https://paytron.com.au/" target="_blank" rel="noopener noreferrer">
                                <img className="image max-w-80 mx-auto" src={`${process.env.REACT_APP_APP_ENDPOINT}/images/paytron-logo.png`} alt="paytron logo" />
                            </a>
                        </li>
                        <li className="pb-4">
                            <a className="is-flex p-0" href="mailto:contact@shopsharepaid.com" target="_blank" rel="noopener noreferrer">
                                <span className="icon-text">
                                    <span className="icon"><i className="fa fa-question-circle"></i></span>
                                    <span>Help</span>
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </aside>
    )
}

export default AffiliateSidebar;