import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { isEmpty } from "lodash";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { StaticData, Storage } from "../helpers";
import StepsLayout from '../layouts/steps-layout';

function Steps(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [step, setStep] = useState('1');
    const authProps = useSelector(state => state.auth);
    const planProps = useSelector(state => state.plans);
    const chargeProps = useSelector(state => state.charge);
    const [plan, setPlan] = useState({});

    useEffect(() => {
        dispatch({
            type: "ADMIN_GET_PLANS",
            payload: ""
        });
    }, []);

    useEffect(() => {
        if (!isEmpty(planProps)) {
            var plan = Object.values(planProps).filter((plan) => {
                if (plan.title === 'Starter') {
                    return plan;
                }
            });

            setPlan(plan[0]);
        }
    }, [planProps]);

    useEffect(() => {
        if (!isEmpty(chargeProps)) {
            window.location.href = chargeProps.recurring_application_charge.confirmation_url;
        }
    }, [chargeProps]);

    function SelectPlan(plan) {
        var session_plan_data = sessionStorage.getItem('plan_data');

        if (session_plan_data === null) {
            if (plan.price === 0) {
                dispatch({
                    type: "SET_RECURRING_CHARGE",
                    payload: {
                        id: "Free",
                        plan: plan.title
                    }
                });

                setTimeout(() => {
                    history.push('/dashboard');
                }, 1000);
            } else if (authProps.plan_details !== undefined) {
                history.push('/dashboard');
            } else {
                dispatch({
                    type: "CREATE_RECURRING_CHARGE",
                    payload: {
                        id: plan._id,
                        page: "dashboard"
                    }
                });
            }
        } else {
            sessionStorage.removeItem('plan_data');
            var enterprisePlan = JSON.parse(session_plan_data);

            if (enterprisePlan.price === 0) {
                dispatch({
                    type: "SET_RECURRING_CHARGE",
                    payload: {
                        id: "Enterprise",
                        plan: enterprisePlan
                    }
                });

                setTimeout(() => {
                    history.push('/dashboard');
                }, 1000);
            } else {
                dispatch({
                    type: "CREATE_RECURRING_CHARGE",
                    payload: {
                        id: "Enterprise",
                        page: "dashboard",
                        plan: enterprisePlan
                    }
                });
            }
        }
    }

    const schema = yup.object().shape({
        firstname: yup.string().required(),
        lastname: yup.string().required(),
        email: yup.string().email().required(),
        company: yup.string().required()
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (form) => {
        dispatch({
            type: "UPDATE_PROFILE",
            payload: form
        });

        setStep('2');
    }

    const schema2 = yup.object().shape({
        address: yup.string().required(),
        city: yup.string().required(),
        state: yup.string().required(),
        country: yup.string().required(),
        postal_code: yup.string().required()
    });

    const { register: register2, handleSubmit: handleSubmit2, errors: errors2 } = useForm({
        resolver: yupResolver(schema2)
    });

    const onSubmit2 = (form) => {
        dispatch({
            type: "UPDATE_PROFILE",
            payload: form
        });

        setStep('3');
    }

    useEffect(() => {
        if (!isEmpty(authProps) && authProps?.token) {
            Storage.Add('token', authProps.token);
        }
    }, [authProps]);

    return (
        <StepsLayout>
            <div className="box" style={{ top: "50%", left: "50%", position: "absolute", width: "100%", maxWidth: "800px", transform: "translate(-50%, -50%)" }} >
                <div className="steps-area has-text-centered my-5">
                    <img className="image mx-auto" style={{ width: "180px" }} src="/images/logo.png" alt="logo" />
                    <div className={`has-text-centered my-5 is-size-4 has-text-weight-medium ${step === '3' ? 'is-hidden' : ''}`}>User Onboarding</div>
                    <ul className={`steps is-medium has-content-centered my-5 ${step === '3' ? 'is-hidden' : ''}`}>
                        <li className={`steps-segment ${step === '1' ? 'is-active' : ''}`}>
                            <span className="steps-marker px-2 py-2">
                                <span className="icon">
                                    <i className="fa fa-user"></i>
                                </span>
                            </span>
                            <div className="steps-content">
                                <p className="is-size-5m6">User Information</p>
                            </div>
                        </li>
                        <li className={`steps-segment ${step === '2' ? 'is-active' : ''}`}>
                            <span className="steps-marker px-2 py-2">
                                <span className="icon">
                                    <i className="fa fa-map-marker"></i>
                                </span>
                            </span>
                            <div className="steps-content">
                                <p className="is-size-5m6">Address Details</p>
                            </div>
                        </li>
                        <li className={`steps-segment ${step === '2' ? 'is-active' : ''}`}>
                            <span className="steps-marker px-2 py-2">
                                <span className="icon">
                                    <i className="fa fa-check"></i>
                                </span>
                            </span>
                            <div className="steps-content">
                                <p className="is-size-5m6">Complete</p>
                            </div>
                        </li>
                    </ul>
                </div>

                <div className="mt-3">
                    <form onSubmit={handleSubmit(onSubmit)} className={`${step !== '1' ? 'is-hidden' : ''}`}>
                        <div className="field">
                            <label className="label">First Name</label>
                            <input type="text" className="input" name="firstname" placeholder="First Name" ref={register} defaultValue={authProps?.firstname} />
                            <p className="help is-danger">{errors.firstname && 'First Name is required'}</p>
                        </div>
                        <div className="field">
                            <label className="label">Last Name</label>
                            <input type="text" className="input" name="lastname" placeholder="Last Name" ref={register} defaultValue={authProps?.lastname} />
                            <p className="help is-danger">{errors.lastname && 'Last Name is required'}</p>
                        </div>
                        <div className="field">
                            <label className="label">Email Address</label>
                            <input type="email" className="input" name="email" placeholder="Email Address" ref={register} defaultValue={authProps?.email} />
                            <p className="help is-danger">{errors.email && 'Email is required'}</p>
                        </div>
                        <div className="field">
                            <label className="label">Company/Brand <span className="is-size-7 has-text-danger">(Company name will be shared on social media, we recommend using your brand name here)</span></label>
                            <input type="text" className="input" name="company" placeholder="Company name" ref={register} defaultValue={authProps?.company} />
                            <p className="help is-danger">{errors.company && 'Company Name is required'}</p>
                        </div>
                        <div className="field has-text-centered is-flex is-justify-content-center py-4">
                            <div className="buttons">
                                <button type="submit" className="button is-primary py-5 min-w-120 is-size-5m6">
                                    <span>Next</span>
                                    <span className="icon">
                                        <i className="fa fa-chevron-right"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </form>

                    <form onSubmit={handleSubmit2(onSubmit2)} className={`${step !== '2' ? 'is-hidden' : ''}`}>
                        <div className="field">
                            <label className="label">Address</label>
                            <input className="input" name="address" ref={register2} defaultValue={authProps?.address} />
                            <p className="help is-danger">{errors2.address && 'Address is required'}</p>
                        </div>
                        <div className="field">
                            <label className="label">City</label>
                            <input type="text" className="input" name="city" ref={register2} defaultValue={authProps?.city} />
                            <p className="help is-danger">{errors2.city && 'City is required'}</p>
                        </div>
                        <div className="field">
                            <label className="label">State</label>
                            <input type="text" className="input" name="state" ref={register2} defaultValue={authProps?.state} />
                            <p className="help is-danger">{errors2.state && 'State is required'}</p>
                        </div>
                        <div className="field">
                            <label className="label">Postal Code</label>
                            <input type="text" className="input" name="postal_code" ref={register2} defaultValue={authProps?.postal_code} />
                            <p className="help is-danger">{errors2.postal_code && 'Postal Code is required'}</p>
                        </div>
                        <div className="field">
                            <label className="label">Country</label>
                            <div className="select is-fullwidth">
                                <select name="country" ref={register2} defaultValue={authProps?.country}>
                                    <option value="">Select Country</option>
                                    {
                                        StaticData.ListCountries().map((country, index) => {
                                            return (
                                                <option value={country.sortname} key={index}>{country.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <p className="help is-danger">{errors2.country && 'Country is required'}</p>
                        </div>
                        <div className="field has-text-centered is-flex is-justify-content-center py-4">
                            <div className="buttons">
                                <a type="submit" className="button is-primary py-5 min-w-120 is-size-5m6" onClick={() => setStep('1')}>
                                    <span className="icon">
                                        <i className="fa fa-chevron-left"></i>
                                    </span>
                                    <span>Back</span>
                                </a>
                                <button type="submit" className="button is-primary py-5 min-w-120 is-size-5m6">
                                    <span>Next</span>
                                    <span className="icon">
                                        <i className="fa fa-chevron-right"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </form>

                    <form className={`has-text-centered py-5 my-5 ${step !== '3' ? 'is-hidden' : ''}`}>
                        <span className="is-success has-text-success">
                            <span className="icon">
                                <i className="fa fa-check-circle is-size-1"></i>
                            </span>
                        </span>
                        <p className="is-size-4 has-text-weight-semibold">Onboarding Complete</p>
                        <p className="is-size-5">Welcome to Shop Share Paid</p>
                        <a className="button is-primary mt-6 is-size-5 px-6 py-5" onClick={() => SelectPlan(plan)}>Proceed to Dashboard</a> :
                    </form>
                </div>
            </div>
        </StepsLayout>
    )

}

export default Steps;