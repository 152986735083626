import { useEffect } from 'react';

function Logout() {
    useEffect(() => {
        sessionStorage.clear();
        window.location.href = process.env.REACT_APP_APP_ENDPOINT + '/affiliate/login';
    });

    return null;
}

export default Logout;