import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import Pagination from "react-js-pagination";
import moment from "moment-timezone";

import AdminLayout from '../layouts/admin-layout';

function AdminAffiliates(props) {
    const dispatch = useDispatch();
    const [affiliates, setAffiliates] = useState({});
    const affiliateProps = useSelector(state => state.affiliates);
    const [search, setSearch] = useState('');

    // Pagination
    const limit = 250;
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        PullData();
    }, [page]);

    function PullData() {
        dispatch({
            type: "ADMIN_FETCH_AFFILIATES",
            payload: {
                id: props.match.params.id,
                page: page - 1,
                limit: limit
            }
        });
    }

    useEffect(() => {
        if (!isEmpty(affiliateProps) && affiliateProps.results !== undefined) {
            setAffiliates(affiliateProps.results);
            setTotal(affiliateProps.total)
        } else {
            setAffiliates([]);
            setTotal(0);
        }
    }, [affiliateProps]);

    function SearchParam(e) {
        setSearch(e.target.value);
    }

    function SearchAffiliates() {
        dispatch({
            type: "ADMIN_FETCH_AFFILIATES",
            payload: {
                id: props.match.params.id,
                search: search
            }
        });
    }

    return (
        <AdminLayout {...props} >
            <section className="pb-3">
                <div className="is-md-flex is-align-items-center is-justify-content-space-between">
                    <div>
                        <h1 className="title is-size-4 is-size-4-tablet mb-2"> Affiliates - <span className="has-text-danger">{affiliates[0]?.shop}</span></h1>
                    </div>
                    <div>
                        <div className="field has-addons">
                            <div className="control">
                                <input className="input" type="text" onChange={(e) => SearchParam(e)} placeholder="Search for affiliates" />
                            </div>
                            <div className="control">
                                <a className="button is-danger h-full" onClick={() => SearchAffiliates()}> <i className="fa fa-search"></i> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="columns is-multiline">
                <div className="column is-12">
                    <div className="box">
                        <div className="table-container">
                            <table className="table is-striped is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Username</th>
                                        <th>Email</th>
                                        <th>Created At</th>
                                        <th>Updated At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !isEmpty(affiliates) && Object.values(affiliates).map((affiliate, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{affiliate.affiliate.firstname} {affiliate.affiliate.lastname}</td>
                                                    <td>{affiliate.affiliate.email}</td>
                                                    <td>{moment(affiliate.affiliate.created_at).tz('Australia/Sydney').format('D MMM, YYYY hh:mm:ss A')}</td>
                                                    <td>{moment(affiliate.affiliate.updated_at).tz('Australia/Sydney').format('D MMM, YYYY hh:mm:ss A')}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className="column is-12">
                <div className="pull-left"><b>Total Affiliates</b> : {total}</div>
                <Pagination activePage={page} itemsCountPerPage={limit} totalItemsCount={total} pageRangeDisplayed={5} onChange={(e) => setPage(e)} />
            </div>
        </AdminLayout>
    )
}

export default AdminAffiliates;