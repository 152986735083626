import { takeLatest, put, call } from "redux-saga/effects";
import axios from "axios";

import { Storage } from '../helpers';

//     __                _     
//    / /   ____  ____ _(_)___ 
//   / /   / __ \/ __ `/ / __ \
//  / /___/ /_/ / /_/ / / / / /
// /_____/\____/\__, /_/_/ /_/ 
//             /____/          

export function* watchAffiliateLogin() {
    yield takeLatest("AFFILIATE_LOGIN", trackAffiliateLogin);
}

function* trackAffiliateLogin(sagaData) {
    try {
        var response = yield call(callAffiliateLogin, sagaData.payload);

        if (response) {
            yield put({
                type: "AFFILIATE_LOGIN_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "AFFILIATE_LOGIN_ERROR",
            response: err
        });
    }
}

function callAffiliateLogin(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/affiliate/login", {
            email: saga.email,
            password: saga.password
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ____             _      __           
//    / __ \___  ____ _(_)____/ /____  _____
//   / /_/ / _ \/ __ `/ / ___/ __/ _ \/ ___/
//  / _, _/  __/ /_/ / (__  ) /_/  __/ /    
// /_/ |_|\___/\__, /_/____/\__/\___/_/     
//            /____/                        

export function* watchAffiliateRegister() {
    yield takeLatest("AFFILIATE_REGISTER", trackAffiliateRegister);
}

function* trackAffiliateRegister(sagaData) {
    try {
        var response = yield call(callAffiliateRegister, sagaData.payload);

        if (response) {
            yield put({
                type: "AFFILIATE_REGISTER_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "AFFILIATE_REGISTER_ERROR",
            response: err
        });
    }
}

function callAffiliateRegister(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/affiliate/register", saga).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ____                 __     ____                                          __
//    / __ \___  ________  / /_   / __ \____ ____________      ______  _________/ /
//   / /_/ / _ \/ ___/ _ \/ __/  / /_/ / __ `/ ___/ ___/ | /| / / __ \/ ___/ __  / 
//  / _, _/  __(__  )  __/ /_   / ____/ /_/ (__  |__  )| |/ |/ / /_/ / /  / /_/ /  
// /_/ |_|\___/____/\___/\__/  /_/    \__,_/____/____/ |__/|__/\____/_/   \__,_/   

export function* watchResetPassword() {
    yield takeLatest("AFFILIATE_RESET_PASSWORD", trackResetPassword);
}

function* trackResetPassword(sagaData) {
    try {
        var response = yield call(callResetPassword, sagaData.payload);

        if (response) {
            yield put({
                type: "AFFILIATE_RESET_PASSWORD_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "AFFILIATE_RESET_PASSWORD_ERROR",
            response: err
        });
    }
}

function callResetPassword(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/affiliate/reset-password", {
            email: saga.email
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//    ________                              ____                                          __
//   / ____/ /_  ____ _____  ____ ____     / __ \____ ____________      ______  _________/ /
//  / /   / __ \/ __ `/ __ \/ __ `/ _ \   / /_/ / __ `/ ___/ ___/ | /| / / __ \/ ___/ __  / 
// / /___/ / / / /_/ / / / / /_/ /  __/  / ____/ /_/ (__  |__  )| |/ |/ / /_/ / /  / /_/ /  
// \____/_/ /_/\__,_/_/ /_/\__, /\___/  /_/    \__,_/____/____/ |__/|__/\____/_/   \__,_/   
//                        /____/                                                            

export function* watchAffiliateChangePassword() {
    yield takeLatest("AFFILIATE_CHANGE_PASSWORD", trackAffiliateChangePassword);
}

function* trackAffiliateChangePassword(sagaData) {
    try {
        var response = yield call(callAffiliateChangePassword, sagaData.payload);

        if (response) {
            yield put({
                type: "AFFILIATE_CHANGE_PASSWORD_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "AFFILIATE_CHANGE_PASSWORD_ERROR",
            response: err
        });
    }
}

function callAffiliateChangePassword(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/affiliate/change-password", {
            currentPassword: saga.currentPassword,
            password: saga.password,
            confirmPassword: saga.confirmPassword
        }, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("affiliate_token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//    __  __          __      __          ____             _____ __   
//   / / / /___  ____/ /___ _/ /____     / __ \_________  / __(_) /__ 
//  / / / / __ \/ __  / __ `/ __/ _ \   / /_/ / ___/ __ \/ /_/ / / _ \
// / /_/ / /_/ / /_/ / /_/ / /_/  __/  / ____/ /  / /_/ / __/ / /  __/
// \____/ .___/\__,_/\__,_/\__/\___/  /_/   /_/   \____/_/ /_/_/\___/ 
//     /_/                                                            

export function* watchAffiliateUpdateProfile() {
    yield takeLatest("AFFILIATE_UPDATE_PROFILE", trackAffiliateUpdateProfile);
}

function* trackAffiliateUpdateProfile(sagaData) {
    try {
        var response = yield call(callAffiliateUpdateProfile, sagaData.payload);

        if (response) {
            yield put({
                type: "AFFILIATE_UPDATE_PROFILE_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "AFFILIATE_UPDATE_PROFILE_ERROR",
            response: err
        });
    }
}

function callAffiliateUpdateProfile(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/affiliate/update-profile", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("affiliate_token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ___    _________ ___       __          ____                          __      
//    /   |  / __/ __(_) (_)___ _/ /____     / __ \____ ___  ______  __  __/ /______
//   / /| | / /_/ /_/ / / / __ `/ __/ _ \   / /_/ / __ `/ / / / __ \/ / / / __/ ___/
//  / ___ |/ __/ __/ / / / /_/ / /_/  __/  / ____/ /_/ / /_/ / /_/ / /_/ / /_(__  ) 
// /_/  |_/_/ /_/ /_/_/_/\__,_/\__/\___/  /_/    \__,_/\__, /\____/\__,_/\__/____/  
//                                                    /____/                        

export function* watchAffiliateFetchPayouts() {
    yield takeLatest("AFFILIATE_FETCH_PAYOUTS", trackAffiliateFetchPayouts);
}

function* trackAffiliateFetchPayouts(sagaData) {
    try {
        var response = yield call(callAffiliateFetchPayouts, sagaData.payload);

        if (response) {
            yield put({
                type: "AFFILIATE_FETCH_PAYOUTS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "AFFILIATE_FETCH_PAYOUTS_ERROR",
            response: err
        });
    }
}

function callAffiliateFetchPayouts(saga) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + `/affiliate/fetch-payouts`, {
            params: saga,
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("affiliate_token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ___    _________ ___       __          ____                          __          ______                                __
//    /   |  / __/ __(_) (_)___ _/ /____     / __ \____ ___  ______  __  __/ /______   / ____/________  __  ______  ___  ____/ /
//   / /| | / /_/ /_/ / / / __ `/ __/ _ \   / /_/ / __ `/ / / / __ \/ / / / __/ ___/  / / __/ ___/ __ \/ / / / __ \/ _ \/ __  / 
//  / ___ |/ __/ __/ / / / /_/ / /_/  __/  / ____/ /_/ / /_/ / /_/ / /_/ / /_(__  )  / /_/ / /  / /_/ / /_/ / /_/ /  __/ /_/ /  
// /_/  |_/_/ /_/ /_/_/_/\__,_/\__/\___/  /_/    \__,_/\__, /\____/\__,_/\__/____/   \____/_/   \____/\__,_/ .___/\___/\__,_/   
//                                                    /____/                                              /_/                   

export function* watchAffiliateFetchPayoutsGrouped() {
    yield takeLatest("AFFILIATE_FETCH_PAYOUTS_GROUPED", trackAffiliateFetchPayoutsGrouped);
}

function* trackAffiliateFetchPayoutsGrouped(sagaData) {
    try {
        var response = yield call(callAffiliateFetchPayoutsGrouped, sagaData.payload);

        if (response) {
            yield put({
                type: "AFFILIATE_FETCH_PAYOUTS_GROUPED_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "AFFILIATE_FETCH_PAYOUTS_GROUPED_ERROR",
            response: err
        });
    }
}

function callAffiliateFetchPayoutsGrouped(saga) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + `/affiliate/fetch-payouts-grouped`, {
            params: saga,
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("affiliate_token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ______     __       __       ______                                  
//    / ____/__  / /______/ /_     / ____/___  __  ______  ____  ____  _____
//   / /_  / _ \/ __/ ___/ __ \   / /   / __ \/ / / / __ \/ __ \/ __ \/ ___/
//  / __/ /  __/ /_/ /__/ / / /  / /___/ /_/ / /_/ / /_/ / /_/ / / / (__  ) 
// /_/    \___/\__/\___/_/ /_/   \____/\____/\__,_/ .___/\____/_/ /_/____/  
//                                               /_/                        

export function* watchAffiliateFetchCoupons() {
    yield takeLatest("AFFILIATE_FETCH_COUPONS", trackAffiliateFetchCoupons);
}

function* trackAffiliateFetchCoupons(sagaData) {
    try {
        var response = yield call(callAffiliateFetchCoupons, sagaData.payload);

        if (response) {
            yield put({
                type: "AFFILIATE_FETCH_COUPONS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "AFFILIATE_FETCH_COUPONS_ERROR",
            response: err
        });
    }
}

function callAffiliateFetchCoupons(saga) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + `/affiliate/fetch-coupons?id=${saga.id}`, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("affiliate_token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ____             __    __                         __   ____       __        _ __    
//    / __ \____ ______/ /_  / /_  ____  ____ __________/ /  / __ \___  / /_____ _(_) /____
//   / / / / __ `/ ___/ __ \/ __ \/ __ \/ __ `/ ___/ __  /  / / / / _ \/ __/ __ `/ / / ___/
//  / /_/ / /_/ (__  ) / / / /_/ / /_/ / /_/ / /  / /_/ /  / /_/ /  __/ /_/ /_/ / / (__  ) 
// /_____/\__,_/____/_/ /_/_.___/\____/\__,_/_/   \__,_/  /_____/\___/\__/\__,_/_/_/____/  

export function* watchAffiliateDashboardDetails() {
    yield takeLatest("AFFILIATE_DASHBOARD_DETAILS", trackAffiliateDashboardDetails);
}

function* trackAffiliateDashboardDetails(sagaData) {
    try {
        var response = yield call(callAffiliateDashboardDetails, sagaData.payload);

        if (response) {
            yield put({
                type: "AFFILIATE_DASHBOARD_DETAILS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "AFFILIATE_DASHBOARD_DETAILS_ERROR",
            response: err
        });
    }
}

function callAffiliateDashboardDetails(saga) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + `/affiliate/dashboard-details`, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("affiliate_token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//    ______                __          _    __                 __             
//   / ____/_______  ____ _/ /____     | |  / /___  __  _______/ /_  ___  _____
//  / /   / ___/ _ \/ __ `/ __/ _ \    | | / / __ \/ / / / ___/ __ \/ _ \/ ___/
// / /___/ /  /  __/ /_/ / /_/  __/    | |/ / /_/ / /_/ / /__/ / / /  __/ /    
// \____/_/   \___/\__,_/\__/\___/     |___/\____/\__,_/\___/_/ /_/\___/_/     

export function* watchAffiliateCreateVouchers() {
    yield takeLatest("AFFILIATE_CREATE_VOUCHERS", trackAffiliateCreateVouchers);
}

function* trackAffiliateCreateVouchers(sagaData) {
    try {
        var response = yield call(callAffiliateCreateVouchers, sagaData.payload);

        if (response) {
            yield put({
                type: "AFFILIATE_CREATE_VOUCHERS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "AFFILIATE_CREATE_VOUCHERS_ERROR",
            response: err
        });
    }
}

function callAffiliateCreateVouchers(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/affiliate/create-vouchers", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("affiliate_token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ______     __       __       _    __                 __                  
//    / ____/__  / /______/ /_     | |  / /___  __  _______/ /_  ___  __________
//   / /_  / _ \/ __/ ___/ __ \    | | / / __ \/ / / / ___/ __ \/ _ \/ ___/ ___/
//  / __/ /  __/ /_/ /__/ / / /    | |/ / /_/ / /_/ / /__/ / / /  __/ /  (__  ) 
// /_/    \___/\__/\___/_/ /_/     |___/\____/\__,_/\___/_/ /_/\___/_/  /____/  
                                                                             
export function* watchAffiliateFetchVouchers() {
    yield takeLatest("AFFILIATE_FETCH_VOUCHERS", trackAffiliateFetchVouchers);
}

function* trackAffiliateFetchVouchers(sagaData) {
    try {
        var response = yield call(callAffiliateFetchVouchers, sagaData.payload);

        if (response) {
            yield put({
                type: "AFFILIATE_FETCH_VOUCHERS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "AFFILIATE_FETCH_VOUCHERS_ERROR",
            response: err
        });
    }
}

function callAffiliateFetchVouchers(saga) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/affiliate/fetch-vouchers", {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("affiliate_token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ____                          __     ____                             __ 
//    / __ \____ ___  ______  __  __/ /_   / __ \___  ____ ___  _____  _____/ /_
//   / /_/ / __ `/ / / / __ \/ / / / __/  / /_/ / _ \/ __ `/ / / / _ \/ ___/ __/
//  / ____/ /_/ / /_/ / /_/ / /_/ / /_   / _, _/  __/ /_/ / /_/ /  __(__  ) /_  
// /_/    \__,_/\__, /\____/\__,_/\__/  /_/ |_|\___/\__, /\__,_/\___/____/\__/  
//             /____/                                 /_/                       

export function* watchAffiliateCreatePayoutRequest() {
    yield takeLatest("AFFILIATE_CREATE_PAYOUT_REQUEST", trackAffiliateCreatePayoutRequest);
}

function* trackAffiliateCreatePayoutRequest(sagaData) {
    try {
        var response = yield call(callAffiliateCreatePayoutRequest, sagaData.payload);

        if (response) {
            yield put({
                type: "AFFILIATE_CREATE_PAYOUT_REQUEST_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "AFFILIATE_CREATE_PAYOUT_REQUEST_ERROR",
            response: err
        });
    }
}

function callAffiliateCreatePayoutRequest(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/affiliate/create-payout-request", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("affiliate_token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ______     __       __       ____                          __     ____                             __ 
//    / ____/__  / /______/ /_     / __ \____ ___  ______  __  __/ /_   / __ \___  ____ ___  _____  _____/ /_
//   / /_  / _ \/ __/ ___/ __ \   / /_/ / __ `/ / / / __ \/ / / / __/  / /_/ / _ \/ __ `/ / / / _ \/ ___/ __/
//  / __/ /  __/ /_/ /__/ / / /  / ____/ /_/ / /_/ / /_/ / /_/ / /_   / _, _/  __/ /_/ / /_/ /  __(__  ) /_  
// /_/    \___/\__/\___/_/ /_/  /_/    \__,_/\__, /\____/\__,_/\__/  /_/ |_|\___/\__, /\__,_/\___/____/\__/  
//                                          /____/                                 /_/                       

export function* watchAffiliateFetchPayoutRequest() {
    yield takeLatest("AFFILIATE_FETCH_PAYOUT_REQUEST", trackAffiliateFetchPayoutRequest);
}

function* trackAffiliateFetchPayoutRequest(sagaData) {
    try {
        var response = yield call(callAffiliateFetchPayoutRequest, sagaData.payload);

        if (response) {
            yield put({
                type: "AFFILIATE_FETCH_PAYOUT_REQUEST_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "AFFILIATE_FETCH_PAYOUT_REQUEST_ERROR",
            response: err
        });
    }
}

function callAffiliateFetchPayoutRequest(saga) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/affiliate/fetch-payout-request", {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("affiliate_token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ____             _      __           
//    / __ \___  ____ _(_)____/ /____  _____
//   / /_/ / _ \/ __ `/ / ___/ __/ _ \/ ___/
//  / _, _/  __/ /_/ / (__  ) /_/  __/ /    
// /_/ |_|\___/\__, /_/____/\__/\___/_/     
//            /____/                        

export function* watchIframeAffiliateRegister() {
    yield takeLatest("IFRAME_AFFILIATE_REGISTER", trackIframeAffiliateRegister);
}

function* trackIframeAffiliateRegister(sagaData) {
    try {
        var response = yield call(callIframeAffiliateRegister, sagaData.payload);

        if (response) {
            yield put({
                type: "IFRAME_AFFILIATE_REGISTER_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "IFRAME_AFFILIATE_REGISTER_ERROR",
            response: err
        });
    }
}

function callIframeAffiliateRegister(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/affiliate/iframe-register", saga).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ______     __       __       _____ __                      
//    / ____/__  / /______/ /_     / ___// /_____  ________  _____
//   / /_  / _ \/ __/ ___/ __ \    \__ \/ __/ __ \/ ___/ _ \/ ___/
//  / __/ /  __/ /_/ /__/ / / /   ___/ / /_/ /_/ / /  /  __(__  ) 
// /_/    \___/\__/\___/_/ /_/   /____/\__/\____/_/   \___/____/  
                                                               
export function* watchAffiliateFetchStores() {
    yield takeLatest("AFFILIATE_FETCH_STORES", trackAffiliateFetchStores);
}

function* trackAffiliateFetchStores(sagaData) {
    try {
        var response = yield call(callAffiliateFetchStores, sagaData.payload);

        if (response) {
            yield put({
                type: "AFFILIATE_FETCH_STORES_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "AFFILIATE_FETCH_STORES_ERROR",
            response: err
        });
    }
}

function callAffiliateFetchStores(saga) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/affiliate/fetch-stores", {
            params: saga,
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("affiliate_token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//    _____                __   ____                             __ 
//   / ___/___  ____  ____/ /  / __ \___  ____ ___  _____  _____/ /_
//   \__ \/ _ \/ __ \/ __  /  / /_/ / _ \/ __ `/ / / / _ \/ ___/ __/
//  ___/ /  __/ / / / /_/ /  / _, _/  __/ /_/ / /_/ /  __(__  ) /_  
// /____/\___/_/ /_/\__,_/  /_/ |_|\___/\__, /\__,_/\___/____/\__/  
//                                        /_/                       

export function* watchAffiliateSendRequest() {
    yield takeLatest("AFFILIATE_SEND_REQUEST", trackAffiliateSendRequest);
}

function* trackAffiliateSendRequest(sagaData) {
    try {
        var response = yield call(callAffiliateSendRequest, sagaData.payload);

        if (response) {
            yield put({
                type: "AFFILIATE_SEND_REQUEST_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "AFFILIATE_SEND_REQUEST_ERROR",
            response: err
        });
    }
}

function callAffiliateSendRequest(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/affiliate/send-request", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("affiliate_token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}