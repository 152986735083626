import React, { createContext } from 'react';

import Spinner from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export const LoaderContext = createContext();

export const Loader = (props) => {
    return (
        <React.Fragment>
            {
                props.loading === true ? <div className="loaderContainer">
                    <Spinner type="Rings" color="Black" height={150} width={150} visible={true} />
                </div> : ""
            }
        </React.Fragment>
    )
}