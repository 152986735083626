import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import moment from "moment";

import { Price } from "../helpers";
import AffiliateLayout from '../layouts/affiliate-layout';

function PayoutsCredit(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const authProps = useSelector(state => state.auth);
    const payoutsProps = useSelector(state => state.payouts);
    const [payouts, setPayouts] = useState({});

    useEffect(() => {
        dispatch({
            type: "AFFILIATE_FETCH_PAYOUTS",
            payload: {
                id: authProps._id,
                type: "bycredits"
            }
        });
    }, []);

    useEffect(() => {
        setPayouts(payoutsProps);
    }, [payoutsProps]);

    return (
        <AffiliateLayout {...props}>
            <section className="pb-3">
                <div className="is-md-flex is-align-items-center is-justify-content-space-between">
                    <div>
                        <h1 className="title is-size-4 is-size-4-tablet mb-2 has-text-primary"> Payouts (In-Store credit)</h1>
                    </div>
                    <div>
                        <button className="button is-success with-shadow mr-2" onClick={() => history.push('/affiliate/vouchers')}>My Active In-store credit vouchers</button>
                        <button className="button is-primary with-shadow" onClick={() => history.push('/affiliate/payouts-credit-grouped')}>Create Voucher for in-store credits</button>
                    </div>
                </div>
            </section>

            <div className="columns is-multiline">
                <div className="column is-12">
                    <div className="message is-danger mb-3">
                        <div className="message-body"> <b>Note : </b> To spend your in-store credit you need to create a voucher code.</div>
                    </div>
                    <div className="box">
                        <div className="table-container">
                            <table className="table is-striped is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>Customer</th>
                                        <th>Customer Email</th>
                                        <th>Store</th>
                                        <th>Discount Code Used</th>
                                        <th>Payout Amount</th>
                                        <th>Status</th>
                                        <th>Created</th>
                                        <th>Redeemable After</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !isEmpty(payouts) && Object.values(payouts).map((payout, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{payout.buyer.firstname} {payout.buyer.lastname}</td>
                                                    <td>{payout.buyer.email}</td>
                                                    <td>{payout.company ? payout.company : payout.shop}</td>
                                                    <td className="has-text-weight-bold">{payout.coupon.title}</td>
                                                    <td className="has-text-weight-semibold">{Price.ConvertTemplate(payout?.payout?.affiliate_payout, `{{amount}} ${payout?.payout?.currency}`)}</td>
                                                    <td>{payout.status === "pending" ? <span className="tag is-warning is-light has-text-weight-semibold border border-solid border-color-warning">Pending</span> : <span className="tag is-success is-light has-text-weight-semibold border border-solid border-color-success">Complete</span>}</td>
                                                    <td>{moment(payout.created_at).format('D MMM, YYYY')}</td>
                                                    <td>{moment(payout.created_at).add('30', 'days').format('D MMM, YYYY')}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            {
                                isEmpty(payouts) ? <p className="pb-5 has-text-centered is-fullwidth">No payout requests made</p> : ""
                            }
                        </div>
                    </div>
                </div>
            </div>
        </AffiliateLayout>
    )
}

export default PayoutsCredit;