import { takeLatest, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { Storage } from '../helpers';

//    ______                __          ____                            _                ________
//   / ____/_______  ____ _/ /____     / __ \___  _______  ____________(_)___  ____ _   / ____/ /_  ____ __________ ____
//  / /   / ___/ _ \/ __ `/ __/ _ \   / /_/ / _ \/ ___/ / / / ___/ ___/ / __ \/ __ `/  / /   / __ \/ __ `/ ___/ __ `/ _ \
// / /___/ /  /  __/ /_/ / /_/  __/  / _, _/  __/ /__/ /_/ / /  / /  / / / / / /_/ /  / /___/ / / / /_/ / /  / /_/ /  __/
// \____/_/   \___/\__,_/\__/\___/  /_/ |_|\___/\___/\__,_/_/  /_/  /_/_/ /_/\__, /   \____/_/ /_/\__,_/_/   \__, /\___/
//                                                                          /____/                          /____/

export function* watchCreateRecurringCharge() {
    yield takeLatest("CREATE_RECURRING_CHARGE", trackCreateRecurringCharge);
}

function* trackCreateRecurringCharge(sagaData) {
    try {
        var response = yield call(callCreateRecurringCharge, sagaData.payload);

        if (response) {
            yield put({
                type: "CREATE_RECURRING_CHARGE_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "CREATE_RECURRING_CHARGE_ERROR",
            response: err
        });
    }
}

function callCreateRecurringCharge(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/shop/create-recurring-charge", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//    _____      __     ____                            _                ________
//   / ___/___  / /_   / __ \___  _______  ____________(_)___  ____ _   / ____/ /_  ____ __________ ____
//   \__ \/ _ \/ __/  / /_/ / _ \/ ___/ / / / ___/ ___/ / __ \/ __ `/  / /   / __ \/ __ `/ ___/ __ `/ _ \
//  ___/ /  __/ /_   / _, _/  __/ /__/ /_/ / /  / /  / / / / / /_/ /  / /___/ / / / /_/ / /  / /_/ /  __/
// /____/\___/\__/  /_/ |_|\___/\___/\__,_/_/  /_/  /_/_/ /_/\__, /   \____/_/ /_/\__,_/_/   \__, /\___/
//                                                          /____/                          /____/

export function* watchSetRecurringCharge() {
    yield takeLatest("SET_RECURRING_CHARGE", trackSetRecurringCharge);
}

function* trackSetRecurringCharge(sagaData) {
    try {
        var response = yield call(callSetRecurringCharge, sagaData.payload);

        if (response) {
            yield put({
                type: "SET_RECURRING_CHARGE_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "SET_RECURRING_CHARGE_ERROR",
            response: err
        });
    }
}

function callSetRecurringCharge(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/shop/set-recurring-charge", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//    ______                      __   ____                            _                ________
//   / ____/___ _____  ________  / /  / __ \___  _______  ____________(_)___  ____ _   / ____/ /_  ____ __________ ____
//  / /   / __ `/ __ \/ ___/ _ \/ /  / /_/ / _ \/ ___/ / / / ___/ ___/ / __ \/ __ `/  / /   / __ \/ __ `/ ___/ __ `/ _ \
// / /___/ /_/ / / / / /__/  __/ /  / _, _/  __/ /__/ /_/ / /  / /  / / / / / /_/ /  / /___/ / / / /_/ / /  / /_/ /  __/
// \____/\__,_/_/ /_/\___/\___/_/  /_/ |_|\___/\___/\__,_/_/  /_/  /_/_/ /_/\__, /   \____/_/ /_/\__,_/_/   \__, /\___/
//                                                                         /____/                          /____/

export function* watchCancelRecurringCharge() {
    yield takeLatest("CANCEL_RECURRING_CHARGE", trackCancelRecurringCharge);
}

function* trackCancelRecurringCharge(sagaData) {
    try {
        var response = yield call(callCancelRecurringCharge, sagaData.payload);

        if (response) {
            yield put({
                type: "CANCEL_RECURRING_CHARGE_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "CANCEL_RECURRING_CHARGE_ERROR",
            response: err
        });
    }
}

function callCancelRecurringCharge(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/shop/cancel-recurring-charge", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ______     __       __       ______           __
//    / ____/__  / /______/ /_     / ____/_  _______/ /_____  ____ ___  ___  __________
//   / /_  / _ \/ __/ ___/ __ \   / /   / / / / ___/ __/ __ \/ __ `__ \/ _ \/ ___/ ___/
//  / __/ /  __/ /_/ /__/ / / /  / /___/ /_/ (__  ) /_/ /_/ / / / / / /  __/ /  (__  )
// /_/    \___/\__/\___/_/ /_/   \____/\__,_/____/\__/\____/_/ /_/ /_/\___/_/  /____/

export function* watchFetchCustomers() {
    yield takeLatest("FETCH_CUSTOMERS", trackFetchCustomers);
}

function* trackFetchCustomers(sagaData) {
    try {
        var response = yield call(callFetchCustomers, sagaData.payload);

        if (response) {
            yield put({
                type: "FETCH_CUSTOMERS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "FETCH_CUSTOMERS_ERROR",
            response: err
        });
    }
}

function callFetchCustomers(saga) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/shop/fetch-customers", {
            params: saga,
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ______     __       __       ______           __                                    ______                  __
//    / ____/__  / /______/ /_     / ____/_  _______/ /_____  ____ ___  ___  __________   / ____/___  __  ______  / /_
//   / /_  / _ \/ __/ ___/ __ \   / /   / / / / ___/ __/ __ \/ __ `__ \/ _ \/ ___/ ___/  / /   / __ \/ / / / __ \/ __/
//  / __/ /  __/ /_/ /__/ / / /  / /___/ /_/ (__  ) /_/ /_/ / / / / / /  __/ /  (__  )  / /___/ /_/ / /_/ / / / / /_
// /_/    \___/\__/\___/_/ /_/   \____/\__,_/____/\__/\____/_/ /_/ /_/\___/_/  /____/   \____/\____/\__,_/_/ /_/\__/

export function* watchFetchCustomersCount() {
    yield takeLatest("FETCH_CUSTOMERS_COUNT", trackFetchCustomersCount);
}

function* trackFetchCustomersCount(sagaData) {
    try {
        var response = yield call(callFetchCustomersCount, sagaData.payload);

        if (response) {
            yield put({
                type: "FETCH_CUSTOMERS_COUNT_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "FETCH_CUSTOMERS_COUNT_ERROR",
            response: err
        });
    }
}

function callFetchCustomersCount(saga) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/shop/fetch-customers-count", {
            params: saga,
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//    ______                __          ___    _________ ___       __
//   / ____/_______  ____ _/ /____     /   |  / __/ __(_) (_)___ _/ /____
//  / /   / ___/ _ \/ __ `/ __/ _ \   / /| | / /_/ /_/ / / / __ `/ __/ _ \
// / /___/ /  /  __/ /_/ / /_/  __/  / ___ |/ __/ __/ / / / /_/ / /_/  __/
// \____/_/   \___/\__,_/\__/\___/  /_/  |_/_/ /_/ /_/_/_/\__,_/\__/\___/

export function* watchCreateAffiliate() {
    yield takeLatest("CREATE_AFFILIATE", trackCreateAffiliate);
}

function* trackCreateAffiliate(sagaData) {
    try {
        var response = yield call(callCreateAffiliate, sagaData.payload);

        if (response) {
            yield put({
                type: "CREATE_AFFILIATE_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "CREATE_AFFILIATE_ERROR",
            response: err
        });
    }
}

function callCreateAffiliate(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/shop/create-affiliate", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//    ______                __          ___    _________ ___       __          ____
//   / ____/_______  ____ _/ /____     /   |  / __/ __(_) (_)___ _/ /____     / __ \__  _____  __  _____
//  / /   / ___/ _ \/ __ `/ __/ _ \   / /| | / /_/ /_/ / / / __ `/ __/ _ \   / / / / / / / _ \/ / / / _ \
// / /___/ /  /  __/ /_/ / /_/  __/  / ___ |/ __/ __/ / / / /_/ / /_/  __/  / /_/ / /_/ /  __/ /_/ /  __/
// \____/_/   \___/\__,_/\__/\___/  /_/  |_/_/ /_/ /_/_/_/\__,_/\__/\___/   \___\_\__,_/\___/\__,_/\___/

export function* watchCreateAffiliateQueue() {
    yield takeLatest("CREATE_AFFILIATE_QUEUE", trackCreateAffiliateQueue);
}

function* trackCreateAffiliateQueue(sagaData) {
    try {
        var response = yield call(callCreateAffiliateQueue, sagaData.payload);

        if (response) {
            yield put({
                type: "CREATE_AFFILIATE_QUEUE_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "CREATE_AFFILIATE_QUEUE_ERROR",
            response: err
        });
    }
}

function callCreateAffiliateQueue(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/shop/create-affiliate-queue", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//    __  __          __      __          ___    _________ ___       __
//   / / / /___  ____/ /___ _/ /____     /   |  / __/ __(_) (_)___ _/ /____
//  / / / / __ \/ __  / __ `/ __/ _ \   / /| | / /_/ /_/ / / / __ `/ __/ _ \
// / /_/ / /_/ / /_/ / /_/ / /_/  __/  / ___ |/ __/ __/ / / / /_/ / /_/  __/
// \____/ .___/\__,_/\__,_/\__/\___/  /_/  |_/_/ /_/ /_/_/_/\__,_/\__/\___/
//     /_/

export function* watchUpdateAffiliate() {
    yield takeLatest("UPDATE_AFFILIATE", trackUpdateAffiliate);
}

function* trackUpdateAffiliate(sagaData) {
    try {
        var response = yield call(callUpdateAffiliate, sagaData.payload);

        if (response) {
            yield put({
                type: "UPDATE_AFFILIATE_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "UPDATE_AFFILIATE_ERROR",
            response: err
        });
    }
}

function callUpdateAffiliate(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/shop/update-affiliate", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ____                                        __          ____  _                             __     ______          __
//    / __ \___  ____ ____  ____  ___  _________ _/ /____     / __ \(_)_____________  __  ______  / /_   / ____/___  ____/ /__
//   / /_/ / _ \/ __ `/ _ \/ __ \/ _ \/ ___/ __ `/ __/ _ \   / / / / / ___/ ___/ __ \/ / / / __ \/ __/  / /   / __ \/ __  / _ \
//  / _, _/  __/ /_/ /  __/ / / /  __/ /  / /_/ / /_/  __/  / /_/ / (__  ) /__/ /_/ / /_/ / / / / /_   / /___/ /_/ / /_/ /  __/
// /_/ |_|\___/\__, /\___/_/ /_/\___/_/   \__,_/\__/\___/  /_____/_/____/\___/\____/\__,_/_/ /_/\__/   \____/\____/\__,_/\___/
//            /____/

export function* watchRegenerateDiscountCode() {
    yield takeLatest("REGENERATE_DISCOUNT_CODE", trackRegenerateDiscountCode);
}

function* trackRegenerateDiscountCode(sagaData) {
    try {
        var response = yield call(callRegenerateDiscountCode, sagaData.payload);

        if (response) {
            yield put({
                type: "REGENERATE_DISCOUNT_CODE_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "REGENERATE_DISCOUNT_CODE_ERROR",
            response: err
        });
    }
}

function callRegenerateDiscountCode(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/shop/regenerate-discount-code", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ______           _              ____  _                             __     ______          __
//    / ____/  ______  (_)_______     / __ \(_)_____________  __  ______  / /_   / ____/___  ____/ /__
//   / __/ | |/_/ __ \/ / ___/ _ \   / / / / / ___/ ___/ __ \/ / / / __ \/ __/  / /   / __ \/ __  / _ \
//  / /____>  </ /_/ / / /  /  __/  / /_/ / (__  ) /__/ /_/ / /_/ / / / / /_   / /___/ /_/ / /_/ /  __/
// /_____/_/|_/ .___/_/_/   \___/  /_____/_/____/\___/\____/\__,_/_/ /_/\__/   \____/\____/\__,_/\___/
//           /_/

export function* watchExpireDiscountCode() {
    yield takeLatest("EXPIRE_DISCOUNT_CODE", trackExpireDiscountCode);
}

function* trackExpireDiscountCode(sagaData) {
    try {
        var response = yield call(callExpireDiscountCode, sagaData.payload);

        if (response) {
            yield put({
                type: "EXPIRE_DISCOUNT_CODE_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "EXPIRE_DISCOUNT_CODE_ERROR",
            response: err
        });
    }
}

function callExpireDiscountCode(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/shop/expire-coupon", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ____                                    ____  _                             __     ______          __
//    / __ \___  ____ ___  ____ _   _____     / __ \(_)_____________  __  ______  / /_   / ____/___  ____/ /__
//   / /_/ / _ \/ __ `__ \/ __ \ | / / _ \   / / / / / ___/ ___/ __ \/ / / / __ \/ __/  / /   / __ \/ __  / _ \
//  / _, _/  __/ / / / / / /_/ / |/ /  __/  / /_/ / (__  ) /__/ /_/ / /_/ / / / / /_   / /___/ /_/ / /_/ /  __/
// /_/ |_|\___/_/ /_/ /_/\____/|___/\___/  /_____/_/____/\___/\____/\__,_/_/ /_/\__/   \____/\____/\__,_/\___/


export function* watchRemoveDiscountCode() {
    yield takeLatest("REMOVE_DISCOUNT_CODE", trackRemoveDiscountCode);
}

function* trackRemoveDiscountCode(sagaData) {
    try {
        var response = yield call(callRemoveDiscountCode, sagaData.payload);

        if (response) {
            yield put({
                type: "REMOVE_DISCOUNT_CODE_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "REMOVE_DISCOUNT_CODE_ERROR",
            response: err
        });
    }
}

function callRemoveDiscountCode(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/shop/remove-coupon", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ____                                     ____  _                             __     ______          __
//    / __ \___  ____  ____ _____ ___  ___     / __ \(_)_____________  __  ______  / /_   / ____/___  ____/ /__
//   / /_/ / _ \/ __ \/ __ `/ __ `__ \/ _ \   / / / / / ___/ ___/ __ \/ / / / __ \/ __/  / /   / __ \/ __  / _ \
//  / _, _/  __/ / / / /_/ / / / / / /  __/  / /_/ / (__  ) /__/ /_/ / /_/ / / / / /_   / /___/ /_/ / /_/ /  __/
// /_/ |_|\___/_/ /_/\__,_/_/ /_/ /_/\___/  /_____/_/____/\___/\____/\__,_/_/ /_/\__/   \____/\____/\__,_/\___/

export function* watchRenameDiscountCode() {
    yield takeLatest("RENAME_DISCOUNT_CODE", trackRenameDiscountCode);
}

function* trackRenameDiscountCode(sagaData) {
    try {
        var response = yield call(callRenameDiscountCode, sagaData.payload);

        if (response) {
            yield put({
                type: "RENAME_DISCOUNT_CODE_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "RENAME_DISCOUNT_CODE_ERROR",
            response: err
        });
    }
}

function callRenameDiscountCode(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/affiliate/rename-coupon", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("affiliate_token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ______                      __     ______           __                                    __    _      __
//    / ____/  ______  ____  _____/ /_   / ____/_  _______/ /_____  ____ ___  ___  __________   / /   (_)____/ /_
//   / __/ | |/_/ __ \/ __ \/ ___/ __/  / /   / / / / ___/ __/ __ \/ __ `__ \/ _ \/ ___/ ___/  / /   / / ___/ __/
//  / /____>  </ /_/ / /_/ / /  / /_   / /___/ /_/ (__  ) /_/ /_/ / / / / / /  __/ /  (__  )  / /___/ (__  ) /_
// /_____/_/|_/ .___/\____/_/   \__/   \____/\__,_/____/\__/\____/_/ /_/ /_/\___/_/  /____/  /_____/_/____/\__/
//           /_/

export function* watchExportCustomersList() {
    yield takeLatest("EXPORT_CUSTOMERS_LIST", trackExportCustomersList);
}

function* trackExportCustomersList(sagaData) {
    try {
        var response = yield call(callExportCustomersList, sagaData.payload);

        if (response) {
            yield put({
                type: "EXPORT_CUSTOMERS_LIST_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "EXPORT_CUSTOMERS_LIST_ERROR",
            response: err
        });
    }
}

function callExportCustomersList(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/shop/export-customers-list", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ____                           __     ______           __                                    __    _      __
//    /  _/___ ___  ____  ____  _____/ /_   / ____/_  _______/ /_____  ____ ___  ___  __________   / /   (_)____/ /_
//    / // __ `__ \/ __ \/ __ \/ ___/ __/  / /   / / / / ___/ __/ __ \/ __ `__ \/ _ \/ ___/ ___/  / /   / / ___/ __/
//  _/ // / / / / / /_/ / /_/ / /  / /_   / /___/ /_/ (__  ) /_/ /_/ / / / / / /  __/ /  (__  )  / /___/ (__  ) /_
// /___/_/ /_/ /_/ .___/\____/_/   \__/   \____/\__,_/____/\__/\____/_/ /_/ /_/\___/_/  /____/  /_____/_/____/\__/
//              /_/

export function* watchImportCustomersList() {
    yield takeLatest("IMPORT_CUSTOMERS_LIST", trackImportCustomersList);
}

function* trackImportCustomersList(sagaData) {
    try {
        var response = yield call(callImportCustomersList, sagaData.payload);

        if (response) {
            yield put({
                type: "IMPORT_CUSTOMERS_LIST_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "IMPORT_CUSTOMERS_LIST_ERROR",
            response: err
        });
    }
}

function callImportCustomersList(saga) {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_ENDPOINT + "/shop/import-customers-list", {
            "method": 'POST',
            "headers": {
                "Authorization": "Bearer " + Storage.Fetch("token")
            },
            "body": saga
        }).then(response => {
            return response.json()
        }).then(data => {
            resolve(data);
        }).catch((err) => {
            reject(err);
        });
    });
}

//     ____                    __      __          ______           __                                    __    _      __
//    / __ \____  ____  __  __/ /___ _/ /____     / ____/_  _______/ /_____  ____ ___  ___  __________   / /   (_)____/ /_
//   / /_/ / __ \/ __ \/ / / / / __ `/ __/ _ \   / /   / / / / ___/ __/ __ \/ __ `__ \/ _ \/ ___/ ___/  / /   / / ___/ __/
//  / ____/ /_/ / /_/ / /_/ / / /_/ / /_/  __/  / /___/ /_/ (__  ) /_/ /_/ / / / / / /  __/ /  (__  )  / /___/ (__  ) /_
// /_/    \____/ .___/\__,_/_/\__,_/\__/\___/   \____/\__,_/____/\__/\____/_/ /_/ /_/\___/_/  /____/  /_____/_/____/\__/
//            /_/

export function* watchPopulateCustomersList() {
    yield takeLatest("POPULATE_CUSTOMERS_LIST", trackPopulateCustomersList);
}

function* trackPopulateCustomersList(sagaData) {
    try {
        var response = yield call(callPopulateCustomersList, sagaData.payload);

        if (response) {
            yield put({
                type: "POPULATE_CUSTOMERS_LIST_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "POPULATE_CUSTOMERS_LIST_ERROR",
            response: err
        });
    }
}

function callPopulateCustomersList(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/shop/populate-customers-list", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ______     __       __       ___    _________ ___       __          ____                             __
//    / ____/__  / /______/ /_     /   |  / __/ __(_) (_)___ _/ /____     / __ \___  ____ ___  _____  _____/ /______
//   / /_  / _ \/ __/ ___/ __ \   / /| | / /_/ /_/ / / / __ `/ __/ _ \   / /_/ / _ \/ __ `/ / / / _ \/ ___/ __/ ___/
//  / __/ /  __/ /_/ /__/ / / /  / ___ |/ __/ __/ / / / /_/ / /_/  __/  / _, _/  __/ /_/ / /_/ /  __(__  ) /_(__  )
// /_/    \___/\__/\___/_/ /_/  /_/  |_/_/ /_/ /_/_/_/\__,_/\__/\___/  /_/ |_|\___/\__, /\__,_/\___/____/\__/____/
//                                                                                   /_/

export function* watchFetchAffiliateRequests() {
    yield takeLatest("FETCH_AFFILIATE_REQUESTS", trackFetchAffiliateRequests);
}

function* trackFetchAffiliateRequests(sagaData) {
    try {
        var response = yield call(callFetchAffiliateRequests, sagaData.payload);

        if (response) {
            yield put({
                type: "FETCH_AFFILIATE_REQUESTS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "FETCH_AFFILIATE_REQUESTS_ERROR",
            response: err
        });
    }
}

function callFetchAffiliateRequests(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/shop/fetch-affiliate-requests", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ____         _           __     ___    _________ ___       __          ____                             __ 
//    / __ \___    (_)__  _____/ /_   /   |  / __/ __(_) (_)___ _/ /____     / __ \___  ____ ___  _____  _____/ /_
//   / /_/ / _ \  / / _ \/ ___/ __/  / /| | / /_/ /_/ / / / __ `/ __/ _ \   / /_/ / _ \/ __ `/ / / / _ \/ ___/ __/
//  / _, _/  __/ / /  __/ /__/ /_   / ___ |/ __/ __/ / / / /_/ / /_/  __/  / _, _/  __/ /_/ / /_/ /  __(__  ) /_  
// /_/ |_|\___/_/ /\___/\___/\__/  /_/  |_/_/ /_/ /_/_/_/\__,_/\__/\___/  /_/ |_|\___/\__, /\__,_/\___/____/\__/  
//           /___/                                                                      /_/                       

export function* watchRejectAffiliateRequest() {
    yield takeLatest("REJECT_AFFILIATE_REQUEST", trackRejectAffiliateRequest);
}

function* trackRejectAffiliateRequest(sagaData) {
    try {
        var response = yield call(callRejectAffiliateRequest, sagaData.payload);

        if (response) {
            yield put({
                type: "REJECT_AFFILIATE_REQUEST_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "REJECT_AFFILIATE_REQUEST_ERROR",
            response: err
        });
    }
}

function callRejectAffiliateRequest(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/shop/reject-affiliate-request", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}