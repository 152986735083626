import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import Swal from "sweetalert2";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import AdminLayout from '../layouts/admin-layout';

function AdminPlans(props) {
    const dispatch = useDispatch();
    const [addModal, setAddModal] = useState('');
    const [editModal, setEditModal] = useState('');
    const [generateModal, setGenerateModal] = useState('');
    const [plan, setPlan] = useState({});
    const [plans, setPlans] = useState({});

    const planProps = useSelector(state => state.plans);

    // Fetch
    useEffect(() => {
        dispatch({
            type: "ADMIN_GET_PLANS",
            payload: ''
        });
    }, []);

    useEffect(() => {
        if (!isEmpty(planProps)) {
            setPlans({ ...planProps });
        }
    }, [planProps]);

    // Add
    const schema = yup.object().shape({
        title: yup.string().required(),
        price: yup.number().required(),
        trial: yup.number().required(),
        commission: yup.number().required(),
        features: yup.string().required()
    });

    const { register, handleSubmit, errors, reset } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (form, e) => {
        setAddModal('');
        dispatch({
            type: "ADMIN_ADD_PLAN",
            payload: form
        });
        reset();
    }

    // Edit
    const schema2 = yup.object().shape({
        id: yup.string(),
        title: yup.string().required(),
        price: yup.number().required(),
        trial: yup.number().required(),
        commission: yup.number().required(),
        features: yup.string().required()
    });

    const { register: register2, handleSubmit: handleSubmit2, errors: errors2, reset: reset2 } = useForm({
        resolver: yupResolver(schema2)
    });

    const onSubmit2 = (form) => {
        setEditModal('');
        dispatch({
            type: "ADMIN_UPDATE_PLAN",
            payload: form
        });

        reset2();
    }

    // Generate
    const schema3 = yup.object().shape({
        shop: yup.string(),
        price: yup.number().required(),
        trial: yup.number().required(),
        commission: yup.number().required()
    });

    const { register: register3, handleSubmit: handleSubmit3, errors: errors3, reset: reset3 } = useForm({
        resolver: yupResolver(schema3)
    });

    const onSubmit3 = (form) => {
        setGenerateModal('');

        var link = ``;
        var data = {
            shop: `${form.shop}.myshopify.com`,
            price: form.price,
            trial: form.trial,
            commission: form.commission
        };

        if (form.shop === "") {
            link = `${process.env.REACT_APP_APP_ENDPOINT}/install-app?data=${btoa(JSON.stringify(data))}`;
        } else {
            link = `${process.env.REACT_APP_APP_ENDPOINT}/install-app?shop=${data.shop}&data=${btoa(JSON.stringify(data))}`;
        }

        Swal.fire({
            title: 'Please copy the link below and send it the user for installation',
            html: `<div style="display: block; background: #fff; padding: 10px; border: 3px dashed #29348e;">${link}</div>`,
            icon: 'warning',
            showConfirmButton: true,
            confirmButtonText: "Copy to Clipboard"
        }).then((result) => {
            if (result.isConfirmed) {
                navigator.clipboard.writeText(link);
            }
        });

        reset3();
    }

    // Actions
    const openEditModal = (e, index) => {
        setPlan(plans[index]);
        setEditModal('is-active');
    }

    const openDeleteModal = (e, index) => {
        Swal.fire({
            title: "Are you sure you want to delete the plan?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: "No"
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch({
                    type: "ADMIN_REMOVE_PLAN",
                    payload: {
                        id: plans[index]._id
                    }
                });

                delete plans[index];
                setPlans({ ...plans });
            }
        });
    }

    return (
        <AdminLayout {...props} >
            <section className="pb-3">
                <div className="is-md-flex is-align-items-center is-justify-content-space-between">
                    <div>
                        <h1 className="title is-size-4 is-size-4-tablet mb-2 has-text-primary"> Plans </h1>
                    </div>
                    <div>
                        <button className="button is-danger with-shadow mr-2" onClick={() => setGenerateModal('is-active')}> Generate Enterprise Install Link </button>
                        <button className="button is-primary with-shadow" onClick={() => setAddModal('is-active')}> Add Plan </button>
                    </div>
                </div>
            </section>

            <div className="columns is-multiline">
                <div className="column is-12">
                    <div className="box">
                        <div className="table-container">
                            <table className="table is-striped is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Title</th>
                                        <th>Price</th>
                                        <th>Trial</th>
                                        <th>Commission</th>
                                        <th>Features</th>
                                        <th align="right">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !isEmpty(plans) && Object.values(plans).map((plan, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{plan.title}</td>
                                                    <td>${plan.price}</td>
                                                    <td>{plan.trial}</td>
                                                    <td>{plan.commission}%</td>
                                                    <td>
                                                        <ul>
                                                            <li>
                                                                {
                                                                    plan.trial === 0 ? '' : `- ${plan.trial} days free trial`
                                                                }
                                                            </li>
                                                            {
                                                                plan?.features && plan.features.split(',').map((feature, index) => {
                                                                    return (
                                                                        <li key={index}>- {feature}</li>
                                                                    )
                                                                })
                                                            }
                                                            <li>
                                                                {
                                                                    plan.commission === 0 ? '' : `- ${plan.commission}% Transaction Fee`
                                                                }
                                                            </li>
                                                        </ul>
                                                    </td>
                                                    <td>
                                                        <div className="buttons is-flex is-justify-content-flex-end">
                                                            <button className="button is-small is-info" onClick={(e) => openEditModal(e, index)}>Edit</button>
                                                            <button className="button is-small is-danger" onClick={(e) => openDeleteModal(e, index)}>Delete</button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`modal ${addModal}`}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <header className="modal-card-head">
                            <p className="modal-card-title is-size-5 has-text-weight-semibold">Add Plan</p>
                            <a className="delete" aria-label="close" onClick={() => setAddModal('')}>x</a>
                        </header>
                        <section className="modal-card-body">
                            <div className="field">
                                <label className="label">Title</label>
                                <input className="input" type="text" name="title" placeholder="Title" ref={register} />
                                <p className="help is-danger">{errors.title && 'Title is required'}</p>
                            </div>
                            <div className="field">
                                <label className="label">Price</label>
                                <input className="input" type="number" name="price" placeholder="Price" ref={register} />
                                <p className="help is-danger">{errors.price && 'Price is required'}</p>
                            </div>
                            <div className="field">
                                <label className="label">Trial (in Days)</label>
                                <input className="input" type="number" name="trial" placeholder="Trial" ref={register} />
                                <p className="help is-danger">{errors.trial && 'Trial days are required. Add 0 for no trial.'}</p>
                            </div>
                            <div className="field">
                                <label className="label">Commission (in Percentage)</label>
                                <input className="input" type="number" name="commission" placeholder="Commission" ref={register} />
                                <p className="help is-danger">{errors.commission && 'Commission percentage is required.'}</p>
                            </div>
                            <div className="field">
                                <label className="label">Features (Comma Seperated)</label>
                                <textarea className="textarea" name="features" placeholder="Features" ref={register}></textarea>
                                <p className="help is-danger">{errors.features && 'Features are required'}</p>
                            </div>
                        </section>
                        <footer className="modal-card-foot is-justify-content-flex-end">
                            <a className="button has-background-white-bis border border-sold border-color-white-bis has-text-dark" onClick={() => setAddModal('')}>Cancel</a>
                            <button className="button is-primary" type="submit">Submit</button>
                        </footer>
                    </form>
                </div>
            </div>

            <div className={`modal ${editModal}`}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <form onSubmit={handleSubmit2(onSubmit2)} noValidate>
                        <header className="modal-card-head">
                            <p className="modal-card-title is-size-5 has-text-weight-semibold">Edit Plan</p>
                            <a className="delete" aria-label="close" onClick={() => setEditModal('')}></a>
                        </header>
                        <section className="modal-card-body">
                            <input className="input" type="hidden" name="id" defaultValue={plan._id} ref={register2} />
                            <div className="field">
                                <label className="label">Title</label>
                                <input className="input" type="text" name="title" placeholder="Title" defaultValue={plan.title} ref={register2} />
                                <p className="help is-danger">{errors2.title && 'Title is required'}</p>
                            </div>
                            <div className="field">
                                <label className="label">Price</label>
                                <input className="input" type="number" name="price" placeholder="Price" defaultValue={plan.price} ref={register2} />
                                <p className="help is-danger">{errors2.price && 'Price is required'}</p>
                            </div>
                            <div className="field">
                                <label className="label">Trial (in Days)</label>
                                <input className="input" type="number" name="trial" placeholder="Trial" defaultValue={plan.trial} ref={register2} />
                                <p className="help is-danger">{errors2.trial && 'Trial days are required. Add 0 for no trial.'}</p>
                            </div>
                            <div className="field">
                                <label className="label">Commission (in Percentage)</label>
                                <input className="input" type="number" name="commission" placeholder="Commission" defaultValue={plan.commission} ref={register2} />
                                <p className="help is-danger">{errors2.commission && 'Commission percentage is required.'}</p>
                            </div>
                            <div className="field">
                                <label className="label">Features (Comma Seperated)</label>
                                <textarea className="textarea" name="features" placeholder="Features" ref={register2} defaultValue={plan.features}></textarea>
                                <p className="help is-danger">{errors2.features && 'Features are required'}</p>
                            </div>
                        </section>
                        <footer className="modal-card-foot is-justify-content-flex-end">
                            <a className="button has-background-white-bis border border-sold border-color-white-bis has-text-dark" onClick={() => setEditModal('')}>Cancel</a>
                            <button className="button is-primary" type="submit">Submit</button>
                        </footer>
                    </form>
                </div>
            </div>

            <div className={`modal ${generateModal}`}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <form onSubmit={handleSubmit3(onSubmit3)} noValidate>
                        <header className="modal-card-head">
                            <p className="modal-card-title is-size-5 has-text-weight-semibold">Generate Link</p>
                            <a className="delete" aria-label="close" onClick={() => setGenerateModal('')}></a>
                        </header>
                        <section className="modal-card-body">
                            <label className="label">Shop <span className="is-size-7 has-text-danger">(Don't add shop for webinar installation link)</span></label>
                            <div className="field has-addons">
                                <p className="control is-flex-grow-1">
                                    <input className="input" type="text" name="shop" placeholder="ssclub1" ref={register3} />
                                </p>
                                <p className="control">
                                    <a className="button is-static h-full"> .myshopify.com </a>
                                </p>
                            </div>
                            <div className="field">
                                <label className="label">Price</label>
                                <input className="input" type="number" name="price" placeholder="Price" ref={register3} />
                                <p className="help is-danger">{errors3.price && 'Price is required'}</p>
                            </div>
                            <div className="field">
                                <label className="label">Trial (in Days)</label>
                                <input className="input" type="number" name="trial" placeholder="Trial" ref={register3} />
                                <p className="help is-danger">{errors3.trial && 'Trial days are required. Add 0 for no trial.'}</p>
                            </div>
                            <div className="field">
                                <label className="label">Commission (in Percentage)</label>
                                <input className="input" type="number" name="commission" placeholder="Commission" ref={register3} />
                                <p className="help is-danger">{errors3.commission && 'Commission percentage is required.'}</p>
                            </div>
                        </section>
                        <footer className="modal-card-foot is-justify-content-flex-end">
                            <a className="button has-background-white-bis border border-sold border-color-white-bis has-text-dark" onClick={() => setGenerateModal('')}>Cancel</a>
                            <button className="button is-primary" type="submit">Generate</button>
                        </footer>
                    </form>
                </div>
            </div>
        </AdminLayout>
    )
}

export default AdminPlans;