import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { isEmpty } from "lodash";

import { Storage } from '../../helpers';

import Spinner from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function InstallAppCallBack() {
    const history = useHistory();
    const dispatch = useDispatch();
    const authProps = useSelector(state => state.auth);

    var location = new URLSearchParams(useLocation().search);

    useEffect(() => {
        dispatch({
            type: "INIT",
            payload: {
                shop: location.get('shop'),
                code: location.get('code')
            }
        });
    }, []);

    useEffect(() => {
        if (!isEmpty(authProps) && authProps.loading !== false) {
            dispatch({
                type: "LOGIN",
                payload: {
                    shop: location.get('shop')
                }
            });
        }
    }, [authProps]);

    useEffect(() => {
        if (!isEmpty(authProps) && authProps.token) {
            Storage.Add('token', authProps.token);
            let plan_data = sessionStorage.getItem('plan_data');
            if (authProps.email === undefined || authProps.postal_code === undefined || authProps.country === undefined || authProps.plan_details === undefined) {
                history.push('/onboarding')
            } else if (plan_data !== null) {
                history.push('/onboarding')
            } else {
                history.push('/dashboard');
            }
        }
    }, [authProps]);

    return (
        <div className="loaderContainer">
            <Spinner type="Rings" color="Black" height={150} width={150} visible={true} />
        </div>
    );
}

export default InstallAppCallBack;