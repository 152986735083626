import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

import AffiliateLayout from '../layouts/affiliate-layout';

function Vouchers(props) {
    const dispatch = useDispatch();
    const authProps = useSelector(state => state.auth);
    const voucherProps = useSelector(state => state.vouchers);
    const [vouchers, setVouchers] = useState({});

    useEffect(() => {
        dispatch({
            type: "AFFILIATE_FETCH_VOUCHERS",
            payload: {}
        });
    }, []);

    useEffect(() => {
        if (!isEmpty(voucherProps)) {
            setVouchers(voucherProps);
        }
    }, [voucherProps]);

    return (
        <AffiliateLayout {...props}>
            <section className="pb-3">
                <div className="is-md-flex is-align-items-center is-justify-content-space-between">
                    <div>
                        <h1 className="title is-size-4 is-size-4-tablet mb-2 has-text-primary"> My Active In-store credit vouchers </h1>
                    </div>
                </div>
            </section>

            <div className="columns is-multiline">
                <div className="column is-12">
                    <div className="box">
                        <div className="table-container">
                            <table className="table is-striped is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>Store</th>
                                        <th>Voucher Code</th>
                                        <th>Voucher Amount</th>
                                        <th align="right">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        Object.values(vouchers).map((voucher, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td><a href={`https://${voucher.shop}`} target="_blank" rel="noopener noreferrer">{voucher?.company ? voucher.company : voucher.shop}</a></td>
                                                    <td className="has-text-weight-bold">{voucher?.coupon}</td>
                                                    <td className="has-text-weight-bold">{voucher?.value} {voucher?.currency}</td>
                                                    <td align="right">
                                                        <button className="button is-primary is-small mr-1" onClick={() => navigator.clipboard.writeText(voucher?.coupon)}>Copy to Clipboard</button>
                                                        <a href={`https://${voucher.shop}`} target="_blank" rel="noopener noreferrer" className="button is-success is-small">Open Store</a>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            {
                                isEmpty(vouchers) ? <p className="pb-5 has-text-centered is-fullwidth">No vouchers generated</p> : ""
                            }
                        </div>
                    </div>
                </div>
            </div>
        </AffiliateLayout>
    )
}

export default Vouchers;