import { all, fork } from 'redux-saga/effects';

import * as adminSagas from "./sagas/admin";
import * as authSagas from "./sagas/auth";
import * as shopSagas from "./sagas/shop";
import * as affiliateSagas from "./sagas/affiliate";

export default function* rootSaga() {
  yield all([
    ...Object.values(adminSagas),
    ...Object.values(authSagas),
    ...Object.values(shopSagas),
    ...Object.values(affiliateSagas),
  ].map(fork));
}