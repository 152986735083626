import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import Swal from "sweetalert2";
import moment from "moment";

import { LoaderContext } from '../loader';
import AdminLayout from '../layouts/admin-layout';

function Payouts(props) {
    const dispatch = useDispatch();
    const authProps = useSelector(state => state.auth);
    const payoutsProps = useSelector(state => state.payouts);
    const failedProps = useSelector(state => state.failed);
    const cancelledProps = useSelector(state => state.cancelled);
    const pdfProps = useSelector(state => state.pdfs);
    const [payouts, setPayouts] = useState({});
    const [failed, setFailed] = useState({});
    const [cancelled, setCancelled] = useState({});
    const { setLoading } = useContext(LoaderContext);

    useEffect(() => {
        PullData();
    }, []);

    function PullData() {
        setLoading(false);
        dispatch({
            type: "ADMIN_FETCH_PAYOUTS",
            payload: {}
        });

        dispatch({
            type: "ADMIN_LIST_FAILED_TRANSACTIONS",
            payload: {}
        });

        dispatch({
            type: "ADMIN_LIST_CANCELLED_TRANSACTIONS",
            payload: {}
        });
    }

    useEffect(() => {
        if (!isEmpty(payoutsProps)) {
            setPayouts(payoutsProps);
        }
        setLoading(false);
    }, [payoutsProps]);

    useEffect(() => {
        if (!isEmpty(failedProps)) {
            setFailed(failedProps);
        }
        setLoading(false);
    }, [failedProps]);

    useEffect(() => {
        if (!isEmpty(cancelledProps)) {
            setCancelled(cancelledProps);
        }
        setLoading(false);
    }, [cancelledProps]);

    function SelectPayouts(e) {
        let payoutCheckboxes = document.getElementsByName('payouts-checkbox');

        for (let i = 0; i < payoutCheckboxes.length; i++) {
            if (e.target.checked) {
                payoutCheckboxes[i].checked = true;
            } else {
                payoutCheckboxes[i].checked = false;
            }
        }
    }

    function CompleteSelectedPayouts() {
        setLoading(true);

        let payoutCheckboxes = document.getElementsByName('payouts-checkbox');
        let checkedIndexes = [];

        for (let i = 0; i < payoutCheckboxes.length; i++) {
            if (payoutCheckboxes[i].checked) {
                checkedIndexes.push(payouts[i].id);
            }
        }

        dispatch({
            type: "ADMIN_COMPLETE_PAYOUTS",
            payload: checkedIndexes
        });

        for (let i = 0; i < payoutCheckboxes.length; i++) {
            payoutCheckboxes[i].checked = false;
        }
    }

    function CompletePayout(id) {
        setLoading(true);

        dispatch({
            type: "ADMIN_COMPLETE_PAYOUTS",
            payload: [id]
        });
    }

    useEffect(() => {
        if (!isEmpty(pdfProps)) {
            PullData();
        }
    }, [pdfProps]);

    function UpdateOrder(id, status) {
        Swal.fire({
            title: `This will update the status of the transaction to '${status}'?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: "No"
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                dispatch({
                    type: "ADMIN_MANAGE_CANCELLED_TRANSACTIONS",
                    payload: {
                        id: id,
                        status: status
                    }
                });

                setTimeout(() => {
                    setLoading(false);
                }, 2000);
            }
        });
    }

    return (
        <AdminLayout {...props}>
            <section className="pb-3">
                <div className="is-md-flex is-align-items-center is-justify-content-space-between">
                    <div>
                        <h1 className="title is-size-4 is-size-4-tablet mb-2 has-text-primary"> Payouts </h1>
                    </div>
                    <div>
                        <button className="button is-primary has-text-white with-shadow" onClick={() => CompleteSelectedPayouts()}>Complete Selected</button>
                    </div>
                </div>
            </section>

            <div className="columns is-multiline">
                <div className="column is-12">
                    <div className="message is-warning mb-3">
                        <div className="message-body"> <b>Note : </b>When selecting multiple payouts for bulk payments, please make sure the users are the same as it will sum up the payout value and create only one invoice.</div>
                    </div>
                    <div className="box">
                        <div className="table-container">
                            <table className="table is-striped is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>
                                            <input type="checkbox" onChange={(e) => SelectPayouts(e)} />
                                        </th>
                                        <th>Company</th>
                                        <th>Affiliate Name</th>
                                        <th>Affiliate Email</th>
                                        <th>Affiliate Payout</th>
                                        <th>App Commission</th>
                                        <th>Status</th>
                                        <th>Requested On</th>
                                        <th>Bank Added</th>
                                        <th>Address Added</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        Object.values(payouts).map((payout, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td><input type="checkbox" name="payouts-checkbox" /></td>
                                                    <td>{payout.company === "" ? payout.shop : payout.company}</td>
                                                    <td>{payout?.affiliate?.firstname} {payout?.affiliate?.lastname}</td>
                                                    <td>{payout?.affiliate?.email}</td>
                                                    <td className="has-text-weight-bold">{payout?.amount}</td>
                                                    <td className="has-text-weight-bold">{payout?.commission}</td>
                                                    <td><span className="tag is-warning is-light has-text-weight-semibold border border-solid border-color-warning">Pending</span></td>
                                                    <td className="has-text-weight-bold">{payout?.requested_on}</td>
                                                    <td>
                                                        {
                                                            payout.bank_details === true ?
                                                                <span className="tag is-success is-light has-text-weight-semibold border border-solid border-color-success">Yes</span> :
                                                                <span className="tag is-danger is-light has-text-weight-semibold border border-solid border-color-danger">No</span>
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            payout.address === true && payout.city === true && payout.state === true && payout.country === true && payout.postal_code === true ?
                                                                <span className="tag is-success is-light has-text-weight-semibold border border-solid border-color-success">Yes</span> :
                                                                <span className="tag is-danger is-light has-text-weight-semibold border border-solid border-color-danger">No</span>
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            payout.bank_details === true && payout.address === true && payout.city === true && payout.state === true && payout.country === true && payout.postal_code === true ?
                                                                <button className="button is-primary is-small has-text-white" onClick={() => CompletePayout(payout.id)}>Complete Payout</button> : ""
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            {
                                isEmpty(payouts) ? <p className="pb-5 has-text-centered is-fullwidth">No payout requests made</p> : ""
                            }
                        </div>
                    </div>
                </div>
            </div>

            <section className="pb-3">
                <div className="is-md-flex is-align-items-center is-justify-content-space-between">
                    <h1 className="title is-size-4 is-size-4-tablet mb-2 has-text-primary"> Failed Transactions </h1>
                </div>
            </section>

            <div className="columns is-multiline">
                <div className="column is-12">
                    <div className="message is-warning mb-3">
                        <div className="message-body"> <b>Note : </b>These are the orders for which we were not able to charge the credit card of the store owner. We have sent them an email, the orders will disappear as soon as they fix the credit card and process the order.</div>
                    </div>
                    <div className="box">
                        <div className="table-container">
                            <table className="table is-striped is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>Order Number</th>
                                        <th>Company</th>
                                        <th>Store</th>
                                        <th>Store Email</th>
                                        <th>Affilaite</th>
                                        <th>Affilaite Email</th>
                                        <th>Created At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !isEmpty(failed) && Object.values(failed).map((fail, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{fail?.charge?.description}</td>
                                                    <td>{fail?.user?.company}</td>
                                                    <td>{fail?.user?.shop}</td>
                                                    <td>{fail?.user?.email}</td>
                                                    <td>{fail?.transaction?.affiliate?.firstname} {fail?.transaction?.affiliate?.lastname}</td>
                                                    <td>{fail?.transaction?.affiliate.email}</td>
                                                    <td>{moment(fail?.created_at).format('D MMM, YYYY')}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            {
                                isEmpty(failed) ? <p className="pb-5 has-text-centered is-fullwidth">No failed transactions</p> : ""
                            }
                        </div>
                    </div>
                </div>
            </div>

            <section className="pb-3">
                <div className="is-md-flex is-align-items-center is-justify-content-space-between">
                    <h1 className="title is-size-4 is-size-4-tablet mb-2 has-text-primary"> Cancelled Transactions </h1>
                </div>
            </section>

            <div className="columns is-multiline">
                <div className="column is-12">
                    <div className="message is-warning mb-3">
                        <div className="message-body"> <b>Note : </b>These are the orders which were cancelled by the store owners due to no inventory, failed payment, voided payment or just cancelled.</div>
                    </div>
                    <div className="box">
                        <div className="table-container">
                            <table className="table is-striped is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>Order Number</th>
                                        <th>Type</th>
                                        <th>Company</th>
                                        <th>Store</th>
                                        <th>Store Email</th>
                                        <th>Affilaite</th>
                                        <th>Affilaite Email</th>
                                        <th>App Payout</th>
                                        <th>Affiliate Payout</th>
                                        <th>Total Paid</th>
                                        <th>Updated At</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !isEmpty(cancelled) && Object.values(cancelled).map((cancel, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{cancel?.payout?.charge_data?.description == null ? "N/A" : cancel?.payout?.charge_data?.description}</td>
                                                    <td>{cancel?.payout?.charge_method === "bycash" ? <span className="tag is-primary is-light has-text-weight-semibold border border-solid border-color-primary">Cash</span> : <span className="tag is-info is-light has-text-weight-semibold border border-solid border-color-info">Credits</span>}</td>
                                                    <td>{cancel?.user?.company}</td>
                                                    <td>{cancel?.user?.shop}</td>
                                                    <td>{cancel?.user?.email}</td>
                                                    <td>{cancel?.affiliate?.firstname} {cancel?.affiliate?.lastname}</td>
                                                    <td>{cancel?.affiliate?.email}</td>
                                                    <td>{(cancel?.payout?.app_payout * cancel?.payout?.conversion_rate).toFixed(2)} {cancel?.payout?.currency}</td>
                                                    <td>{(cancel?.payout?.affiliate_payout * cancel?.payout?.conversion_rate).toFixed(2)} {cancel?.payout?.currency}</td>
                                                    <td><a href={cancel?.payout?.charge_data?.receipt_url} target="_blank" rel="noopener noreferrer">{((cancel?.payout?.app_payout + cancel?.payout?.affiliate_payout) * cancel?.payout?.conversion_rate).toFixed(2)} {cancel?.payout?.currency}</a></td>
                                                    <td>{moment(cancel?.updated_at).format('D MMM, YYYY')}</td>
                                                    <td>
                                                        <button className="button is-primary has-text-white is-small mr-1" onClick={() => UpdateOrder(cancel?._id, 'refunded')}>Mark Refunded</button>
                                                        <button className="button is-info has-text-white is-small mt-1" onClick={() => UpdateOrder(cancel?._id, 'managed')}>Mark Managed</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            {
                                isEmpty(cancelled) ? <p className="pb-5 has-text-centered is-fullwidth">No Cancelled transactions</p> : ""
                            }
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}

export default Payouts;