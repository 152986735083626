import React, { useContext } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { LoaderContext } from '../loader';
import AuthLayout from '../layouts/auth-layout';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

function ForgotPassword(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const { setLoading } = useContext(LoaderContext);

    const schema = yup.object().shape({
        email: yup.string().email().required(),
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (form) => {
        setLoading(true);

        dispatch({
            type: "AFFILIATE_RESET_PASSWORD",
            payload: form
        });

        setTimeout(() => {
            setLoading(false);
            history.push('/affiliate/login');
        }, 6000);
    }

    return (
        <section className="admin-login h-full">
            <div className="columns h-full is-flex is-justify-content-center">
                <div className="column is-4 has-background-primary">
                    <div className="logo">
                        <img src="/images/logo.png" alt="shopsharepaid" />
                    </div>
                    <div className="w-full admin-graphic mx-auto pt-6">
                        <img src="/images/marketing-girl-graphic.svg" className="w-full" alt="shopsharepaid" />
                    </div>
                    <div className="admin-info py-6 has-text-centered">
                        <h2 className="has-text-white is-size-4 mx-auto mb-2">Turn your customers into affiliate</h2>
                        <h2 className="has-text-white is-size-6">Get started and instantly increase your sales.</h2>
                    </div>
                </div>
                <div className="column is-8 is-align-items-center">
                    <div className="is-flex login-box w-full is-align-items-center mx-auto h-full">
                        <div className="py-4 px-1 w-full">
                            <AuthLayout {...props} >
                                <div className="mb-5">
                                    <h1 className="is-size-4 has-text-weight-medium mb-0 has-text-primary">Affiliate Reset Password</h1>
                                    <p className="is-size-5m6">Enter your email below</p>
                                </div>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="field">
                                        <div className="control">
                                            <label className="label">Email</label>
                                            <input className="input" type="email" name="email" placeholder="Email Address" ref={register} />
                                        </div>
                                        <p className="help is-danger">{errors.email && 'Email is required'}</p>
                                    </div>
                                    <div className="has-text-left mb-2">
                                        <a className="has-text-primary m-0 p-0 is-text-link" onClick={() => history.push('/affiliate/login')}>Already have an account?</a>
                                    </div>
                                    <div className="pt-3 is-flex is-justify-content-flex-end is-clearfix">
                                        <button className="button is-primary with-shadow w-full max-w-160 mx-auto" type="submit"> Reset Password </button>
                                    </div>
                                </form>
                            </AuthLayout>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ForgotPassword;