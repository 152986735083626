// React
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

// Redux, Saga, Reducer 
import { createStore, applyMiddleware } from 'redux';
import { logger } from 'redux-logger';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducer';
import saga from "./saga";

// Global Loader
import { LoaderContext } from './loader';

// User Pages
import InstallApp from './pages/installation/install-app';
import InstallAppCallBack from './pages/installation/install-app-callback';
import Steps from './pages/steps';
import Dashboard from './pages/dashboard';
import Profile from './pages/profile';
import HowItWorks from './pages/how-it-works';
import UpgradePlan from './pages/upgrade-plan';
import Payments from './pages/payments';
import Payouts from './pages/payouts';
import MyAffiliates from './pages/my-affiliates';
import ManualAffiliates from './pages/manual-affiliates';
import SuperAffiliates from './pages/super-affiliates';
import ManualPopulateAffiliates from './pages/manual-populate-affiliates';
import PremiumPopulateAffiliates from './pages/premium-populate-affiliates';
import AffiliateSettings from './pages/affiliate-settings';
import CustomizeMessages from './pages/customize-messages';
import Logout from './pages/logout';

// Admin Pages
import AdminLogin from './admin/login';
import AdminDashboard from './admin/dashboard';
import AdminProfile from './admin/profile';
import AdminPlans from './admin/plans';
import AdminPayouts from './admin/payouts';
import AdminUsers from './admin/users';
import AdminAffiliates from './admin/affiliates';
import AdminExportData from './admin/export-data';
import AdminLogout from './admin/logout';

// Affiliate Pages
import AffiliateLogin from './affiliate/login';
import AffiliateRegister from './affiliate/register';
import AffiliateIframeRegister from './affiliate/iframe-register';
import AffiliateForgotPassword from './affiliate/forgot-password';
import AffiliateDashboard from './affiliate/dashboard';
import AffiliateProfile from './affiliate/profile';
import AffiliatePayments from './affiliate/payments';
import AffiliatePayoutsCash from './affiliate/payouts-cash';
import AffiliatePayoutsCredit from './affiliate/payouts-credit';
import AffiliatePayoutsCreditGrouped from './affiliate/payouts-credit-grouped';
import AffiliatePayoutsCashGrouped from './affiliate/payouts-cash-grouped';
import AffiliateCoupons from './affiliate/coupons';
import AffiliateVouchers from './affiliate/vouchers';
import AffiliateStores from './affiliate/stores';
import AffiliatePayoutsHistory from './affiliate/payouts-history';
import AffiliateHowToGetPaid from './affiliate/how-to-get-paid';
import AffiliateLogout from './affiliate/logout';

// Store, Reducer and Saga Integration
import { throttle } from 'lodash';
import { State, Storage } from './helpers';

const persistedState = State.Load();
const sagaMiddleware = createSagaMiddleware();
var store = "";
if (process.env.NODE_ENV === "development") {
  store = createStore(rootReducer, persistedState, applyMiddleware(sagaMiddleware, logger));
} else {
  store = createStore(rootReducer, persistedState, applyMiddleware(sagaMiddleware));
}

store.subscribe(throttle(() => {
  State.Save({
    "auth": store.getState().auth
  });
}), 1000);

sagaMiddleware.run(saga);

const UserProtectedRoute = ({ component: Component, user, ...rest }) => {
  return (
    <Route {...rest} render={props => Storage.Fetch('token') ? <Component {...props} /> : <Redirect to={{ pathname: "/" }} />} />
  )
}

const AffiliateProtectedRoute = ({ component: Component, user, ...rest }) => {
  return (
    <Route {...rest} render={props => Storage.Fetch('affiliate_token') ? <Component {...props} /> : <Redirect to={{ pathname: "/affiliate/login" }} />} />
  )
}

const AdminProtectedRoute = ({ component: Component, user, ...rest }) => {
  return (
    <Route {...rest} render={props => Storage.Fetch('admin_token') ? <Component {...props} /> : <Redirect to={{ pathname: "/admin/login" }} />} />
  )
}

const App = () => {
  const [loading, setLoading] = useState(false);

  return (
    <Provider store={store}>
      <LoaderContext.Provider value={{ loading, setLoading }}>
        <Router>
          <React.Fragment>
            <Switch>
              <Route path="/install-app" component={InstallApp} exact />
              <Route path="/" component={InstallAppCallBack} exact />
              <Route path="/onboarding" component={Steps} exact />

              <UserProtectedRoute path="/dashboard" component={Dashboard} exact />
              <UserProtectedRoute path="/profile" component={Profile} exact />
              <UserProtectedRoute path="/how-it-works" component={HowItWorks} exact />
              <UserProtectedRoute path="/upgrade-plan" component={UpgradePlan} exact />
              <UserProtectedRoute path="/my-affiliates" component={MyAffiliates} exact />
              <UserProtectedRoute path="/manual-affiliates" component={ManualAffiliates} exact />
              <UserProtectedRoute path="/super-affiliates" component={SuperAffiliates} exact />
              <UserProtectedRoute path="/manual-populate-affiliates" component={ManualPopulateAffiliates} exact />
              <UserProtectedRoute path="/premium-populate-affiliates" component={PremiumPopulateAffiliates} exact />
              <UserProtectedRoute path="/affiliate-settings" component={AffiliateSettings} exact />
              <UserProtectedRoute path="/customize-messages" component={CustomizeMessages} exact />
              <UserProtectedRoute path="/payments" component={Payments} exact />
              <UserProtectedRoute path="/payouts" component={Payouts} exact />
              <UserProtectedRoute path="/logout" component={Logout} exact />

              <Route path="/affiliate/login" component={AffiliateLogin} exact />
              <Route path="/affiliate/register" component={AffiliateRegister} exact />
              <Route path="/affiliate/iframe-register" component={AffiliateIframeRegister} exact />
              <Route path="/affiliate/forgot-password" component={AffiliateForgotPassword} exact />
              <AffiliateProtectedRoute path="/affiliate/dashboard" component={AffiliateDashboard} exact />
              <AffiliateProtectedRoute path="/affiliate/profile" component={AffiliateProfile} exact />
              <AffiliateProtectedRoute path="/affiliate/payments" component={AffiliatePayments} exact />
              <AffiliateProtectedRoute path="/affiliate/payouts-cash" component={AffiliatePayoutsCash} exact />
              <AffiliateProtectedRoute path="/affiliate/payouts-credit" component={AffiliatePayoutsCredit} exact />
              <AffiliateProtectedRoute path="/affiliate/payouts-credit-grouped" component={AffiliatePayoutsCreditGrouped} exact />
              <AffiliateProtectedRoute path="/affiliate/payouts-cash-grouped" component={AffiliatePayoutsCashGrouped} exact />
              <AffiliateProtectedRoute path="/affiliate/coupons" component={AffiliateCoupons} exact />
              <AffiliateProtectedRoute path="/affiliate/vouchers" component={AffiliateVouchers} exact />
              <AffiliateProtectedRoute path="/affiliate/stores" component={AffiliateStores} exact />
              <AffiliateProtectedRoute path="/affiliate/payouts-history" component={AffiliatePayoutsHistory} exact />
              <AffiliateProtectedRoute path="/affiliate/how-to-get-paid" component={AffiliateHowToGetPaid} exact />
              <AffiliateProtectedRoute path="/affiliate/logout" component={AffiliateLogout} exact />

              <Route path="/admin/login" component={AdminLogin} exact />
              <AdminProtectedRoute path="/admin/dashboard" component={AdminDashboard} exact />
              <AdminProtectedRoute path="/admin/profile" component={AdminProfile} exact />
              <AdminProtectedRoute path="/admin/plans" component={AdminPlans} exact />
              <AdminProtectedRoute path="/admin/payouts" component={AdminPayouts} exact />
              <AdminProtectedRoute path="/admin/users" component={AdminUsers} exact />
              <AdminProtectedRoute path="/admin/users/:id" component={AdminAffiliates} exact />
              <AdminProtectedRoute path="/admin/export-data" component={AdminExportData} exact />
              <AdminProtectedRoute path="/admin/logout" component={AdminLogout} exact />
            </Switch>
          </React.Fragment>
        </Router>
      </LoaderContext.Provider>
    </Provider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();