import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { isEmpty } from 'lodash';

import { Storage } from '../helpers';
import { LoaderContext } from '../loader';
import AuthLayout from '../layouts/auth-layout';

import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

function IframeRegister(props) {
    const dispatch = useDispatch();
    const { setLoading } = useContext(LoaderContext);
    const authProps = useSelector(state => state.auth);

    let location = new URLSearchParams(useLocation().search);
    let storename = location.get('shop');

    const schema = yup.object().shape({
        shop: yup.string().required(),
        firstname: yup.string().required(),
        lastname: yup.string().required(),
        email: yup.string().email().required(),
        password: yup.string().min(6).required()
    });

    const { register, handleSubmit, errors, reset } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (form) => {
        setLoading(true);
        dispatch({
            type: "IFRAME_AFFILIATE_REGISTER",
            payload: form
        });
        reset();
    }

    useEffect(() => {
        setLoading(false);
        if (!isEmpty(authProps) && authProps?.token && authProps?.affiliate) {
            setLoading(false);
            Storage.Add('affiliate_token', authProps.token);

            Swal.fire({
                icon: "success",
                title: "Congrats, you are now an Affiliate",
                html: "You will receive an email shortly with a link to your affiliate dashboard",
                timer: 10000,
                showConfirmButton: false
            });
        } else if (!isEmpty(authProps) && authProps.loading === false) {
            setLoading(false);
        }
    }, [authProps]);

    return (
        <section className="admin-login h-full iframe-register">
            <div className="columns w-full h-full">
                <div className="column is-12 is-align-items-center">
                    <div className="is-flex login-box w-full is-align-items-center mx-auto h-full">
                        <div className="py-4 px-1 w-full">
                            <AuthLayout {...props}>
                                <div className="mb-5">
                                    <h1 className="is-size-4 has-text-weight-medium mb-0 has-text-primary has-text-centered">Affiliate Registration</h1>
                                </div>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="field is-horizontal">
                                        <div className="field-body">
                                            <input type="hidden" name="shop" defaultValue={storename} ref={register} />
                                            <div className="field">
                                                <label className="label">First Name</label>
                                                <input type="text" className="input" name="firstname" placeholder="First Name" ref={register} />
                                                <p className="help is-danger">{errors.firstname && 'First Name is required'}</p>
                                            </div>
                                            <div className="field">
                                                <label className="label">Last Name</label>
                                                <input type="text" className="input" name="lastname" placeholder="Last Name" ref={register} />
                                                <p className="help is-danger">{errors.lastname && 'Last Name is required'}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Email Address</label>
                                        <input type="email" className="input" name="email" placeholder="Email Address" ref={register} />
                                        <p className="help is-danger">{errors.email && 'Email is required'}</p>
                                    </div>
                                    <div className="field">
                                        <label className="label">Password</label>
                                        <input type="password" className="input" name="password" placeholder="Password" ref={register} />
                                        <p className="help is-danger">{errors.password && 'Password is required'}</p>
                                    </div>
                                    <div className="is-flex is-justify-content-space-between mb-2">
                                        <div>
                                            <a className="has-text-primary m-0 p-0 is-text-link" href={`${process.env.REACT_APP_APP_ENDPOINT}/affiliate/login`} target="_blank" rel="noopener noreferrer">Already have an account?</a>
                                        </div>
                                        <div>
                                            <a className="has-text-primary m-0 p-0 is-text-link" href={`${process.env.REACT_APP_APP_ENDPOINT}/affiliate/forgot-password`} target="_blank" rel="noopener noreferrer">Forgot Password?</a>
                                        </div>
                                    </div>
                                    <div className="pt-3 is-flex is-justify-content-flex-end is-clearfix">
                                        <button className="button is-primary with-shadow w-full max-w-160 mx-auto" type="submit"> Register </button>
                                    </div>
                                </form>
                            </AuthLayout>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default IframeRegister;