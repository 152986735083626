import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import moment from "moment";

import AffiliateLayout from '../layouts/affiliate-layout';

function PayoutsHistory(props) {
    const dispatch = useDispatch();
    const authProps = useSelector(state => state.auth);
    const requestProps = useSelector(state => state.request);
    const [requests, setRequests] = useState({});

    useEffect(() => {
        dispatch({
            type: "AFFILIATE_FETCH_PAYOUT_REQUEST",
            payload: {}
        });
    }, []);

    useEffect(() => {
        if (!isEmpty(requestProps)) {
            setRequests(requestProps);
        }
    }, [requestProps]);

    return (
        <AffiliateLayout {...props}>
            <section className="pb-3">
                <div className="is-md-flex is-align-items-center is-justify-content-space-between">
                    <div>
                        <h1 className="title is-size-4 is-size-4-tablet mb-2 has-text-primary"> Payouts History </h1>
                    </div>
                </div>
            </section>

            <div className="columns is-multiline">
                <div className="column is-12">
                    <div className="box">
                        <div className="table-container">
                            <table className="table is-striped is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>Store</th>
                                        <th>Payout Amount</th>
                                        <th>Status</th>
                                        <th>Fee</th>
                                        <th>Created At</th>
                                        <th>Updated At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !isEmpty(requests) && Object.values(requests).map((request, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{request.company}</td>
                                                    <td className="has-text-weight-bold">{request.value} {request.currency}</td>
                                                    <td className="has-text-weight-bold">{request.fee} {request.currency}</td>
                                                    <td>
                                                        {request.status === "pending" ? <span className="tag is-warning is-light has-text-weight-semibold border border-solid border-color-warning">Pending</span> : ""}
                                                        {request.status === "complete" ? <span className="tag is-success is-light has-text-weight-semibold border border-solid border-color-success">Complete</span> : ""}
                                                    </td>
                                                    <td>{moment(request.created_at).format("D MMM, YYYY")}</td>
                                                    <td>{moment(request.updated_at).format("D MMM, YYYY")}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            {
                                isEmpty(requests) ? <p className="pb-5 has-text-centered is-fullwidth">No payouts history</p> : ""
                            }
                        </div>
                    </div>
                </div>
            </div>
        </AffiliateLayout>
    )
}

export default PayoutsHistory;