import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function Header() {
    const history = useHistory();
    const [videoModal, setVideoModal] = useState('');
    const authProps = useSelector(state => state.auth);

    function ToggleSidebar() {
        let sidebar = document.getElementsByClassName('sidebar')[0];
        if (sidebar.classList.contains('show')) {
            sidebar.classList.remove('show');
        } else {
            sidebar.classList.add('show');
        }
    }

    return (
        <div className="header py-2 px-4">
            <nav className="navbar" role="navigation" aria-label="main navigation">
                <div className="is-flex is-justify-content-space-between w-full is-align-items-center header-minimal">
                    <a role="button" className="navbar-burger burger ml-0" data-target="navMenu" aria-label="menu" aria-expanded="false" onClick={() => ToggleSidebar()}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </a>
                    <div className="navbar-brand">
                        <div className="navbar-item brand-text" onClick={() => history.push('/dashboard')}>
                            <img src="/images/logo.png" alt="" />
                        </div>
                    </div>
                    <div className="upgrade-plan-header is-flex-grow-1 is-justify-content-flex-end is-flex is-align-items-center">
                        <div className="navbar-item pr-0 is-flex is-align-items-center has-text-white">
                            <p className="p-4 is-size-6 mr-1 has-text-white tag has-background-primary"><b className="mr-1">Current Plan: </b> {authProps.plan_details === undefined ? "No Plan Selected" : authProps.plan_details.name}</p>
                            <button className="button is-danger" onClick={() => history.push('/upgrade-plan')}> Change Plans </button> </div>
                    </div>
                    <div className="dropdown is-right is-hoverable">
                        <div className="dropdown-trigger">
                            <button className="button is-primary" aria-haspopup="true" aria-controls="dropdown-menu">
                                <span className="user-name-character is-block has-text-white">{authProps?.firstname.charAt(0).toUpperCase()}</span>
                                <span className="icon">
                                    <i className="is-size-5 fa fa-angle-down" aria-hidden="true"></i>
                                </span>
                            </button>
                        </div>
                        <div className="dropdown-menu" id="dropdown-menu" role="menu">
                            <div className="dropdown-content">
                                <p className="pt-4 px-5 mb-4 has-text-weight-bold is-size-5m6">Hi, {authProps?.firstname} </p>
                                <a onClick={() => history.push('/profile')} className="is-clickable dropdown-item pr-3"><span className="icon-text"><span className="icon"><i className="fa fa-user"></i></span><span>Profile Settings</span></span></a>
                                <hr className="dropdown-divider" />
                                <a className="dropdown-item has-text-danger" onClick={() => history.push('/logout')}> <span className="icon-text"><span className="icon"><i className="fa fa-power-off"></i></span><span>Logout</span></span> </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="navMenu" className="navbar-menu">
                    <div className="upgrade-plan-header is-flex-grow-1 is-justify-content-flex-end is-flex is-align-items-center">
                        <div className="navbar-item pr-1 border is-flex w-full is-justify-content-space-between">
                            <span className="tag current-plan is-success is-size-6 is-light border-2 border-dashed border-color-success">
                                <b>Current Plan</b> : {authProps.plan_details === undefined ? "No Plan Selected" : authProps.plan_details.name}
                            </span>
                            {
                                authProps.stripe_card_details === undefined || authProps.stripe_card_details === "" ?
                                    <span className="tag current-plan is-danger is-size-5 is-light">
                                        <b>Attention</b> - Please update your payment settings to enable your affiliate program
                                    </span> : ""
                            }
                            <button className="button has-text-weight-medium is-danger with-shadow" onClick={() => history.push('/upgrade-plan')}> Change Plans </button>
                        </div>
                    </div>
                    <div className="navbar-end">
                        <div className="is-flex">
                            <div className="navbar-item">
                                <a className="button is-info with-shadow" onClick={() => setVideoModal('is-active')}>
                                    <span className="icon-text">
                                        <span> Training Videos </span>
                                    </span>
                                </a>
                            </div>
                            <div className="navbar-item has-dropdown is-hoverable">
                                <div className="navbar-link">
                                    <div className="loggedin-user is-flex">
                                        <span className="user-name-character is-block has-text-white">{authProps?.firstname.charAt(0).toUpperCase()}</span>
                                    </div>
                                </div>
                                <div className="navbar-dropdown is-right loggedin-user-dd">
                                    <p className="pt-4 px-5 mb-4 has-text-weight-bold is-size-5m6">Hi, {authProps?.firstname} </p>

                                    <a onClick={() => history.push('/profile')} className="is-clickable navbar-item pr-3"><span className="icon-text"><span className="icon"><i className="fa fa-user"></i></span><span>Profile Settings</span></span></a>
                                    <hr className="navbar-divider" />
                                    <a className="navbar-item pr-3 has-text-danger" onClick={() => history.push('/logout')}> <span className="icon-text"><span className="icon"><i className="fa fa-power-off"></i></span><span>Logout</span></span> </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>

            <div className={`modal ${videoModal}`}>
                <div className="modal-background"></div>
                <div className="modal-card is-large">
                    <div style={{ "padding": "100% 0 0 0", "position": "relative" }}>
                        <a className="delete is-large modal-close has-background-danger" aria-label="close" onClick={() => setVideoModal('')}>x</a>
                        <iframe src="https://player.vimeo.com/video/566644421?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{ "position": "absolute", "top": "0", "left": "0", "width": "100%", "height": "100%" }} title="QUICK SET UP - SHOP SHARE PAID TRAINING VIDEO SERIES.mp4"></iframe>
                    </div>
                    <script src="https://player.vimeo.com/api/player.js"></script>
                </div>
            </div>
        </div>
    )
}

export default Header;