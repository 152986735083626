import { Storage } from './helpers';
import Swal from "sweetalert2";

const rootReducer = (state = {}, action) => {
    if (action.type.includes('SUCCESS') && action.response?.message) {
        Swal.fire({
            icon: "success",
            title: action.response.message,
            timer: 3000,
            showConfirmButton: false
        });
    } else if (action.type.includes('ERROR') && action.response?.message) {
        if (action.response?.message === "getaddrinfo ENOTFOUND null" || action.response?.message === "getaddrinfo EAI_AGAIN null") {
            Swal.fire({
                icon: "error",
                title: "Please open the app through your shopify store."
            }).then((result) => {
                if (result.isConfirmed) {
                    if (Storage.Fetch('affiliate_token') !== null) {
                        window.location.href = process.env.REACT_APP_APP_ENDPOINT + '/affiliate/login';
                    } else if (Storage.Fetch('admin_token') !== null) {
                        window.location.href = process.env.REACT_APP_APP_ENDPOINT + '/admin/login';
                    } else if (Storage.Fetch('token') !== null) {
                        let currentState = JSON.parse(Storage.Fetch('state'));
                        window.location.href = `https://${currentState.auth.shop}/admin/apps`;
                    }
                    sessionStorage.clear();
                }
            });
        } else if (action.response?.message === "jwt expired") {
            Swal.fire({
                icon: "error",
                title: "Your login session has expired. Please login again to continue."
            }).then((result) => {
                if (result.isConfirmed) {
                    if (Storage.Fetch('affiliate_token') !== null) {
                        window.location.href = process.env.REACT_APP_APP_ENDPOINT + '/affiliate/login';
                    } else if (Storage.Fetch('admin_token') !== null) {
                        window.location.href = process.env.REACT_APP_APP_ENDPOINT + '/admin/login';
                    } else if (Storage.Fetch('token') !== null) {
                        let currentState = JSON.parse(Storage.Fetch('state'));
                        window.location.href = `https://${currentState.auth.shop}/admin/apps`;
                    }
                    sessionStorage.clear();
                }
            });
        } else {
            Swal.fire({
                icon: "error",
                title: action.response.message
            });
        }
    }

    switch (action.type) {
        case 'INIT_SUCCESS':
            return {
                auth: action.response.data
            };
        case 'INIT_ERROR':
            return Object.assign({}, {
                ...state,
                auth: {
                    loading: false
                }
            });
        case 'LOGIN_SUCCESS':
            return Object.assign({}, {
                ...state,
                auth: action.response.data.user,
                rates: action.response.data.rates
            });
        case 'LOGIN_ERROR':
            return Object.assign({}, {
                ...state,
                auth: {
                    loading: false
                }
            });
        case 'CHANGE_PASSWORD_SUCCESS':
            return Object.assign({}, {
                ...state,
                auth: action.response.data
            });
        case 'CHANGE_PASSWORD_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'UPDATE_PROFILE_SUCCESS':
            return Object.assign({}, {
                ...state,
                auth: action.response.data
            });
        case 'UPDATE_PROFILE_ERROR':
            return Object.assign({}, {
                ...state,
            });
        case 'UPDATE_LOGO_SUCCESS':
            return Object.assign({}, {
                ...state,
                auth: action.response.data
            });
        case 'UPDATE_LOGO_ERROR':
            return Object.assign({}, {
                ...state,
            });
        case 'CREATE_BANK_SUCCESS':
            return Object.assign({}, {
                ...state,
                auth: action.response.data
            });
        case 'CREATE_BANK_ERROR':
            return Object.assign({}, {
                ...state,
                auth: action.response.data
            });
        case 'REMOVE_BANK_SUCCESS':
            return Object.assign({}, {
                ...state,
                auth: action.response.data
            });
        case 'REMOVE_BANK_ERROR':
            return Object.assign({}, {
                ...state,
            });
        case 'CREATE_RECURRING_CHARGE_SUCCESS':
            return Object.assign({}, {
                ...state,
                charge: action.response.data
            });
        case 'CREATE_RECURRING_CHARGE_ERROR':
            return Object.assign({}, {
                ...state,
                charge: {
                    loading: false
                }
            });
        case 'SET_RECURRING_CHARGE_SUCCESS':
            return Object.assign({}, {
                ...state,
                auth: action.response.data
            });
        case 'SET_RECURRING_CHARGE_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'CANCEL_RECURRING_CHARGE_SUCCESS':
            return Object.assign({}, {
                ...state,
                auth: action.response.data
            });
        case 'CANCEL_RECURRING_CHARGE_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'ADMIN_LOGIN_SUCCESS':
            return Object.assign({}, {
                ...state,
                auth: action.response.data
            });
        case 'ADMIN_LOGIN_ERROR':
            return Object.assign({}, {
                ...state,
                auth: {
                    loading: false
                }
            });
        case 'ADMIN_CHANGE_PASSWORD_SUCCESS':
            return Object.assign({}, {
                ...state
            });
        case 'ADMIN_CHANGE_PASSWORD_ERROR':
            return Object.assign({}, {
                ...state,
                auth: {
                    loading: false
                }
            });
        case 'ADMIN_UPDATE_PROFILE_SUCCESS':
            return Object.assign({}, {
                ...state,
                auth: action.response.data
            });
        case 'ADMIN_UPDATE_PROFILE_ERROR':
            return Object.assign({}, {
                ...state,
                auth: {
                    loading: false
                }
            });
        case 'ADMIN_GET_CUSTOMER_SUCCESS':
            return Object.assign({}, {
                ...state,
                user: action.response.data
            });
        case 'ADMIN_GET_CUSTOMER_ERROR':
            return Object.assign({}, {
                ...state,
                user: {
                    loading: false
                }
            });
        case 'ADMIN_GET_CUSTOMERS_SUCCESS':
            return Object.assign({}, {
                ...state,
                users: action.response.data
            });
        case 'ADMIN_GET_CUSTOMERS_ERROR':
            return Object.assign({}, {
                ...state,
                users: {
                    loading: false
                }
            });
        case 'ADMIN_GET_PLANS_SUCCESS':
            return Object.assign({}, {
                ...state,
                plans: action.response.data
            });
        case 'ADMIN_GET_PLANS_ERROR':
            return Object.assign({}, {
                ...state,
                plans: {
                    loading: false
                }
            });
        case 'ADMIN_ADD_PLAN_SUCCESS':
            return Object.assign({}, {
                ...state,
                plans: action.response.data
            });
        case 'ADMIN_ADD_PLAN_ERROR':
            return Object.assign({}, {
                ...state,
                plans: {
                    loading: false
                }
            });
        case 'ADMIN_UPDATE_PLAN_SUCCESS':
            return Object.assign({}, {
                ...state,
                plans: action.response.data
            });
        case 'ADMIN_UPDATE_PLAN_ERROR':
            return Object.assign({}, {
                ...state,
                plans: {
                    loading: false
                }
            });
        case 'ADMIN_REMOVE_PLAN_SUCCESS':
            return Object.assign({}, {
                ...state
            });
        case 'ADMIN_REMOVE_PLAN_ERROR':
            return Object.assign({}, {
                ...state,
                plans: {
                    loading: false
                }
            });
        case 'DISCOUNT_SETTINGS_SUCCESS':
            return Object.assign({}, {
                ...state,
                auth: action.response.data
            });
        case 'DISCOUNT_SETTINGS_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'DISCOUNT_SETTINGS_TOGGLE_SUCCESS':
            return Object.assign({}, {
                ...state,
                auth: action.response.data
            });
        case 'DISCOUNT_SETTINGS_TOGGLE_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'UPDATE_SHARE_MESSAGES_SUCCESS':
            return Object.assign({}, {
                ...state,
                auth: action.response.data
            });
        case 'UPDATE_SHARE_MESSAGES_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'CREATE_CARD_SUCCESS':
            return Object.assign({}, {
                ...state,
                auth: action.response.data
            });
        case 'CREATE_CARD_ERROR':
            return Object.assign({}, {
                ...state,
            });
        case 'REMOVE_CARD_SUCCESS':
            return Object.assign({}, {
                ...state,
                auth: action.response.data
            });
        case 'REMOVE_CARD_ERROR':
            return Object.assign({}, {
                ...state,
            });
        case 'AFFILIATE_LOGIN_SUCCESS':
            return Object.assign({}, {
                ...state,
                auth: action.response.data
            });
        case 'AFFILIATE_LOGIN_ERROR':
            return Object.assign({}, {
                ...state,
                auth: {
                    loading: false
                }
            });
        case 'AFFILIATE_REGISTER_SUCCESS':
            return Object.assign({}, {
                ...state,
                auth: action.response.data
            });
        case 'AFFILIATE_REGISTER_ERROR':
            return Object.assign({}, {
                ...state,
                auth: action.response.data
            });
        case 'AFFILIATE_RESET_PASSWORD_SUCCESS':
            return Object.assign({}, {
                ...state,
            });
        case 'AFFILIATE_RESET_PASSWORD_ERROR':
            return Object.assign({}, {
                ...state,
            });
        case 'AFFILIATE_CHANGE_PASSWORD_SUCCESS':
            return Object.assign({}, {
                ...state
            });
        case 'AFFILIATE_CHANGE_PASSWORD_ERROR':
            return Object.assign({}, {
                ...state,
                auth: {
                    loading: false
                }
            });
        case 'AFFILIATE_UPDATE_PROFILE_SUCCESS':
            return Object.assign({}, {
                ...state,
                auth: action.response.data
            });
        case 'AFFILIATE_UPDATE_PROFILE_ERROR':
            return Object.assign({}, {
                ...state,
                auth: {
                    loading: false
                }
            });
        case 'FETCH_PAYOUTS_SUCCESS':
            return Object.assign({}, {
                ...state,
                payouts: action.response.data
            });
        case 'FETCH_PAYOUTS_ERROR':
            return Object.assign({}, {
                ...state,
            });
        case 'AFFILIATE_FETCH_PAYOUTS_SUCCESS':
            return Object.assign({}, {
                ...state,
                payouts: action.response.data
            });
        case 'AFFILIATE_FETCH_PAYOUTS_ERROR':
            return Object.assign({}, {
                ...state,
            });
        case 'AFFILIATE_FETCH_PAYOUTS_GROUPED_SUCCESS':
            return Object.assign({}, {
                ...state,
                grouped: action.response.data
            });
        case 'AFFILIATE_FETCH_PAYOUTS_GROUPED_ERROR':
            return Object.assign({}, {
                ...state,
            });
        case 'AFFILIATE_FETCH_COUPONS_SUCCESS':
            return Object.assign({}, {
                ...state,
                coupons: action.response.data
            });
        case 'AFFILIATE_FETCH_COUPONS_ERROR':
            return Object.assign({}, {
                ...state,
            });
        case 'ADMIN_FETCH_PAYOUTS_SUCCESS':
            return Object.assign({}, {
                ...state,
                payouts: action.response.data
            });
        case 'ADMIN_FETCH_PAYOUTS_ERROR':
            return Object.assign({}, {
                ...state,
            });
        case 'ADMIN_COMPLETE_PAYOUTS_SUCCESS':
            return Object.assign({}, {
                ...state,
                pdfs: action.response.data
            });
        case 'ADMIN_COMPLETE_PAYOUTS_ERROR':
            return Object.assign({}, {
                ...state,
            });
        case 'ADMIN_FETCH_USERS_SUCCESS':
            return Object.assign({}, {
                ...state,
                users: action.response.data
            });
        case 'ADMIN_FETCH_USERS_ERROR':
            return Object.assign({}, {
                ...state,
            });
        case 'ADMIN_FETCH_STORES_SUCCESS':
            return Object.assign({}, {
                ...state,
                stores: action.response.data
            });
        case 'ADMIN_FETCH_STORES_ERROR':
            return Object.assign({}, {
                ...state,
            });
        case 'ADMIN_FETCH_AFFILIATES_SUCCESS':
            return Object.assign({}, {
                ...state,
                affiliates: action.response.data
            });
        case 'ADMIN_FETCH_AFFILIATES_ERROR':
            return Object.assign({}, {
                ...state,
            });
        case 'DASHBOARD_DETAILS_SUCCESS':
            return Object.assign({}, {
                ...state,
                details: action.response.data
            });
        case 'DASHBOARD_DETAILS_ERROR':
            return Object.assign({}, {
                ...state,
            });
        case 'AFFILIATE_DASHBOARD_DETAILS_SUCCESS':
            return Object.assign({}, {
                ...state,
                details: action.response.data
            });
        case 'AFFILIATE_DASHBOARD_DETAILS_ERROR':
            return Object.assign({}, {
                ...state,
            });
        case 'ADMIN_DASHBOARD_DETAILS_SUCCESS':
            return Object.assign({}, {
                ...state,
                details: action.response.data
            });
        case 'ADMIN_DASHBOARD_DETAILS_ERROR':
            return Object.assign({}, {
                ...state,
            });
        case 'FETCH_AFFILIATES_SUCCESS':
            return Object.assign({}, {
                ...state,
                affiliates: action.response.data
            });
        case 'FETCH_AFFILIATES_ERROR':
            return Object.assign({}, {
                ...state,
            });
        case 'CREATE_AFFILIATE_SUCCESS':
            return Object.assign({}, {
                ...state,
            });
        case 'CREATE_AFFILIATE_ERROR':
            return Object.assign({}, {
                ...state,
            });
        case 'CREATE_AFFILIATE_QUEUE_SUCCESS':
            return Object.assign({}, {
                ...state,
            });
        case 'CREATE_AFFILIATE_QUEUE_ERROR':
            return Object.assign({}, {
                ...state,
            });
        case 'UPDATE_AFFILIATE_SUCCESS':
            return Object.assign({}, {
                ...state,
            });
        case 'UPDATE_AFFILIATE_ERROR':
            return Object.assign({}, {
                ...state,
            });
        case 'FETCH_CUSTOMERS_SUCCESS':
            return Object.assign({}, {
                ...state,
                customers: action.response.data
            });
        case 'FETCH_CUSTOMERS_ERROR':
            return Object.assign({}, {
                ...state,
            });
        case 'FETCH_CUSTOMERS_COUNT_SUCCESS':
            return Object.assign({}, {
                ...state,
                count: action.response.data
            });
        case 'FETCH_CUSTOMERS_COUNT_ERROR':
            return Object.assign({}, {
                ...state,
            });
        case 'REGENERATE_DISCOUNT_CODE_SUCCESS':
            return Object.assign({}, {
                ...state,
                count: action.response.data
            });
        case 'REGENERATE_DISCOUNT_CODE_ERROR':
            return Object.assign({}, {
                ...state,
            });
        case 'GET_USER_STEPS_SUCCESS':
            return Object.assign({}, {
                ...state,
                steps: action.response.data
            });
        case 'GET_USER_STEPS_ERROR':
            return Object.assign({}, {
                ...state,
            });
        case 'ADMIN_EXPORT_DATA_SUCCESS':
            return Object.assign({}, {
                ...state,
                file: action.response.data
            });
        case 'ADMIN_EXPORT_DATA_ERROR':
            return Object.assign({}, {
                ...state,
            });
        case 'EXPORT_CUSTOMERS_LIST_SUCCESS':
            return Object.assign({}, {
                ...state,
                file: action.response.data
            });
        case 'EXPORT_CUSTOMERS_LIST__ERROR':
            return Object.assign({}, {
                ...state,
            });
        case 'IMPORT_CUSTOMERS_LIST_SUCCESS':
            return Object.assign({}, {
                ...state,
                import: action.response.data
            });
        case 'IMPORT_CUSTOMERS_LIST_ERROR':
            return Object.assign({}, {
                ...state,
                import: action.response.data
            });
        case 'POPULATE_CUSTOMERS_LIST_SUCCESS':
            return Object.assign({}, {
                ...state
            });
        case 'POPULATE_CUSTOMERS_LIST_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'AFFILIATE_CREATE_VOUCHERS_SUCCESS':
            return Object.assign({}, {
                ...state,
                voucher: action.response.data
            });
        case 'AFFILIATE_CREATE_VOUCHERS_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'AFFILIATE_FETCH_VOUCHERS_SUCCESS':
            return Object.assign({}, {
                ...state,
                vouchers: action.response.data
            });
        case 'AFFILIATE_FETCH_VOUCHERS_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'AFFILIATE_CREATE_PAYOUT_REQUEST_SUCCESS':
            return Object.assign({}, {
                ...state,
                request: action.response.data
            });
        case 'AFFILIATE_CREATE_PAYOUT_REQUEST_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'AFFILIATE_FETCH_PAYOUT_REQUEST_SUCCESS':
            return Object.assign({}, {
                ...state,
                request: action.response.data
            });
        case 'AFFILIATE_FETCH_PAYOUT_REQUEST_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'EXPIRE_DISCOUNT_CODE_SUCCESS':
            return Object.assign({}, {
                ...state,
                coupon: action.response.data
            });
        case 'EXPIRE_DISCOUNT_CODE_ERROR':
            return Object.assign({}, {
                ...state,
                coupon: action.response.data
            });
        case 'REMOVE_DISCOUNT_CODE_SUCCESS':
            return Object.assign({}, {
                ...state,
                coupon: action.response.data
            });
        case 'REMOVE_DISCOUNT_CODE_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'RENAME_DISCOUNT_CODE_SUCCESS':
            return Object.assign({}, {
                ...state,
                coupon: action.response.data
            });
        case 'RENAME_DISCOUNT_CODE_ERROR':
            return Object.assign({}, {
                ...state,
                coupon: action.response.data
            });
        case 'IFRAME_AFFILIATE_REGISTER_SUCCESS':
            return Object.assign({}, {
                ...state,
                auth: action.response.data
            });
        case 'IFRAME_AFFILIATE_REGISTER_ERROR':
            return Object.assign({}, {
                ...state,
                auth: {
                    loading: false
                }
            });
        case 'AFFILIATE_PAGE_SETTINGS_SUCCESS':
            return Object.assign({}, {
                ...state,
                auth: action.response.data
            });
        case 'AFFILIATE_PAGE_SETTINGS_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'ADMIN_LIST_FAILED_TRANSACTIONS_SUCCESS':
            return Object.assign({}, {
                ...state,
                failed: action.response.data
            });
        case 'ADMIN_LIST_FAILED_TRANSACTIONS_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'LIST_FAILED_TRANSACTIONS_SUCCESS':
            return Object.assign({}, {
                ...state,
                failed: action.response.data
            });
        case 'LIST_FAILED_TRANSACTIONS_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'TRIGGER_FAILED_TRANSACTION_SUCCESS':
            return Object.assign({}, {
                ...state
            });
        case 'TRIGGER_FAILED_TRANSACTION_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'ADMIN_LIST_CANCELLED_TRANSACTIONS_SUCCESS':
            return Object.assign({}, {
                ...state,
                cancelled: action.response.data
            });
        case 'ADMIN_LIST_CANCELLED_TRANSACTIONS_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'ADMIN_MANAGE_CANCELLED_TRANSACTIONS_SUCCESS':
            return Object.assign({}, {
                ...state
            });
        case 'ADMIN_MANAGE_CANCELLED_TRANSACTIONS_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'AFFILIATE_FETCH_STORES_SUCCESS':
            return Object.assign({}, {
                ...state,
                stores: action.response.data
            });
        case 'AFFILIATE_FETCH_STORES_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'AFFILIATE_SEND_REQUEST_SUCCESS':
            return Object.assign({}, {
                ...state,
                stores: action.response.data
            });
        case 'AFFILIATE_SEND_REQUEST_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'FETCH_AFFILIATE_REQUESTS_SUCCESS':
            return Object.assign({}, {
                ...state,
                requests: action.response.data
            });
        case 'FETCH_AFFILIATE_REQUESTS_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'REJECT_AFFILIATE_REQUEST_SUCCESS':
            return Object.assign({}, {
                ...state,
                requests: action.response.data
            });
        case 'REJECT_AFFILIATE_REQUEST_ERROR':
            return Object.assign({}, {
                ...state
            });
        default:
            return state;
    }
}

export default rootReducer;