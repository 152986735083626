import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { LoaderContext } from '../loader';
import DashboardLayout from '../layouts/dashboard-layout';

function Affiliates(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const authProps = useSelector(state => state.auth);
    const { setLoading } = useContext(LoaderContext);

    const schema = yup.object().shape({
        allow_listing: yup.boolean().required(),
        affiliate_type: yup.string().required(),
        affiliate_value: yup.number().required(),
        affiliate_visibility: yup.boolean().required(),
        affiliate_payout: yup.string().required(),
        buyer_type: yup.string().required(),
        buyer_value: yup.number().required(),
        buyer_expiry: yup.number().required()
    });

    const { register, handleSubmit, errors, getValues } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (form) => {
        setLoading(true);

        dispatch({
            type: "DISCOUNT_SETTINGS",
            payload: form
        });
    }

    function handleChange() {
        var formValues = getValues();

        dispatch({
            type: "DISCOUNT_SETTINGS_TOGGLE",
            payload: {
                allow_listing: formValues.allow_listing,
                affiliate_visibility: formValues.affiliate_visibility,
                affiliate_payout: formValues.affiliate_payout[0]
            }
        });
    }

    function handleAffiliatePage() {
        var formValues = getValues();

        dispatch({
            type: "AFFILIATE_PAGE_SETTINGS",
            payload: {
                affiliate_page: formValues.affiliate_page
            }
        });
    }

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, [authProps]);

    function TogglePayments(e) {
        if (e.target.value === "bycash") {
            if (e.target.checked) {
                document.getElementsByName('affiliate_payout')[0].checked = false;
                document.getElementsByName('affiliate_payout')[1].checked = true;
            } else {
                document.getElementsByName('affiliate_payout')[0].checked = true;
                document.getElementsByName('affiliate_payout')[1].checked = false;
            }
        } else if (e.target.value === "bycredits") {
            if (e.target.checked) {
                document.getElementsByName('affiliate_payout')[1].checked = false;
                document.getElementsByName('affiliate_payout')[0].checked = true;
            } else {
                document.getElementsByName('affiliate_payout')[1].checked = true;
                document.getElementsByName('affiliate_payout')[0].checked = false;
            }
        }

        handleChange();
    }

    function NotAvailable() {
        Swal.fire({
            icon: "error",
            title: "Pay using in-store credits",
            html: "This feature is only available on the Pro and Enterprise Plan. Please upgrade your plan if you wish to use this feature."
        });
    }

    function NotAvailable2() {
        Swal.fire({
            icon: "error",
            title: "Add Affiliate page for sign up",
            html: "This is feature will be unlocked when you complete your profile eg. Payment Settings, Profile Settings"
        });
    }

    return (
        <DashboardLayout {...props} >
            <section className="pb-3">
                <div className="container is-max-widescreen">
                    <h1 className="title is-size-4 mb-2 has-text-primary"> Affiliate Settings </h1>
                </div>
            </section>

            <div className="columns is-multiline">
                <div className="column is-12">
                    <div className="container is-max-widescreen">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="box px-0 pt-0">
                                <div className="head  pt-5 px-5 mb-4">
                                    <p className="title is-size-5 has-text-primary">Commission/Affiliate Settings</p>
                                </div>
                                <div className="box-content px-5">
                                    <p className="mb-3">Here you can set the commission you want to pay your affiliates each time their affiliate link generates you a sale</p>
                                    <p className="mb-3 has-text-danger has-text-weight-bold"><strong>Please note:</strong> Due to a restriction from the payment gateway, a commission must be equal or great than 0.50 cents in the currency of your store. If it is less it will be rounded up to 0.50.</p>
                                    <p className="mb-3 has-text-info has-text-weight-bold has-text-centered" style={{ "textDecoration": "underline" }}>Incentivize your affiliates to share their discount link by offering them a greater commission!</p>
                                    <div className="field">
                                        <div className="field-body is-flex">
                                            <div className="field is-flex-basis-0">
                                                <label className="label">Type</label>
                                                <div className="select is-fullwidth">
                                                    <select name="affiliate_type" ref={register} defaultValue={authProps?.discount?.affiliate?.type}>
                                                        <option value=''>Choose Type</option>
                                                        <option value='fixed_amount'>Fixed Amount</option>
                                                        <option value='percentage'>Percentage</option>
                                                    </select>
                                                </div>
                                                <p className="help is-danger">{errors.affiliate_type && 'Type is required.'}</p>
                                            </div>
                                            <div className="field is-flex-basis-0">
                                                <label className="label">Value</label>
                                                <div className="control">
                                                    <input className="input" name="affiliate_value" type="number" placeholder="Enter value" ref={register} defaultValue={authProps?.discount?.affiliate?.value} />
                                                </div>
                                                <p className="help is-danger">{errors.affiliate_value && 'Value is required.'}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="head pt-5 px-5 mb-4">
                                    <p className="title is-size-5 has-text-primary">Discount Code Settings</p>
                                </div>
                                <div className="box-content px-5">
                                    <p className="mb-3">Here you can set the discount you want to offer to your affiliates customers. If you never want the discount code to expire, please enter 0 as the value.</p>
                                    <div className="field">
                                        <div className="field-body is-flex">
                                            <div className="field is-flex-basis-0">
                                                <label className="label">Type</label>
                                                <div className="select is-fullwidth">
                                                    <select ref={register} name="buyer_type" defaultValue={authProps?.discount?.buyer?.type}>
                                                        <option value=''>Choose Type</option>
                                                        <option value='fixed_amount'>Fixed Amount</option>
                                                        <option value='percentage'>Percentage</option>
                                                    </select>
                                                    <p className="help is-danger">{errors.buyer_type && 'Type is required.'}</p>
                                                </div>
                                            </div>
                                            <div className="field is-flex-basis-0">
                                                <label className="label">Value</label>
                                                <div className="control">
                                                    <input className="input" name="buyer_value" type="number" placeholder="Enter value" ref={register} defaultValue={authProps?.discount?.buyer?.value} />
                                                </div>
                                                <p className="help is-danger">{errors.buyer_value && 'Value is required.'}</p>
                                            </div>
                                            <div className="field is-flex-basis-0">
                                                <label className="label">Expiry Days</label>
                                                <div className="control">
                                                    <input className="input" name="buyer_expiry" type="number" placeholder="Enter value" ref={register} defaultValue={authProps?.discount?.buyer?.expiry} />
                                                </div>
                                                <p className="help is-danger">{errors.buyer_expiry && 'Expiry days are required.'}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="columns">
                                        <div className="column is-4">
                                        </div>
                                        <div className="column is-4">
                                            <div className="is-size-5m6 has-text-weight-bold has-text-danger mt-2 has-text-centered">
                                                (Don't forget to save your changes)
                                            </div>
                                        </div>
                                        <div className="column is-4">
                                            <div className="is-flex is-justify-content-flex-end">
                                                <button type="submit" className="button is-primary with-shadow">Save Changes</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="box px-0 pt-0">
                                <div className="head pt-5 px-5 mb-4">
                                    <div className="is-flex is-justify-content-space-between">
                                        <p className="title is-size-5 has-text-primary mb-0 pr-2">Turn off Affiliate program on thank you page</p>
                                        <div>
                                            <div className="control is-flex">
                                                <span className="mr-2 has-text-weight-bold is-size-5m6">Off</span>
                                                <label className="switch">
                                                    <input type="checkbox" name="affiliate_visibility" onChange={() => handleChange()} defaultChecked={authProps?.discount?.affiliate?.visibility === true ? true : false} ref={register} />
                                                    <span className="slider"></span>
                                                </label>
                                                <span className="ml-2 has-text-weight-bold is-size-5m6">On</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="box-content px-5">
                                    <p className="mb-4">By turning this off you will not offer your affiliate program to customers who complete a purchase on your store. This means you will have to manually create an affiliate to offer the program. We recommend leaving this turned on.</p>
                                </div>
                            </div>

                            <div className="box px-0 pt-0">
                                <div className="head pt-5 px-5 mb-4">
                                    <div className="is-flex is-justify-content-space-between">
                                        <p className="title is-size-5 has-text-primary mb-0 pr-2">Add an affiliate sign up page on your shopify store. <a href={`${process.env.REACT_APP_APP_ENDPOINT}/images/shop-share-paid-gif.gif`} target="_blank" rel="noopener norefferer" className="is-size-7 is-clickable"><u>Learn More</u></a></p>
                                        <div>
                                            <div className="control is-flex">
                                                <span className="mr-2 has-text-weight-bold is-size-5m6">
                                                    {
                                                        authProps?.stripe_card_details !== "" && authProps?.stripe_card_details !== undefined ? "" : <i className="fa fa-lock fa-lg mr-2 is-clickable" onClick={() => NotAvailable2()}></i>
                                                    }
                                                    Off
                                                </span>
                                                <label className="switch">
                                                    <input type="checkbox" name="affiliate_page" onChange={() => handleAffiliatePage()} defaultChecked={authProps?.affiliate_page === undefined || authProps?.affiliate_page === null ? false : true} ref={register} disabled={authProps?.stripe_card_details !== "" && authProps?.stripe_card_details !== undefined ? false : true} />
                                                    <span className="slider"></span>
                                                </label>
                                                <span className="ml-2 has-text-weight-bold is-size-5m6">On</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="box-content px-5">
                                    <p className="mb-4">By turning this off you will you will not be able to add your customers to the affiliate program without them making a purchase on your store.</p>
                                </div>
                            </div>

                            <div className="box px-0 pt-0">
                                <div className="head pt-5 px-5 mb-3">
                                    <div className="is-flex is-justify-content-space-between">
                                        <p className="title is-size-5 has-text-primary mb-0 pr-2">
                                            Pay your affiliates using in-store credits
                                            <span className="has-text-danger is-size-6 ml-2">(No Transaction Fee is charged when using this method)</span>
                                        </p>
                                        <div>
                                            <div className="control is-flex">
                                                <span className="mr-2 has-text-weight-bold is-size-5m6">
                                                    {
                                                        authProps?.plan_details?.name === "Starter" ? <i className="fa fa-lock fa-lg mr-2 is-clickable" onClick={() => NotAvailable()}></i> : ""
                                                    }
                                                    Off
                                                </span>
                                                <label className="switch">
                                                    <input type="checkbox" name="affiliate_payout" value="bycredits" onChange={(e) => { TogglePayments(e); handleChange(); }} defaultChecked={authProps?.discount?.affiliate?.payout === 'bycredits' ? true : false} ref={register} disabled={authProps?.plan_details?.name === "Starter" ? true : false} />
                                                    <span className="slider"></span>
                                                </label>
                                                <span className="ml-2 has-text-weight-bold is-size-5m6">On</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="head px-5 mb-4">
                                    <div className="is-flex is-justify-content-space-between">
                                        <p className="title is-size-5 has-text-primary mb-0 pr-2"> Pay your affiliates by cash </p>
                                        <div>
                                            <div className="control is-flex">
                                                <span className="mr-2 has-text-weight-bold is-size-5m6">Off</span>
                                                <label className="switch">
                                                    <input type="checkbox" name="affiliate_payout" value="bycash" defaultChecked={authProps?.discount?.affiliate?.payout === 'bycash' ? true : false} onChange={(e) => TogglePayments(e)} ref={register} disabled={authProps?.plan_details?.name === "Starter" ? true : false} />
                                                    <span className="slider"></span>
                                                </label>
                                                <span className="ml-2 has-text-weight-bold is-size-5m6">On</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="box-content px-5">
                                    <p className="mb-4">
                                        You can pay your affiliates using in store credit. When they redeem their credit we will create a discount code for the amount owing that only they can use as it will be locked to their email address and can only be used once. <br /> <br />
                                        Or you can pay your affiliates with cash and this is handled by us.
                                    </p>
                                </div>
                            </div>

                            <div className="box px-0 pt-0">
                                <div className="head pt-5 px-5 mb-4">
                                    <div className="is-flex is-justify-content-space-between">
                                        <p className="title is-size-5 has-text-primary mb-0 pr-2">Turn on Super Affiliates program</p>
                                        <div>
                                            <div className="control is-flex">
                                                <span className="mr-2 has-text-weight-bold is-size-5m6">Off</span>
                                                <label className="switch">
                                                    <input type="checkbox" name="allow_listing" onChange={() => handleChange()} defaultChecked={authProps?.discount?.allow_listing === true ? true : false} ref={register} />
                                                    <span className="slider"></span>
                                                </label>
                                                <span className="ml-2 has-text-weight-bold is-size-5m6">On</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="box-content px-5">
                                    <p className="mb-4">Allow affiliates from other stores on Shop Share Paid to become your affiliates.</p>
                                    <p className="mb-4">By turning this feature on, you will now have the advantage of gaining more affiliates from other stores. To enable this feature first upload your store logo by <a className="has-text-weight-bold" onClick={() => history.push('/profile')}>clicking here</a> or going to profile settings.</p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}

export default Affiliates;