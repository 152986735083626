import React, { useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { isEmpty } from "lodash";
import { Storage } from '../helpers';
import { LoaderContext } from '../loader';
import AuthLayout from '../layouts/auth-layout';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

function Login(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const { setLoading } = useContext(LoaderContext);
    const authProps = useSelector(state => state.auth);

    const schema = yup.object().shape({
        email: yup.string().email().required(),
        password: yup.string().min(6).required()
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema)
    });


    const onSubmit = (form) => {
        setLoading(true);
        dispatch({
            type: "ADMIN_LOGIN",
            payload: form
        });
    }

    useEffect(() => {
        if (!isEmpty(authProps) && authProps?.token && authProps?.administrator) {
            setLoading(false);
            Storage.Add('admin_token', authProps.token);
            history.push('/admin/dashboard');
        } else if (!isEmpty(authProps) && authProps.loading === false) {
            setLoading(false);
        }
    }, [authProps]);

    return (<section className="admin-login h-full">
        <div className="columns h-full is-flex is-justify-content-center">
            <div className="column is-4 has-background-primary">
                <div className="logo">
                    <img src="/images/logo.png" alt="shopsharepaid" />
                </div>
                <div className="w-full admin-graphic mx-auto py-6">
                    <img src="/images/marketing-girl-graphic.svg" className="w-full" alt="shopsharepaid" />
                </div>
                <div className="admin-info pb-6 has-text-centered">
                    <h2 className="has-text-white is-size-4 mx-auto mb-2">Turn your customers into affiliate</h2>
                    <h2 className="has-text-white is-size-6">Get started and instantly increase your sales.</h2>
                </div>
            </div>
            <div className="column is-8 is-align-items-center">
                <div className="is-flex login-box w-full is-align-items-center mx-auto h-full">
                    <div className="py-4 px-1 w-full">
                        <AuthLayout {...props} >
                            <div className="mb-5">
                                <h1 className="is-size-4 has-text-weight-medium mb-0 has-text-primary">Administrator Authentication</h1>
                                <p className="is-size-5m6">Enter your details below</p>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="field">
                                    <div className="control">
                                        <label className="label has-text-primary">Email</label>
                                        <input className="input" type="email" name="email" placeholder="Email Address" ref={register} />
                                    </div>
                                    <p className="help is-danger">{errors.email && 'Email is required'}</p>
                                </div>
                                <div className="field">
                                    <div className="control">
                                        <label className="label has-text-primary">Password</label>
                                        <input className="input" type="password" name="password" placeholder="Password" ref={register} />
                                    </div>
                                    <p className="help is-danger">{errors.password && 'Password should be minimum 6 characters'}</p>
                                </div>
                                <div className="pt-3 is-flex is-justify-content-flex-end">
                                    <button className="button is-primary with-shadow w-full max-w-160 mx-auto" type="submit"> Login </button>
                                </div>
                            </form>
                        </AuthLayout>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}

export default Login;