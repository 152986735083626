import { takeLatest, put, call } from "redux-saga/effects";
import axios from "axios";

import { Storage } from '../helpers';

//     ____      _ __ 
//    /  _/___  (_) /_
//    / // __ \/ / __/
//  _/ // / / / / /_  
// /___/_/ /_/_/\__/  
                   
export function* watchInit() {
    yield takeLatest("INIT", trackInit);
}

function* trackInit(sagaData) {
    try {
        var response = yield call(callInit, sagaData.payload);

        if (response) {
            yield put({
                type: "INIT_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "INIT_ERROR",
            response: err
        });
    }
}

function callInit(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/auth/init", saga).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     __                _     
//    / /   ____  ____ _(_)___ 
//   / /   / __ \/ __ `/ / __ \
//  / /___/ /_/ / /_/ / / / / /
// /_____/\____/\__, /_/_/ /_/ 
//             /____/          

export function* watchLogin() {
    yield takeLatest("LOGIN", trackLogin);
}

function* trackLogin(sagaData) {
    try {
        var response = yield call(callLogin, sagaData.payload);

        if (response) {
            yield put({
                type: "LOGIN_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "LOGIN_ERROR",
            response: err
        });
    }
}

function callLogin(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/auth/login", saga).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//    ________                              ____                                          __
//   / ____/ /_  ____ _____  ____ ____     / __ \____ ____________      ______  _________/ /
//  / /   / __ \/ __ `/ __ \/ __ `/ _ \   / /_/ / __ `/ ___/ ___/ | /| / / __ \/ ___/ __  / 
// / /___/ / / / /_/ / / / / /_/ /  __/  / ____/ /_/ (__  |__  )| |/ |/ / /_/ / /  / /_/ /  
// \____/_/ /_/\__,_/_/ /_/\__, /\___/  /_/    \__,_/____/____/ |__/|__/\____/_/   \__,_/   
//                        /____/                                                            

export function* watchChangePassword() {
    yield takeLatest("CHANGE_PASSWORD", trackChangePassword);
}

function* trackChangePassword(sagaData) {
    try {
        var response = yield call(callChangePassword, sagaData.payload);

        if (response) {
            yield put({
                type: "CHANGE_PASSWORD_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "CHANGE_PASSWORD_ERROR",
            response: err
        });
    }
}

function callChangePassword(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/auth/change-password", {
            currentPassword: saga.currentPassword,
            password: saga.password,
            confirmPassword: saga.confirmPassword
        }, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//    __  __          __      __          ____             _____ __   
//   / / / /___  ____/ /___ _/ /____     / __ \_________  / __(_) /__ 
//  / / / / __ \/ __  / __ `/ __/ _ \   / /_/ / ___/ __ \/ /_/ / / _ \
// / /_/ / /_/ / /_/ / /_/ / /_/  __/  / ____/ /  / /_/ / __/ / /  __/
// \____/ .___/\__,_/\__,_/\__/\___/  /_/   /_/   \____/_/ /_/_/\___/ 
//     /_/                                                            

export function* watchUpdateProfile() {
    yield takeLatest("UPDATE_PROFILE", trackUpdateProfile);
}

function* trackUpdateProfile(sagaData) {
    try {
        var response = yield call(callUpdateProfile, sagaData.payload);

        if (response) {
            yield put({
                type: "UPDATE_PROFILE_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "UPDATE_PROFILE_ERROR",
            response: err
        });
    }
}

function callUpdateProfile(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/auth/update-profile", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//    ______                __          ____              __  
//   / ____/_______  ____ _/ /____     / __ )____ _____  / /__
//  / /   / ___/ _ \/ __ `/ __/ _ \   / __  / __ `/ __ \/ //_/
// / /___/ /  /  __/ /_/ / /_/  __/  / /_/ / /_/ / / / / ,<   
// \____/_/   \___/\__,_/\__/\___/  /_____/\__,_/_/ /_/_/|_|  
                                                           
export function* watchCreateBank() {
    yield takeLatest("CREATE_BANK", trackCreateBank);
}

function* trackCreateBank(sagaData) {
    try {
        var response = yield call(callCreateBank, sagaData.payload);

        if (response) {
            yield put({
                type: "CREATE_BANK_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "CREATE_BANK_ERROR",
            response: err
        });
    }
}

function callCreateBank(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/payouts/create-bank", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ____                                    ____              __  
//    / __ \___  ____ ___  ____ _   _____     / __ )____ _____  / /__
//   / /_/ / _ \/ __ `__ \/ __ \ | / / _ \   / __  / __ `/ __ \/ //_/
//  / _, _/  __/ / / / / / /_/ / |/ /  __/  / /_/ / /_/ / / / / ,<   
// /_/ |_|\___/_/ /_/ /_/\____/|___/\___/  /_____/\__,_/_/ /_/_/|_|  
                                                                  
export function* watchRemoveBank() {
    yield takeLatest("REMOVE_BANK", trackRemoveBank);
}

function* trackRemoveBank(sagaData) {
    try {
        var response = yield call(callRemoveBank, sagaData.payload);

        if (response) {
            yield put({
                type: "REMOVE_BANK_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "REMOVE_BANK_ERROR",
            response: err
        });
    }
}

function callRemoveBank(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/payouts/remove-bank", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//    ______                __          ______               __
//   / ____/_______  ____ _/ /____     / ____/___ __________/ /
//  / /   / ___/ _ \/ __ `/ __/ _ \   / /   / __ `/ ___/ __  / 
// / /___/ /  /  __/ /_/ / /_/  __/  / /___/ /_/ / /  / /_/ /  
// \____/_/   \___/\__,_/\__/\___/   \____/\__,_/_/   \__,_/   
                                                            
export function* watchCreateCard() {
    yield takeLatest("CREATE_CARD", trackCreateCard);
}

function* trackCreateCard(sagaData) {
    try {
        var response = yield call(callCreateCard, sagaData.payload);

        if (response) {
            yield put({
                type: "CREATE_CARD_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "CREATE_CARD_ERROR",
            response: err
        });
    }
}

function callCreateCard(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/payment/create-card", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ____                                    ______               __
//    / __ \___  ____ ___  ____ _   _____     / ____/___ __________/ /
//   / /_/ / _ \/ __ `__ \/ __ \ | / / _ \   / /   / __ `/ ___/ __  / 
//  / _, _/  __/ / / / / / /_/ / |/ /  __/  / /___/ /_/ / /  / /_/ /  
// /_/ |_|\___/_/ /_/ /_/\____/|___/\___/   \____/\__,_/_/   \__,_/   
                                                                   
export function* watchRemoveCard() {
    yield takeLatest("REMOVE_CARD", trackRemoveCard);
}

function* trackRemoveCard(sagaData) {
    try {
        var response = yield call(callRemoveCard, sagaData.payload);

        if (response) {
            yield put({
                type: "REMOVE_CARD_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "REMOVE_CARD_ERROR",
            response: err
        });
    }
}

function callRemoveCard(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/payment/remove-card", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ____  _                             __     _____      __  __  _                 
//    / __ \(_)_____________  __  ______  / /_   / ___/___  / /_/ /_(_)___  ____ ______
//   / / / / / ___/ ___/ __ \/ / / / __ \/ __/   \__ \/ _ \/ __/ __/ / __ \/ __ `/ ___/
//  / /_/ / (__  ) /__/ /_/ / /_/ / / / / /_    ___/ /  __/ /_/ /_/ / / / / /_/ (__  ) 
// /_____/_/____/\___/\____/\__,_/_/ /_/\__/   /____/\___/\__/\__/_/_/ /_/\__, /____/  
//                                                                       /____/        

export function* watchDiscountSettings() {
    yield takeLatest("DISCOUNT_SETTINGS", trackDiscountSettings);
}

function* trackDiscountSettings(sagaData) {
    try {
        var response = yield call(callDiscountSettings, sagaData.payload);

        if (response) {
            yield put({
                type: "DISCOUNT_SETTINGS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "DISCOUNT_SETTINGS_ERROR",
            response: err
        });
    }
}

function callDiscountSettings(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/auth/discount-settings", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ____  _                             __     _____      __  __  _                     ______                  __   
//    / __ \(_)_____________  __  ______  / /_   / ___/___  / /_/ /_(_)___  ____ ______   /_  __/___  ____ _____ _/ /__ 
//   / / / / / ___/ ___/ __ \/ / / / __ \/ __/   \__ \/ _ \/ __/ __/ / __ \/ __ `/ ___/    / / / __ \/ __ `/ __ `/ / _ \
//  / /_/ / (__  ) /__/ /_/ / /_/ / / / / /_    ___/ /  __/ /_/ /_/ / / / / /_/ (__  )    / / / /_/ / /_/ / /_/ / /  __/
// /_____/_/____/\___/\____/\__,_/_/ /_/\__/   /____/\___/\__/\__/_/_/ /_/\__, /____/    /_/  \____/\__, /\__, /_/\___/ 
//                                                                       /____/                    /____//____/         

export function* watchDiscountSettingsToggle() {
    yield takeLatest("DISCOUNT_SETTINGS_TOGGLE", trackDiscountSettingsToggle);
}

function* trackDiscountSettingsToggle(sagaData) {
    try {
        var response = yield call(callDiscountSettingsToggle, sagaData.payload);

        if (response) {
            yield put({
                type: "DISCOUNT_SETTINGS_TOGGLE_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "DISCOUNT_SETTINGS_TOGGLE_ERROR",
            response: err
        });
    }
}

function callDiscountSettingsToggle(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/auth/discount-settings-toggle", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//    __  __          __      __          _____ __                       __  ___                                    
//   / / / /___  ____/ /___ _/ /____     / ___// /_  ____ _________     /  |/  /__  ______________ _____ ____  _____
//  / / / / __ \/ __  / __ `/ __/ _ \    \__ \/ __ \/ __ `/ ___/ _ \   / /|_/ / _ \/ ___/ ___/ __ `/ __ `/ _ \/ ___/
// / /_/ / /_/ / /_/ / /_/ / /_/  __/   ___/ / / / / /_/ / /  /  __/  / /  / /  __(__  |__  ) /_/ / /_/ /  __(__  ) 
// \____/ .___/\__,_/\__,_/\__/\___/   /____/_/ /_/\__,_/_/   \___/  /_/  /_/\___/____/____/\__,_/\__, /\___/____/  
//     /_/                                                                                       /____/             

export function* watchUpdateShareMessages() {
    yield takeLatest("UPDATE_SHARE_MESSAGES", trackUpdateShareMessages);
}

function* trackUpdateShareMessages(sagaData) {
    try {
        var response = yield call(callUpdateShareMessages, sagaData.payload);

        if (response) {
            yield put({
                type: "UPDATE_SHARE_MESSAGES_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "UPDATE_SHARE_MESSAGES_ERROR",
            response: err
        });
    }
}

function callUpdateShareMessages(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/auth/update-share-messages", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ___    _________ ___       __          ____                      _____      __  __  _                 
//    /   |  / __/ __(_) (_)___ _/ /____     / __ \____ _____ ____     / ___/___  / /_/ /_(_)___  ____ ______
//   / /| | / /_/ /_/ / / / __ `/ __/ _ \   / /_/ / __ `/ __ `/ _ \    \__ \/ _ \/ __/ __/ / __ \/ __ `/ ___/
//  / ___ |/ __/ __/ / / / /_/ / /_/  __/  / ____/ /_/ / /_/ /  __/   ___/ /  __/ /_/ /_/ / / / / /_/ (__  ) 
// /_/  |_/_/ /_/ /_/_/_/\__,_/\__/\___/  /_/    \__,_/\__, /\___/   /____/\___/\__/\__/_/_/ /_/\__, /____/  
//                                                    /____/                                   /____/        

export function* watchAffiliatePageSettings() {
    yield takeLatest("AFFILIATE_PAGE_SETTINGS", trackAffiliatePageSettings);
}

function* trackAffiliatePageSettings(sagaData) {
    try {
        var response = yield call(callAffiliatePageSettings, sagaData.payload);

        if (response) {
            yield put({
                type: "AFFILIATE_PAGE_SETTINGS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "AFFILIATE_PAGE_SETTINGS_ERROR",
            response: err
        });
    }
}

function callAffiliatePageSettings(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/auth/affiliate-page-settings", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ______     __       __       ____                          __      
//    / ____/__  / /______/ /_     / __ \____ ___  ______  __  __/ /______
//   / /_  / _ \/ __/ ___/ __ \   / /_/ / __ `/ / / / __ \/ / / / __/ ___/
//  / __/ /  __/ /_/ /__/ / / /  / ____/ /_/ / /_/ / /_/ / /_/ / /_(__  ) 
// /_/    \___/\__/\___/_/ /_/  /_/    \__,_/\__, /\____/\__,_/\__/____/  
//                                          /____/                        

export function* watchFetchPayouts() {
    yield takeLatest("FETCH_PAYOUTS", trackFetchPayouts);
}

function* trackFetchPayouts(sagaData) {
    try {
        var response = yield call(callFetchPayouts, sagaData.payload);

        if (response) {
            yield put({
                type: "FETCH_PAYOUTS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "FETCH_PAYOUTS_ERROR",
            response: err
        });
    }
}

function callFetchPayouts(saga) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + `/auth/fetch-payouts`, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ____             __    __                         __   ____       __        _ __    
//    / __ \____ ______/ /_  / /_  ____  ____ __________/ /  / __ \___  / /_____ _(_) /____
//   / / / / __ `/ ___/ __ \/ __ \/ __ \/ __ `/ ___/ __  /  / / / / _ \/ __/ __ `/ / / ___/
//  / /_/ / /_/ (__  ) / / / /_/ / /_/ / /_/ / /  / /_/ /  / /_/ /  __/ /_/ /_/ / / (__  ) 
// /_____/\__,_/____/_/ /_/_.___/\____/\__,_/_/   \__,_/  /_____/\___/\__/\__,_/_/_/____/  

export function* watchDashboardDetails() {
    yield takeLatest("DASHBOARD_DETAILS", trackDashboardDetails);
}

function* trackDashboardDetails(sagaData) {
    try {
        var response = yield call(callDashboardDetails, sagaData.payload);

        if (response) {
            yield put({
                type: "DASHBOARD_DETAILS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "DASHBOARD_DETAILS_ERROR",
            response: err
        });
    }
}

function callDashboardDetails(saga) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + `/auth/dashboard-details`, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ______     __       __       ___    _________ ___       __           
//    / ____/__  / /______/ /_     /   |  / __/ __(_) (_)___ _/ /____  _____
//   / /_  / _ \/ __/ ___/ __ \   / /| | / /_/ /_/ / / / __ `/ __/ _ \/ ___/
//  / __/ /  __/ /_/ /__/ / / /  / ___ |/ __/ __/ / / / /_/ / /_/  __(__  ) 
// /_/    \___/\__/\___/_/ /_/  /_/  |_/_/ /_/ /_/_/_/\__,_/\__/\___/____/  
                                                                         
export function* watchFetchAffiliates() {
    yield takeLatest("FETCH_AFFILIATES", trackFetchAffiliates);
}

function* trackFetchAffiliates(sagaData) {
    try {
        var response = yield call(callFetchAffiliates, sagaData.payload);

        if (response) {
            yield put({
                type: "FETCH_AFFILIATES_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "FETCH_AFFILIATES_ERROR",
            response: err
        });
    }
}

function callFetchAffiliates(saga) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/auth/fetch-affiliates", {
            params: saga,
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//    __  __                  _____ __                 
//   / / / /_______  _____   / ___// /____  ____  _____
//  / / / / ___/ _ \/ ___/   \__ \/ __/ _ \/ __ \/ ___/
// / /_/ (__  )  __/ /      ___/ / /_/  __/ /_/ (__  ) 
// \____/____/\___/_/      /____/\__/\___/ .___/____/  
//                                      /_/            

export function* watchGetUserSteps() {
    yield takeLatest("GET_USER_STEPS", trackGetUserSteps);
}

function* trackGetUserSteps(sagaData) {
    try {
        var response = yield call(callGetUserSteps, sagaData.payload);

        if (response) {
            yield put({
                type: "GET_USER_STEPS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "GET_USER_STEPS_ERROR",
            response: err
        });
    }
}

function callGetUserSteps(saga) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/auth/get-user-steps", {
            params: saga,
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     __    _      __     ______      _ __         __   ______                                 __  _                 
//    / /   (_)____/ /_   / ____/___ _(_) /__  ____/ /  /_  __/________ _____  _________ ______/ /_(_)___  ____  _____
//   / /   / / ___/ __/  / /_  / __ `/ / / _ \/ __  /    / / / ___/ __ `/ __ \/ ___/ __ `/ ___/ __/ / __ \/ __ \/ ___/
//  / /___/ (__  ) /_   / __/ / /_/ / / /  __/ /_/ /    / / / /  / /_/ / / / (__  ) /_/ / /__/ /_/ / /_/ / / / (__  ) 
// /_____/_/____/\__/  /_/    \__,_/_/_/\___/\__,_/    /_/ /_/   \__,_/_/ /_/____/\__,_/\___/\__/_/\____/_/ /_/____/  
                                                                                                                   
export function* watchListFailedTransactions() {
    yield takeLatest("LIST_FAILED_TRANSACTIONS", trackListFailedTransactions);
}

function* trackListFailedTransactions(sagaData) {
    try {
        var response = yield call(callListFailedTransactions, sagaData.payload);

        if (response) {
            yield put({
                type: "LIST_FAILED_TRANSACTIONS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "LIST_FAILED_TRANSACTIONS_ERROR",
            response: err
        });
    }
}

function callListFailedTransactions(saga) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/auth/list-failed-transactions", {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//   ______     _                          ____      _ __         __   __                                   __  _           
//  /_  __/____(_)___ _____ ____  _____   / __/___ _(_) /__  ____/ /  / /__________ _____  _________ ______/ /_(_)___  ____ 
//   / / / ___/ / __ `/ __ `/ _ \/ ___/  / /_/ __ `/ / / _ \/ __  /  / __/ ___/ __ `/ __ \/ ___/ __ `/ ___/ __/ / __ \/ __ \
//  / / / /  / / /_/ / /_/ /  __/ /     / __/ /_/ / / /  __/ /_/ /  / /_/ /  / /_/ / / / (__  ) /_/ / /__/ /_/ / /_/ / / / /
// /_/ /_/  /_/\__, /\__, /\___/_/     /_/  \__,_/_/_/\___/\__,_/   \__/_/   \__,_/_/ /_/____/\__,_/\___/\__/_/\____/_/ /_/ 
//            /____//____/                                                                                                  

export function* watchTriggerFailedTransaction() {
    yield takeLatest("TRIGGER_FAILED_TRANSACTION", trackTriggerFailedTransaction);
}

function* trackTriggerFailedTransaction(sagaData) {
    try {
        var response = yield call(callTriggerFailedTransaction, sagaData.payload);

        if (response) {
            yield put({
                type: "TRIGGER_FAILED_TRANSACTION_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "TRIGGER_FAILED_TRANSACTION_ERROR",
            response: err
        });
    }
}

function callTriggerFailedTransaction(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/auth/trigger-failed-transaction", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//    __  __          __      __          __                    
//   / / / /___  ____/ /___ _/ /____     / /   ____  ____ _____ 
//  / / / / __ \/ __  / __ `/ __/ _ \   / /   / __ \/ __ `/ __ \
// / /_/ / /_/ / /_/ / /_/ / /_/  __/  / /___/ /_/ / /_/ / /_/ /
// \____/ .___/\__,_/\__,_/\__/\___/  /_____/\____/\__, /\____/ 
//     /_/                                        /____/        

export function* watchUpdateLogo() {
    yield takeLatest("UPDATE_LOGO", trackUpdateLogo);
}

function* trackUpdateLogo(sagaData) {
    try {
        var response = yield call(callUpdateLogo, sagaData.payload);

        if (response) {
            yield put({
                type: "UPDATE_LOGO_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "UPDATE_LOGO_ERROR",
            response: err
        });
    }
}

function callUpdateLogo(saga) {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_ENDPOINT + "/auth/update-logo", {
            "method": 'POST',
            "headers": {
                "Authorization": "Bearer " + Storage.Fetch("token")
            },
            "body": saga
        }).then(response => {
            return response.json()
        }).then(data => {
            resolve(data);
        }).catch((err) => {
            reject(err);
        });
    });
}