import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import Swal from "sweetalert2";

import { LoaderContext } from '../loader';
import AffiliateLayout from '../layouts/affiliate-layout';

function PayoutsCashGrouped(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const authProps = useSelector(state => state.auth);
    const payoutsProps = useSelector(state => state.grouped);
    const voucherProps = useSelector(state => state.voucher);
    const [payouts, setPayouts] = useState({});
    const [payoutsNotReady, setPayoutsNotReady] = useState({});
    const [chargeModal, setChargeModal] = useState('');
    const { setLoading } = useContext(LoaderContext);

    useEffect(() => {
        dispatch({
            type: "AFFILIATE_FETCH_PAYOUTS_GROUPED",
            payload: {
                id: authProps._id,
                type: "bycash"
            }
        });
    }, [voucherProps]);

    useEffect(() => {
        setLoading(false);
        if(payoutsProps !== undefined) {
            setPayouts(payoutsProps.payoutsReady);
            setPayoutsNotReady(payoutsProps.payoutsNotReady);
        }
    }, [payoutsProps]);

    function PayoutRequest(shop) {
        if (authProps?.bank_details === undefined || authProps?.bank_details === "" || authProps?.bank_details === null) {
            Swal.fire({
                icon: "error",
                title: "Please add your bank details from the payment settings page in the sidebar"
            });
        } else if (authProps?.address === undefined || authProps?.address === "" || authProps?.address === null) {
            Swal.fire({
                icon: "error",
                title: "Please add your address details from the profile settings in the dashboard header"
            });
        } else {
            setLoading(true);
            dispatch({
                type: "AFFILIATE_CREATE_PAYOUT_REQUEST",
                payload: {
                    shop: shop
                }
            });

            setTimeout(() => {
                setLoading(false);
                history.push('/affiliate/payouts-cash');
            }, 3000);
        }
    }

    return (
        <AffiliateLayout {...props}>
            <section className="pb-3">
                <div className="is-md-flex is-align-items-center is-justify-content-space-between">
                    <div>
                        <h1 className="title is-size-4 is-size-4-tablet mb-2 has-text-primary"> Payouts (In-Cash)</h1>
                    </div>
                </div>
            </section>

            <div className="columns is-multiline">
                <div className="column is-12">
                    <div className="message is-danger mb-3">
                        <div className="message-body"> <b>Note : </b>Payouts can be requested after 30 days from when the sale was made. We do this to protect the merchant from fraudulent sales. If the customer cancels, returns or refunds their order your commission will be retracted. Transactions for a store may be grouped together to reduce the bank transfer fees.</div>
                    </div>
                    <div className="message is-info mb-3">
                        <div className="message-body"> <b>Note : </b>Minimum payout value should be greater than {authProps?.shop_details?.money_in_emails_format.replace("{{amount}}", 20)} and you will be charged a transfer fee by our payment process. <a className="is-ghost has-text-weight-bold" onClick={() => setChargeModal('is-active')}>See Fee & Charges</a>. You will be paid within 24-48 Hours after the request is made.</div>
                    </div>

                    <h2 className="is-size-5 has-text-primary has-text-weight-semibold">Ready to be Paid</h2>
                    <div className="box">
                        <div className="table-container">
                            <table className="table is-striped is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>Store</th>
                                        <th>Payout Amount (before fees & charges)</th>
                                        <th align="right">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !isEmpty(payouts) && Object.values(payouts).map((payout, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{payout?.company ? payout.company : payout.shop}</td>
                                                    <td className="has-text-weight-bold">{payout?.payout.toFixed(2)} {payout?.currency}</td>
                                                    <td align="right">
                                                        <button className="button is-primary with-shadow" onClick={() => PayoutRequest(payout.shop)}>Request Payout - <span className="has-text-weight-semibold ml-1">{payout?.payout.toFixed(2)} {payout?.currency}</span></button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            {
                                isEmpty(payouts) ? <p className="pb-5 has-text-centered is-fullwidth">No Transactions - They will show here after 30 days from the day of the sale</p> : ""
                            }
                        </div>
                    </div>

                    <h2 className="is-size-5 has-text-primary has-text-weight-semibold">Pending Payouts</h2>
                    <p className="is-size5m6 mb-2">Pending payments will remain in pending for 30 days from the day of purchase.</p>
                    <div className="box">
                        <div className="table-container">
                            <table className="table is-striped is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>Store</th>
                                        <th>Pending Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !isEmpty(payoutsNotReady) && Object.values(payoutsNotReady).map((payout, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{payout?.company ? payout.company : payout.shop}</td>
                                                    <td className="has-text-weight-bold">{payout?.payout.toFixed(2)} {payout?.currency}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            {
                                isEmpty(payoutsNotReady) ? <p className="pb-5 has-text-centered is-fullwidth">No Transactions - They will show here after 30 days from the day of the sale</p> : ""
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className={`modal ${chargeModal}`}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title is-size-5 has-text-weight-semibold is-fullwidth">Transfer Fees by Country <br />
                            <span class ="is-size-7 is-fullwidth has-text-weight-normal">Additional currency conversion rates may apply.</span>
                        </p>
                        <a className="delete" aria-label="close" onClick={() => setChargeModal('')}></a>
                    </header>
                    <section className="modal-card-body">
                        <div className="table-container">
                            <table className="table is-striped is-narrow is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>Country</th>
                                        <th>Currency</th>
                                        <th>Fee per transaction</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr><td className="py-2">Australia</td><td>AUD</td><td>5% + $2.50 AUD</td> </tr>
                                    <tr><td className="py-2">Canada</td><td>CAD</td><td>5% + $2.50 AUD</td> </tr>
                                    <tr><td className="py-2">Czech Republic</td><td>CZK</td><td>5% + $2.50 AUD</td> </tr>
                                    <tr><td className="py-2">Denmark</td><td>DKK</td><td>5% + $2.50 AUD</td> </tr>
                                    <tr><td className="py-2">Europe</td><td>EUR</td><td>5% + $2.50 AUD</td> </tr>
                                    <tr><td className="py-2">Hong Kong</td><td>HKD</td><td>5% + $2.50 AUD</td> </tr>
                                    <tr><td className="py-2">Hungary</td><td>HUF</td><td>5% + $2.50 AUD</td> </tr>
                                    <tr><td className="py-2">India</td><td>INR</td><td>5% + $2.50 AUD</td> </tr>
                                    <tr><td className="py-2">Indonesia</td><td>IDR</td><td>5% + $2.50 AUD</td> </tr>
                                    <tr><td className="py-2">Malaysia</td><td>MYR</td><td>5% + $2.50 AUD</td> </tr>
                                    <tr><td className="py-2">Norway</td><td>NOK</td><td>5% + $2.50 AUD</td> </tr>
                                    <tr><td className="py-2">Philippines</td><td>PHP</td><td>5% + $2.50 AUD</td> </tr>
                                    <tr><td className="py-2">Poland</td><td>PLN</td><td>5% + $2.50 AUD</td> </tr>
                                    <tr><td className="py-2">Singapore</td><td>SGD</td><td>5% + $2.50 AUD</td> </tr>
                                    <tr><td className="py-2">Sweden</td><td>SEK</td><td>5% + $2.50 AUD</td> </tr>
                                    <tr><td className="py-2">United Kingdom</td><td>GBP</td><td>5% + $2.50 AUD</td> </tr>
                                    <tr><td className="py-2">United States</td><td>USD</td><td>5% + $2.50 AUD</td> </tr>
                                    <tr><td className="py-2">Other Countries</td><td>N/A</td><td>5% + $12.00 AUD</td> </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                </div>
            </div>
        </AffiliateLayout>
    )
}

export default PayoutsCashGrouped;