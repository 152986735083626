import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { Storage } from "../helpers";
import AdminLayout from '../layouts/admin-layout';

function Profile(props) {
    const dispatch = useDispatch();
    const authProps = useSelector(state => state.auth);

    // User General Details Form
    const schema = yup.object().shape({
        firstname: yup.string().required(),
        lastname: yup.string().required(),
        email: yup.string().email().required()
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (form) => {
        dispatch({
            type: "ADMIN_UPDATE_PROFILE",
            payload: form
        });
    }

    // User Password Form
    const schema2 = yup.object().shape({
        currentPassword: yup.string().min(6).required(),
        password: yup.string().min(6).required(),
        confirmPassword: yup.string().min(6).required()
    });

    const { register: register2, handleSubmit: handleSubmit2, errors: errors2 } = useForm({
        resolver: yupResolver(schema2)
    });

    const onSubmit2 = (form) => {
        dispatch({
            type: "ADMIN_CHANGE_PASSWORD",
            payload: form
        });
    }

    useEffect(() => {
        if (!isEmpty(authProps) && authProps?.token) {
            Storage.Add('admin_token', authProps.token);
        }
    }, [authProps])

    return (
        <AdminLayout {...props} >
            <section className="pb-3">
                <div className="container is-max-widescreen">
                    <h1 className="title is-size-4 mb-2 has-text-primary"> Admin Profile </h1>
                </div>
            </section>
            <div className="container is-max-widescreen">
                <div className="columns">
                    <div className="column is-6">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="box px-0 pt-0">
                                <div className="head py-4 px-5">
                                    <div className="title is-size-5 has-text-primary">General Details</div>
                                </div>
                                <div className="box-content px-5">
                                    <div className="field">
                                        <label className="label">First Name</label>
                                        <input type="text" className="input" name="firstname" placeholder="First Name" defaultValue={authProps.firstname} ref={register} />
                                        <p className="help is-danger">{errors.firstname && 'First Name is required'}</p>
                                    </div>
                                    <div className="field">
                                        <label className="label">Last Name</label>
                                        <input type="text" className="input" name="lastname" placeholder="Last Name" defaultValue={authProps.lastname} ref={register} />
                                        <p className="help is-danger">{errors.lastname && 'Last Name is required'}</p>
                                    </div>
                                    <div className="field">
                                        <label className="label">Email Address</label>
                                        <input type="email" className="input" name="email" placeholder="Email Address" defaultValue={authProps.email} ref={register} />
                                        <p className="help is-danger">{errors.email && 'Email is required'}</p>
                                    </div>

                                </div>
                            </div>
                            <div className="field is-flex is-justify-content-flex-end">
                                <button type="submit" className="button is-primary px-5 with-shadow">Update Details</button>
                            </div>
                        </form>
                    </div>

                    <div className="column is-6">
                        <form onSubmit={handleSubmit2(onSubmit2)}>
                            <div className="box px-0 pt-0">
                                <div className="head py-4 px-5">
                                    <div className="title is-size-5 has-text-primary">Change Password</div>
                                </div>
                                <div className="box-content px-5">
                                    <div className="field">
                                        <label className="label">Current Password</label>
                                        <input type="password" className="input" name="currentPassword" placeholder="Current Password" ref={register2} />
                                        <p className="help is-danger">{errors2.currentPassword && 'Current Password is required'}</p>
                                    </div>
                                    <div className="field">
                                        <label className="label">New Password</label>
                                        <input type="password" className="input" name="password" placeholder="New Password" ref={register2} />
                                        <p className="help is-danger">{errors2.password && 'New Password is required'}</p>
                                    </div>
                                    <div className="field">
                                        <label className="label">Confirm Password</label>
                                        <input type="password" className="input" name="confirmPassword" placeholder="Confirm Password" ref={register2} />
                                        <p className="help is-danger">{errors2.confirmPassword && 'Confirm Password is required'}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="field is-flex is-justify-content-flex-end">
                                <button type="submit" className="button is-primary px-5 with-shadow">Change Password</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}

export default Profile;