import React, { useEffect, useContext, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import Swal from "sweetalert2";
import moment from "moment";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { Storage, StaticData } from "../helpers";
import { LoaderContext } from '../loader';
import DashboardLayout from '../layouts/dashboard-layout';

function Payments(props) {
    const dispatch = useDispatch();
    const authProps = useSelector(state => state.auth);
    const failedProps = useSelector(state => state.failed);
    const { setLoading } = useContext(LoaderContext);
    const [failed, setFailed] = useState({});

    useEffect(() => {
        dispatch({
            type: "LIST_FAILED_TRANSACTIONS",
            payload: {}
        });
    }, []);

    useEffect(() => {
        setFailed(failedProps);
    }, [failedProps]);

    const schema1 = yup.object().shape({
        number: yup.string().max(16).required(),
        exp_month: yup.string().max(2).required(),
        exp_year: yup.string().max(4).required(),
        cvc: yup.string().min(3).max(4).required()
    });

    const { register, handleSubmit, errors, reset } = useForm({
        resolver: yupResolver(schema1)
    });

    const onSubmit = (form) => {
        setLoading(true);
        dispatch({
            type: "CREATE_CARD",
            payload: form
        });

        setTimeout(() => {
            setLoading(false);
        }, 1000);

        reset();
    }

    useEffect(() => {
        if (!isEmpty(authProps) && authProps?.token) {
            Storage.Add('token', authProps.token);
        }
    }, [authProps]);

    function RemoveCard() {
        Swal.fire({
            title: "Are you sure you want to remove the card?",
            html: "Removing the card will disable your entire affiliate program i.e Thank you page, Affiliate sign up page",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: "No"
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch({
                    type: "REMOVE_CARD",
                    payload: {}
                });
            }
        });
    }

    function TriggerTransaction(id) {
        Swal.fire({
            title: "Are you sure you want to process the transaction again?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: "No"
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                dispatch({
                    type: "TRIGGER_FAILED_TRANSACTION",
                    payload: {
                        id: id
                    }
                });

                setTimeout(() => {
                    dispatch({
                        type: "LIST_FAILED_TRANSACTIONS",
                        payload: {}
                    });

                    setLoading(false);
                }, 5000);
            }
        });
    }

    return (
        <DashboardLayout {...props} >
            <section>
                <div className="container is-max-widescreen">
                    <h1 className="title is-size-4 mb-2 has-text-primary"> Payment Settings </h1>
                </div>
            </section>

            {
                authProps?.stripe_card_details === undefined || authProps?.stripe_card_details === "" ?
                    <div className="column is-12">
                        <div className="container is-max-widescreen">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="box px-0 pt-0">
                                    <div className="head py-4 px-5">
                                        <p className="title is-size-5 has-text-primary">Card Details</p>
                                    </div>

                                    <div className="box-content px-5">
                                        <p className="mb-3">You are charged only when a customer purchases a product using the Shop Share Paid generated affiliate code. We charge your credit card immediately after the purchase shows paid. This charge includes the commission you agree to pay your affiliate for every sale along with the Shop Share Paid commission fee listed on your plan. If the payment is pending, you will not be charged until the payment status shows paid.</p>
                                        <input type="hidden" className="input" name="id" defaultValue={authProps?._id} ref={register} />
                                        <div className="field">
                                            <label className="label">Card Number</label>
                                            <input type="text" className="input" name="number" ref={register} maxLength="16" />
                                            <p className="help is-danger">{errors.number && "Card Number is required"}</p>
                                        </div>
                                        <div className="field">
                                            <label className="label">Card Month</label>
                                            <div className="select is-fullwidth">
                                                <select className="select" name="exp_month" ref={register}>
                                                    <option value="">Select Month</option>
                                                    {
                                                        StaticData.ListMonths().map((month, index) => {
                                                            return (
                                                                <option value={month.value} key={index}>{month.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <p className="help is-danger">{errors.exp_month && "Card Month is required"}</p>
                                        </div>
                                        <div className="field">
                                            <label className="label">Card Year</label>
                                            <div className="select is-fullwidth">
                                                <select className="select" name="exp_year" ref={register}>
                                                    <option value="">Select Year</option>
                                                    {
                                                        StaticData.ListYears().map((year, index) => {
                                                            return (
                                                                <option value={year} key={index}>{year}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <p className="help is-danger">{errors.exp_year && "Card Year is required"}</p>
                                        </div>
                                        <div className="field">
                                            <label className="label">CVC</label>
                                            <input type="text" className="input" name="cvc" ref={register} maxLength="4" />
                                            <p className="help is-danger">{errors.cvc && "Card CVC is required"}</p>
                                        </div>
                                    </div>

                                </div>
                                <div className="field mt-5 mb-2 is-flex is-justify-content-flex-end">
                                    <button type="submit" className="button is-primary px-5">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div> : ""
            }

            {
                !isEmpty(authProps?.stripe_card_details) ?
                    <div className="column is-12">
                        <div className="container is-max-widescreen">
                            <div className="box">
                                <div className="box-content">
                                    <div className="title is-size-5 mb-4 has-text-primary">Saved Card Details</div>
                                    <div className="table-container w-full">
                                        <table className="table is-striped is-fullwidth">
                                            <thead>
                                                <tr>
                                                    <th width="25%">Card Type</th>
                                                    <th width="30%">Expiration</th>
                                                    <th width="30%">Last 4 Digits</th>
                                                    <th width="15%">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        {authProps?.stripe_card_details.brand.toLowerCase() === 'visa' ? <div><i className="fa fa-cc-visa is-size-1 has-text-primary"></i></div> : ""}
                                                        {authProps?.stripe_card_details.brand.toLowerCase() === 'mastercard' ? <div><i className="fa fa-cc-mastercard is-size-1 has-text-primary"></i></div> : ""}
                                                        {authProps?.stripe_card_details.brand.toLowerCase() === 'discover' ? <div><i className="fa fa-cc-discover is-size-1 has-text-primary"></i></div> : ""}
                                                        {authProps?.stripe_card_details.brand.toLowerCase() === 'amex' ? <div><i className="fa fa-cc-amex is-size-1 has-text-primary"></i></div> : ""}
                                                    </td>
                                                    <td>{authProps?.stripe_card_details.exp_month + "/" + authProps?.stripe_card_details.exp_year}</td>
                                                    <td>************{authProps?.stripe_card_details.last4}</td>
                                                    <td>
                                                        <button className="button is-danger is-small" onClick={() => RemoveCard()}>Remove Card</button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div> : ""
            }

            {
                !isEmpty(failed) ? <div className="container is-max-widescreen mt-5">
                    <section className="pb-3">
                        <div className="is-md-flex is-align-items-center is-justify-content-space-between">
                            <h1 className="title is-size-4 is-size-4-tablet mb-2 has-text-primary"> Unsuccessful Transaction </h1>
                        </div>
                    </section>

                    <div className="columns is-multiline">
                        <div className="column is-12">
                            <div className="message is-danger mb-3 mt-1">
                                <div className="message-body">Your affiliate cannot be paid their commission as your credit card was declined. We have placed your account on hold until your payment information is updated and can be processed. Once you update your credit card, select process pending affiliate payments. As soon as the charge is successful your account will be active again. </div>
                            </div>
                            <div className="box">
                                <div className="table-container">
                                    <table className="table is-striped is-fullwidth">
                                        <thead>
                                            <tr>
                                                <th>Order Number</th>
                                                <th>Affilaite Name</th>
                                                <th>Affilaite Email</th>
                                                <th>Error Reason</th>
                                                <th>Resolution</th>
                                                <th>Created At</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                !isEmpty(failed) && Object.values(failed).map((fail, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td><a href={`https://${authProps?.shop}/admin/orders/${fail.transaction.order.id}`} target="_blank" rel="noopener norefferer">{fail.charge.description}</a></td>
                                                            <td>{fail.transaction.affiliate.firstname} {fail.transaction.affiliate.lastname}</td>
                                                            <td>{fail.transaction.affiliate.email}</td>
                                                            <td>Card declined from Bank</td>
                                                            <td>Update Card/Contact Bank</td>
                                                            <td>{moment(fail.created_at).format('D MMM, YYYY')}</td>
                                                            <td><button className="button is-primary is-small" onClick={() => TriggerTransaction(fail._id)}>Process Transaction</button></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : ""
            }
        </DashboardLayout >
    )
}

export default Payments;