import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import Swal from "sweetalert2";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { Storage, StaticData } from "../helpers";
import { LoaderContext } from '../loader';
import DashboardLayout from '../layouts/dashboard-layout';

function Profile(props) {
    const dispatch = useDispatch();
    const authProps = useSelector(state => state.auth);
    const { setLoading } = useContext(LoaderContext);

    const schema = yup.object().shape({
        firstname: yup.string().required(),
        lastname: yup.string().required(),
        email: yup.string().email().required(),
        company: yup.string().required(),
        address: yup.string().required(),
        city: yup.string().required(),
        state: yup.string().required(),
        country: yup.string().required(),
        postal_code: yup.string().required()
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (form) => {
        setLoading(true);
        dispatch({
            type: "UPDATE_PROFILE",
            payload: form
        });
    }

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);

        if (!isEmpty(authProps) && authProps?.token) {
            Storage.Add('token', authProps.token);
        }
    }, [authProps]);

    function UpdateLogo(e) {
        setLoading(true);
        var allFiles = Array.from(e.target.files);

        const formData = new FormData();
        for (let i = 0; i < allFiles.length; i++) {
            formData.append('data', allFiles[i], allFiles[i].name);
            formData.append('type', e.target.name);
        }

        dispatch({
            type: "UPDATE_LOGO",
            payload: formData
        });
    }

    return (
        <DashboardLayout {...props} >
            <section className="pb-3">
                <div className="container is-max-widescreen">
                    <h1 className="title is-size-4 mb-2 has-text-primary"> Profile </h1>
                </div>
            </section>

            <div className="columns is-multiline">
                <div className="column is-12">
                    <div className="container is-max-widescreen">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="box px-0 pt-0">
                                <div className="head py-4 px-5">
                                    <p className="title is-size-5 has-text-primary">User Information</p>
                                </div>
                                <div className="box-content px-5">
                                    <div className="field">
                                        <div className="field-body">
                                            <div className="mr-5">
                                                <figure class="is-square">
                                                    {
                                                        authProps?.logo ?
                                                            <img style={{ width: "256px", height: "auto" }} src={`${process.env.REACT_APP_API_ENDPOINT}/images/${authProps?.logo}`} /> :
                                                            <img src="https://bulma.io/images/placeholders/256x256.png" />
                                                    }
                                                    <input class="file-input is-clickable" type="file" name="logo" onChange={(e) => UpdateLogo(e)} />
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <div className="field-body">
                                            <div className="field">
                                                <label className="label">First Name</label>
                                                <input type="text" className="input" name="firstname" placeholder="First Name" defaultValue={authProps?.firstname} ref={register} />
                                                <p className="help is-danger">{errors.firstname && 'First Name is required'}</p>
                                            </div>
                                            <div className="field">
                                                <label className="label">Last Name</label>
                                                <input type="text" className="input" name="lastname" placeholder="Last Name" defaultValue={authProps?.lastname} ref={register} />
                                                <p className="help is-danger">{errors.lastname && 'Last Name is required'}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <div className="field-body">
                                            <div className="field">
                                                <label className="label">Email Address</label>
                                                <input type="email" className="input" name="email" placeholder="Email Address" defaultValue={authProps?.email} ref={register} />
                                                <p className="help is-danger">{errors.email && 'Email is required'}</p>
                                            </div>
                                            <div className="field">
                                                <label className="label">Company</label>
                                                <input type="text" className="input" name="company" placeholder="Company" defaultValue={authProps?.company} ref={register} />
                                                <p className="help is-danger">{errors.company && 'Company name is required'}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <div className="field-body">
                                            <div className="field">
                                                <label className="label">City</label>
                                                <input type="text" className="input" name="city" defaultValue={authProps?.city} ref={register} />
                                                <p className="help is-danger">{errors.city && 'City is required'}</p>
                                            </div>
                                            <div className="field">
                                                <label className="label">Postal Code</label>
                                                <input type="text" className="input" name="postal_code" defaultValue={authProps?.postal_code} ref={register} />
                                                <p className="help is-danger">{errors.postal_code && 'Postal Code is required'}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <div className="field-body">
                                            <div className="field is-flex-basis-0">
                                                <label className="label">State</label>
                                                <input type="text" className="input" name="state" defaultValue={authProps?.state} ref={register} />
                                                <p className="help is-danger">{errors.state && 'State is required'}</p>
                                            </div>
                                            <div className="field is-flex-basis-0">
                                                <label className="label">Country</label>
                                                <div className="select is-fullwidth">
                                                    <select name="country" ref={register} defaultValue={authProps?.country}>
                                                        <option value="">Select Country</option>
                                                        {
                                                            StaticData.ListCountries().map((country, index) => {
                                                                return (
                                                                    <option value={country.sortname} key={index}>{country.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <p className="help is-danger">{errors.country && 'Country is required'}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Address</label>
                                        <input type="text" className="input" name="address" defaultValue={authProps?.address} ref={register} />
                                        <p className="help is-danger">{errors.address && 'Address is required'}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="field mt-5 mb-2 is-flex is-justify-content-flex-end">
                                <button type="submit" className="button is-primary with-shadow px-5">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}

export default Profile;