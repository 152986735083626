import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import DashboardLayout from '../layouts/dashboard-layout';

function Affiliates(props) {
    const dispatch = useDispatch();
    const authProps = useSelector(state => state.auth);
    const [firstTitle, setFirstTitle] = useState();
    const [firstMessage, setFirstMessage] = useState();
    const [repeatTitle, setRepeatTitle] = useState();
    const [repeatMessage, setRepeatMessage] = useState();
    const [shareMessage, setShareMessage] = useState();
    const [imageModal, setImageModal] = useState('');
    const [modalImage, setModalImage] = useState('');

    const defaultMessages = {
        messages: {
            first: {
                title: "Did you know you can make money promoting our products?",
                message: "Activate your discount code and receive {discount} commission every time someone uses your code to purchase. All you need to do is activate your discount code by using the link below and tell us where you want to be paid your commission."
            },
            repeat: {
                title: "Welcome Back!",
                message: "We have generated a new affiliate discount code {discount_code} for you. Share it and receive {discount} commission every time someone uses your code to purchase."
            },
            share_message: "If anyone is interested to shop at {company}, why pay full price! Instead, use my discount code {discount_code} to get {discount} off at final checkout."
        }
    }

    useEffect(() => {
        ReplaceMessage('first_title', authProps?.messages?.first?.title);
        ReplaceMessage('first_message', authProps?.messages?.first?.message);
        ReplaceMessage('repeat_title', authProps?.messages?.repeat?.title);
        ReplaceMessage('repeat_message', authProps?.messages?.repeat?.message);
        ReplaceMessage('share_message', authProps?.messages?.share_message);
    }, [authProps]);

    const schema = yup.object().shape({
        first_title: yup.string().required(),
        first_message: yup.string().required(),
        repeat_title: yup.string().required(),
        repeat_message: yup.string().required(),
        share_message: yup.string().required()
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (form) => {
        dispatch({
            type: "UPDATE_SHARE_MESSAGES",
            payload: form
        });
    }

    function ResetToDefault(method) {
        if (method === 'first') {
            ReplaceMessage('first_title', defaultMessages.messages.first.title);
            ReplaceMessage('first_message', defaultMessages.messages.first.message);
            document.getElementsByName('first_title')[0].value = defaultMessages.messages.first.title;
            document.getElementsByName('first_message')[0].value = defaultMessages.messages.first.message;
        } else if (method === 'repeat') {
            ReplaceMessage('repeat_title', defaultMessages.messages.repeat.title);
            ReplaceMessage('repeat_message', defaultMessages.messages.repeat.message);
            document.getElementsByName('repeat_title')[0].value = defaultMessages.messages.repeat.title;
            document.getElementsByName('repeat_message')[0].value = defaultMessages.messages.repeat.message;
        } else if (method === 'share') {
            ReplaceMessage('share_message', defaultMessages.messages.share_message);
            document.getElementsByName('share_message')[0].value = defaultMessages.messages.share_message;
        }
    }

    function ResetToSaved(method) {
        if (method === 'first') {
            ReplaceMessage('first_title', authProps.messages.first.title);
            ReplaceMessage('first_message', authProps.messages.first.message);
            document.getElementsByName('first_title')[0].value = authProps.messages.first.title;
            document.getElementsByName('first_message')[0].value = authProps.messages.first.message;
        } else if (method === 'repeat') {
            ReplaceMessage('repeat_title', authProps.messages.repeat.title);
            ReplaceMessage('repeat_message', authProps.messages.repeat.message);
            document.getElementsByName('repeat_title')[0].value = authProps.messages.repeat.title;
            document.getElementsByName('repeat_message')[0].value = authProps.messages.repeat.message;
        } else if (method === 'share') {
            ReplaceMessage('share_message', authProps.messages.share_message);
            document.getElementsByName('share_message')[0].value = authProps.messages.share_message;
        }
    }

    function previewUpdate(e) {
        if (e.target.name === "first_title") {
            ReplaceMessage('first_title', e.target.value);
        } else if (e.target.name === "first_message") {
            ReplaceMessage('first_message', e.target.value);
        } else if (e.target.name === "repeat_title") {
            ReplaceMessage('repeat_title', e.target.value);
        } else if (e.target.name === "repeat_message") {
            ReplaceMessage('repeat_message', e.target.value);
        } else if (e.target.name === "share_message") {
            ReplaceMessage('share_message', e.target.value);
        }
    }

    function AddToMessage(method, text) {
        let snippet;
        if (method === 'first') {
            snippet = document.getElementsByName('first_message')[0].value;
            snippet = `${snippet} ${text}`;
            document.getElementsByName('first_message')[0].value = snippet;
            ReplaceMessage('first_message', snippet);
        } else if (method === 'repeat') {
            snippet = document.getElementsByName('repeat_message')[0].value;
            snippet = `${snippet} ${text}`;
            document.getElementsByName('repeat_message')[0].value = snippet;
            ReplaceMessage('repeat_message', snippet);
        } else if (method === 'share') {
            snippet = document.getElementsByName('share_message')[0].value;
            snippet = `${snippet} ${text}`;
            document.getElementsByName('share_message')[0].value = snippet;
            ReplaceMessage('share_message', snippet);
        }
    }

    function ReplaceMessage(type, snippet) {
        let discount = 0;
        let discount_code = "DEMO1234";
        let company = authProps.company;

        if (type === "first_message" || type === "repeat_message") {
            if (authProps?.discount?.affiliate?.type === "fixed_amount") {
                discount = authProps.shop_details.money_in_emails_format.replace('{{amount}}', authProps.discount.affiliate.value);
            } else if (authProps?.discount?.affiliate?.type === "percentage") {
                discount = `${authProps.discount.affiliate.value}%`;
            }
        } else if (type === "share_message") {
            if (authProps?.discount?.buyer?.type === "fixed_amount") {
                discount = authProps.shop_details.money_in_emails_format.replace('{{amount}}', authProps.discount.buyer.value);
            } else if (authProps?.discount?.buyer?.type === "percentage") {
                discount = `${authProps.discount.buyer.value}%`;
            }
        }

        snippet = snippet.replaceAll('{company}', `<b>${company}</b>`);
        snippet = snippet.replaceAll('{discount}', `<b>${discount}</b>`);
        snippet = snippet.replaceAll('{discount_code}', `<b>${discount_code}</b>`);

        if (type === "first_title") {
            setFirstTitle(snippet);
        } else if (type === "first_message") {
            setFirstMessage(snippet);
        } else if (type === "repeat_title") {
            setRepeatTitle(snippet);
        } else if (type === "repeat_message") {
            setRepeatMessage(snippet);
        } else if (type === "share_message") {
            setShareMessage(snippet);
        }
    }

    function modalToggle(image) {
        setModalImage(image);
        setImageModal('is-active');
    }

    return (
        <DashboardLayout {...props} >
            <section className="pb-3">
                <h1 className="title is-size-4 mb-2 has-text-primary"> Customize Messages </h1>
            </section>

            <div className="columns is-multiline">
                <div className="column is-12">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="columns">
                            <div className="column is-6">
                                <div className="box px-0 pt-0">
                                    <div className="is-max-flex is-align-items-center is-justify-content-space-between head pt-5 px-5 mb-4">
                                        <div className="is-max-mb-4">
                                            <p className="title is-size-5 has-text-primary">Message for affiliate invitation</p>
                                        </div>
                                        <div className="is-flex is-flex-wrap-nowrap">
                                            <span className="button is-light is-danger mr-1" onClick={() => ResetToDefault('first')}><i className="fa fa-refresh mr-2"></i> Reset to Default</span>
                                            <span className="button is-light is-primary" onClick={() => ResetToSaved('first')}><i className="fa fa-mail-reply mr-2"></i> Undo</span>
                                        </div>
                                    </div>
                                    <div className="box-content px-5">
                                        <p className="mb-3">
                                            This is the message your customers will see on your thank you page, inviting them to join your affiliate program.
                                            <a className="ml-1" onClick={() => modalToggle(`${process.env.REACT_APP_APP_ENDPOINT}/images/first-message.png`)}>View Example</a>
                                        </p>
                                        <div className="field">
                                            <label className="label">Title</label>
                                            <div className="control">
                                                <input className="input" name="first_title" type="text" placeholder="Enter title" ref={register} onChange={(e) => previewUpdate(e)} defaultValue={authProps?.messages?.first?.title} />
                                            </div>
                                            <p className="help is-danger">{errors.first_title && 'Title is required'}</p>
                                        </div>
                                        <div className="field">
                                            <label className="label">Message</label>
                                            <div className="buttons">
                                                <span className="button is-link is-light" onClick={() => AddToMessage('first', '{company}')}>company</span>
                                                <span className="button is-link is-light" onClick={() => AddToMessage('first', '{discount}')}>discount</span>
                                                <span className="button is-link is-light" onClick={() => AddToMessage('first', '{discount_code}')}>discount_code</span>
                                            </div>
                                            <div className="control">
                                                <textarea className="textarea" name="first_message" placeholder="Enter message" ref={register} onChange={(e) => previewUpdate(e)} defaultValue={authProps?.messages?.first?.message} />
                                            </div>
                                            <p className="help is-danger">{errors.first_message && 'Message is required'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-6">
                                <div className="content-box__row is-flex h-full is-align-items-center is-justify-content-center">
                                    <div className="content-block">
                                        <h2>{firstTitle}</h2>
                                        <p dangerouslySetInnerHTML={{ __html: firstMessage }}></p>
                                        <a className="content-link">Activate Affiliate Discount Code</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="columns">
                            <div className="column is-6">
                                <div className="box px-0 pt-0">
                                    <div className="is-max-flex is-align-items-center is-justify-content-space-between head pt-5 px-5 mb-4">
                                        <div className="is-max-mb-4">
                                            <p className="title is-size-5 has-text-primary">Existing Affiliate, thank you page message</p>
                                        </div>
                                        <div className="is-flex is-flex-wrap-nowrap">
                                            <span className="button is-light is-danger mr-1" onClick={() => ResetToDefault('repeat')}><i className="fa fa-refresh mr-2"></i> Reset to Default</span>
                                            <span className="button is-light is-primary" onClick={() => ResetToSaved('repeat')}><i className="fa fa-mail-reply mr-2"></i> Undo</span>
                                        </div>
                                    </div>
                                    <div className="box-content px-5">
                                        <p className="mb-3">
                                            Your existing affiliates who purchase from you will see this message when they land on your thank you page.
                                            <a className="ml-1" onClick={() => modalToggle(`${process.env.REACT_APP_APP_ENDPOINT}/images/repeat-message.png`)}>View Example</a>
                                        </p>
                                        <div className="field">
                                            <label className="label">Title</label>
                                            <div className="control">
                                                <input className="input" name="repeat_title" type="text" placeholder="Enter title" ref={register} onChange={(e) => previewUpdate(e)} defaultValue={authProps?.messages?.repeat?.title} />
                                            </div>
                                            <p className="help is-danger">{errors.repeat_title && 'Title is required'}</p>
                                        </div>
                                        <div className="field">
                                            <label className="label">Message</label>
                                            <div className="buttons">
                                                <span className="button is-link is-light" onClick={() => AddToMessage('repeat', '{company}')}>company</span>
                                                <span className="button is-link is-light" onClick={() => AddToMessage('repeat', '{discount}')}>discount</span>
                                                <span className="button is-link is-light" onClick={() => AddToMessage('repeat', '{discount_code}')}>discount_code</span>
                                            </div>
                                            <div className="control">
                                                <textarea className="textarea" name="repeat_message" placeholder="Enter message" ref={register} onChange={(e) => previewUpdate(e)} defaultValue={authProps?.messages?.repeat?.message} />
                                            </div>
                                            <p className="help is-danger">{errors.repeat_message && 'Message is required'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-6">
                                <div className="content-box__row is-flex h-full is-align-items-center is-justify-content-center">
                                    <div className="content-block">
                                        <h2>{repeatTitle}</h2>
                                        <p dangerouslySetInnerHTML={{ __html: repeatMessage }}></p>
                                        <p style={{ "textAlign": "center", "fontSize": "16px", "marginTop": "5px" }}>You can access your affiliate account by clicking <a><b>here</b></a></p>
                                        <br />
                                        <p className="share-text">Share now and start earning</p>
                                        <div>
                                            <a className="facebook_button">
                                                <i className="fa fa-facebook mr-2"></i>
                                                Share on Facebook
                                            </a>
                                            <a className="twitter_button">
                                                <i className="fa fa-twitter mr-2"></i>
                                                Share on Twitter
                                            </a>
                                            <a className="custom_button">
                                                <i className="fa fa-copy mr-2"></i>
                                                Copy Code
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="columns">
                            <div className="column is-6">
                                <div className="box px-0 pt-0">
                                    <div className="is-max-flex is-align-items-center is-justify-content-space-between head pt-5 px-5 mb-4">
                                        <div className="is-max-mb-4">
                                            <p className="title is-size-5 has-text-primary">Facebook/Twitter Share message</p>
                                        </div>
                                        <div className="is-flex is-flex-wrap-nowrap">
                                            <span className="button is-light is-danger mr-1" onClick={() => ResetToDefault('share')}><i className="fa fa-refresh mr-2"></i> Reset to Default</span>
                                            <span className="button is-light is-primary" onClick={() => ResetToSaved('share')}><i className="fa fa-mail-reply mr-2"></i> Undo</span>
                                        </div>
                                    </div>
                                    <div className="box-content px-5">
                                        <p className="mb-3">
                                            This is the message that will appear on your affiliates social page when they click the share button.
                                            <a className="ml-1" onClick={() => modalToggle(`${process.env.REACT_APP_APP_ENDPOINT}/images/share-facebook.png`)}>Facebook Example</a>,
                                            <a className="ml-1" onClick={() => modalToggle(`${process.env.REACT_APP_APP_ENDPOINT}/images/share-twitter.png`)}>Twitter Example</a>
                                        </p>
                                        <div className="field">
                                            <label className="label">Message</label>
                                            <div className="buttons">
                                                <span className="button is-link is-light" onClick={() => AddToMessage('share', '{company}')}>company</span>
                                                <span className="button is-link is-light" onClick={() => AddToMessage('share', '{discount}')}>discount</span>
                                                <span className="button is-link is-light" onClick={() => AddToMessage('share', '{discount_code}')}>discount_code</span>
                                            </div>
                                            <div className="control">
                                                <textarea className="textarea" name="share_message" placeholder="Enter message" ref={register} onChange={(e) => previewUpdate(e)} defaultValue={authProps?.messages?.share_message} />
                                            </div>
                                            <p className="help is-danger">{errors.share_message && 'Message is required'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-6">
                                <div className="content-box__row is-flex h-full is-align-items-center is-justify-content-center">
                                    <div className="content-block">
                                        <h2></h2>
                                        <p dangerouslySetInnerHTML={{ __html: shareMessage }}></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="field my-5 is-flex is-justify-content-flex-end">
                            <button type="submit" className="button is-primary with-shadow">Save Changes</button>
                        </div>
                    </form>
                </div>
            </div>

            <div className={`modal ${imageModal}`}>
                <div className="modal-background"></div>
                <div className="modal-content" style={{ "width": "60%" }}>
                    <p className="image">
                        <img src={modalImage} alt="" />
                    </p>
                </div>
                <button className="modal-close is-large" aria-label="close" onClick={() => setImageModal('')}></button>
            </div>
        </DashboardLayout>
    )
}

export default Affiliates;