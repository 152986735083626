import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import moment from "moment";
import Swal from "sweetalert2";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { LoaderContext } from '../loader';
import AffiliateLayout from '../layouts/affiliate-layout';

function Coupons(props) {
    const dispatch = useDispatch();
    const authProps = useSelector(state => state.auth);
    const couponProps = useSelector(state => state.coupon);
    const couponsProps = useSelector(state => state.coupons);
    const [coupons, setCoupons] = useState({});
    const [renameModal, setRenameModal] = useState('');
    const [selectedCode, setSelectedCode] = useState('');
    const { setLoading } = useContext(LoaderContext);

    useEffect(() => {
        PullData();
    }, []);

    function PullData() {
        dispatch({
            type: "AFFILIATE_FETCH_COUPONS",
            payload: {
                id: authProps._id
            }
        });
    }

    useEffect(() => {
        if (!isEmpty(couponsProps)) {
            setCoupons(couponsProps);
        }
    }, [couponsProps]);

    useEffect(() => {
        if (!isEmpty(couponProps)) {
            PullData();

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }, [couponProps]);

    function ShareOn(social, data) {
        let description = "If anyone is interested to shop at {company}, why pay full price! Instead, use my discount code {discount_code} to get {discount} on any product";

        let buyer_discount = '';
        if (data.coupon.type == "fixed_amount") {
            buyer_discount = data.amount_format;
            buyer_discount = buyer_discount.replace('{{amount}}', data.coupon.value);
        } else if (data.coupon.type == "percentage") {
            buyer_discount = `${data.coupon.value}%`;
        }

        description = description.replace('{company}', data.company);
        description = description.replace('{discount_code}', data.coupon.title);
        description = description.replace('{discount}', buyer_discount);

        if (social === "facebook") {
            let facebook_share = `https://www.facebook.com/sharer/sharer.php?u=${data.domain}&quote=${description}`;
            window.open(facebook_share);
        } else if (social === "twitter") {
            let twitter_description = description.replace('%', "%25");
            let twitter_share = `https://twitter.com/intent/tweet?original_referer=${data.domain}&ref_src=twsrc%5Etfw&text=${twitter_description}&tw_p=tweetbutton&url=${data.domain}`;
            window.open(twitter_share);
        } else if (social === "custom") {
            Swal.fire({
                title: 'Copy the text below or modify it and share it on any website or blog',
                html: `<div style="display: block; background: #fff; padding: 10px; border: 3px dashed #29348e;">${description} - ${data.domain}</div>`,
                icon: 'warning',
                showConfirmButton: true,
                confirmButtonText: "Copy to Clipboard"
            }).then((result) => {
                if (result.isConfirmed) {
                    navigator.clipboard.writeText(`${description} ${data.domain}`);
                }
            });
        }
    }

    function RenameDiscountCode(coupon) {
        setSelectedCode(coupon);
        setRenameModal("is-active");
    }

    const schema = yup.object().shape({
        selected_coupon: yup.string().required(),
        coupon: yup.string().required().matches(/^[A-Za-z0-9]*$/g).min(6).max(14)
    });

    const { register, handleSubmit, errors, reset } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (form) => {
        setLoading(true);
        reset();

        var newForm = Object.assign({}, form);
        newForm.coupon = form.coupon.toUpperCase();

        dispatch({
            type: "RENAME_DISCOUNT_CODE",
            payload: newForm
        });

        setRenameModal('');
    }

    return (
        <AffiliateLayout {...props}>
            <section className="pb-3">
                <div className="is-md-flex is-align-items-center is-justify-content-space-between">
                    <div>
                        <h1 className="title is-size-4 is-size-4-tablet mb-2 has-text-primary"> How it works </h1>
                    </div>
                </div>
            </section>

            <div className="columns is-multiline">
                <div className="column is-12">
                    <div className="message is-info mb-3">
                        <div className="message-body">
                            When you share your discount code, and it is used to purchase, you will receive a commission in either cash or in-store credit, depending on your affiliate program. You can use the quick share buttons to share your discount code or copy the discount code and paste it on your favourite social platforms.
                            You can redeem your commission 30 days from each sale. We pay directly into your bank account for cash commission, and for in-store credits, you need to redeem the credit into a voucher code.
                            <br /><br />
                            <b>Good luck and enjoy!</b>
                        </div>
                    </div>
                    <div className="box">
                        <div className="table-container">
                            <table className="table is-striped is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Store name</th>
                                        <th>Discount Code</th>
                                        <th>Discount Value</th>
                                        <th>Discount Code Status</th>
                                        <th>Commission Value</th>
                                        <th>Payout Method</th>
                                        <th>Starts At</th>
                                        <th>Ends At</th>
                                        <th>Share on Social Media <br />Wesbite</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        Object.values(coupons).map((coupon, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td><a href={`https://${coupon.shop}`} target="_blank" rel="noopener noreferrer">{coupon.company ? coupon.company : coupon.shop}</a></td>
                                                    <td className="has-text-weight-bold">{coupon.coupon.title} <span data-tooltip="Copy Discount Code"><i className="fa fa-clone is-clickable ml-1 has-text-danger" onClick={() => navigator.clipboard.writeText(coupon.coupon.title)}></i></span></td>
                                                    <td>{coupon?.coupon?.type == "fixed_amount" ? <b>${coupon?.coupon?.value}</b> : <span><b>{coupon?.coupon?.value}%</b> of order amount</span>}</td>
                                                    <td>{coupon.status === "expired" ? <span className="tag is-danger is-light has-text-weight-semibold border border-solid border-color-danger">Expired</span> : <span className="tag is-success is-light has-text-weight-semibold border border-solid border-color-success">Active</span>}</td>
                                                    <td>{coupon?.commission?.type == "fixed_amount" ? <b>${coupon?.commission?.value}</b> : <span><b>{coupon?.commission?.value}%</b> of order amount</span>}</td>
                                                    <td>{coupon?.discount?.affiliate?.payout === "bycash" ? <span className="tag is-primary is-light has-text-weight-semibold border border-solid border-color-primary">Cash</span> : <span className="tag is-info is-light has-text-weight-semibold border border-solid border-color-info">In-store credits</span>}</td>
                                                    <td>{moment(coupon.coupon.starts_at).format('D MMM, YYYY')}</td>
                                                    <td>{coupon.coupon.ends_at === null ? "N/A" : moment(coupon.coupon.ends_at).format('D MMM, YYYY')}</td>
                                                    <td>
                                                        {
                                                            coupon.status === "expired" ? "N/A" : <React.Fragment>
                                                                <button className="button is-small has-text-white is-info-dark mr-1" onClick={() => ShareOn('facebook', coupon)}><i className="fa fa-facebook fa-lg"></i></button>
                                                                <button className="button is-small has-text-white is-info mr-1" onClick={() => ShareOn('twitter', coupon)}><i className="fa fa-twitter fa-lg"></i></button>
                                                                <button className="button is-small has-text-white is-dark mr-1" onClick={() => ShareOn('custom', coupon)}><i className="fa fa-copy fa-lg"></i></button>
                                                            </React.Fragment>
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            coupon.status === "expired" ? "N/A" : <button className="button is-small has-text-white is-success" onClick={() => RenameDiscountCode(coupon.coupon.title)}>Rename Discount Code</button>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            {
                                isEmpty(coupons) ? <p className="pb-5 has-text-centered is-fullwidth">No discount coupons found</p> : ""
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className={`modal ${renameModal}`}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <header className="modal-card-head">
                            <p className="modal-card-title is-size-5 has-text-weight-semibold">Rename Discount Code</p>
                            <a className="delete" aria-label="close" onClick={() => setRenameModal('')}></a>
                        </header>
                        <section className="modal-card-body">
                            <div className="field">
                                <label className="label">Discount Code</label>
                                <input className="input" type="hidden" name="selected_coupon" defaultValue={selectedCode} ref={register} />
                                <input className="input is-uppercase" type="text" name="coupon" placeholder="Enter discount code" ref={register} />
                                <p className="help is-danger">{errors.coupon && "Discount code should not have special characters. Allowed discount code length is 6-14 characters."}</p>
                            </div>
                        </section>
                        <footer className="modal-card-foot is-justify-content-flex-end">
                            <a className="button has-background-white-bis border border-sold border-color-white-bis has-text-dark" onClick={() => setRenameModal('')}>Cancel</a>
                            <button className="button is-primary" type="submit">Submit</button>
                        </footer>
                    </form>
                </div>
            </div>
        </AffiliateLayout>
    )
}

export default Coupons;