import React, { useContext } from "react";

import Header from "../components/header";
import Sidebar from "../components/sidebar";
import { Loader, LoaderContext } from "../loader";

function DashboardLayout(props) {
    const { loading } = useContext(LoaderContext);

    return (
        <React.Fragment>
            <Sidebar />
            <Header />
            <div className="page-wrapper px-5">
                <Loader loading={loading} />
                <div className="content-area w-full py-5">
                    {props.children}
                </div>
            </div>
        </React.Fragment>
    )
}

export default DashboardLayout;