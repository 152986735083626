import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import Swal from "sweetalert2";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { Storage, StaticData } from "../helpers";
import { LoaderContext } from '../loader';
import AffiliateLayout from '../layouts/affiliate-layout';

function Payments(props) {
    const dispatch = useDispatch();
    const authProps = useSelector(state => state.auth);
    const { setLoading } = useContext(LoaderContext);

    // Bank Details
    const schema = yup.object().shape({
        firstname: yup.string().required(),
        lastname: yup.string().required(),
        email: yup.string().email().required(),
        address: yup.string().required(),
        city: yup.string().required(),
        state: yup.string().required(),
        country: yup.string().required(),
        postal_code: yup.string().required(),
        holder_name: yup.string().required(),
        account_currency: yup.string(),
        bank_name: yup.string().required(),
        branch_id: yup.string(),
        account_number: yup.string().required(),
    });

    const { register, handleSubmit, errors, reset } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit2 = (form) => {
        setLoading(true);
        dispatch({
            type: "CREATE_BANK",
            payload: form
        });

        setTimeout(() => {
            setLoading(false);
        }, 3000);

        reset();
    }

    useEffect(() => {
        if (!isEmpty(authProps) && authProps?.token) {
            Storage.Add('token', authProps.token);
        }
    }, [authProps]);

    function RemoveBank() {
        Swal.fire({
            title: "Are you sure you want to remove the bank?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: "No"
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                dispatch({
                    type: "REMOVE_BANK",
                    payload: {}
                });

                setTimeout(() => {
                    setLoading(false);
                }, 2000);
            }
        });
    }

    return (
        <AffiliateLayout {...props} >
            <section className="pb-3">
                <div className="container is-max-widescreen">
                    <h1 className="title is-size-4 mb-2 has-text-primary"> Payment Settings </h1>
                </div>
            </section>

            {
                authProps?.bank_details === undefined || authProps?.bank_details === "" ?
                    <div className="column is-12">
                        <div className="container is-max-widescreen">
                            <form onSubmit={handleSubmit(onSubmit2)}>
                                <div className="box px-0 pt-0">
                                    <div className="head py-4 px-5 mb-2">
                                        <h3 className="title is-size-5 has-text-primary">Bank Details</h3>
                                    </div>
                                    <div className="box-content px-5">
                                        <div className="subtitle is-size-6">To pay you directly into your nominated account we need you to provide the below information.</div>
                                        <div className="field">
                                            <label className="label">Account Holder name</label>
                                            <p className="help has-text-grey-dark">Exact name of account owner/holder on your bank account.</p>
                                            <input type="text" className="input" name="holder_name" ref={register} />
                                            <p className="help is-danger mt-0">{errors.holder_name && "Account Holder Name is required"}</p>
                                        </div>
                                        <div className="field">
                                            <label className="label">Account Currency</label>
                                            <p className="help has-text-grey-dark">Please select the default currency for your Bank.</p>
                                            <div className="select is-fullwidth">
                                                <select name="account_currency" ref={register}>
                                                    <option value="">Select Account Currency</option>
                                                    {
                                                        StaticData.ListCurrencies().map((currency, index) => {
                                                            return (
                                                                <option value={currency.code} key={index}>{currency.code} - {currency.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <p className="help mt-0 is-danger">{errors.account_currency && "Account Currency is required"}</p>
                                        </div>
                                        <div className="field mb-4">
                                            <label className="label">Bank Name</label>
                                            <input type="text" className="input" name="bank_name" ref={register} />
                                            <p className="help is-danger">{errors.bank_name && "Bank Name is required"}</p>
                                        </div>
                                        <div className="field">
                                            <label className="label">Branch ID</label>
                                            <p className="help has-text-grey-dark">Bank’s branch number, only required in Australia (BSB), US (ABA Routing Number), UK (Sort Code),Europe (IBAN), Canada (Transit Number), India (IFSC Code).</p>
                                            <input type="text" className="input" name="branch_id" ref={register} />
                                        </div>
                                        <div className="field mb-4">
                                            <label className="label">Bank Account Number</label>
                                            <input type="text" className="input" name="account_number" ref={register} />
                                            <p className="help is-danger">{errors.bank_id && "Bank Account Number is required"}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="box px-0 pt-0">
                                    <div className="head py-4 px-5 mb-2">
                                        <h3 className="title is-size-5 has-text-primary">User Details</h3>
                                    </div>
                                    <div className="box-content px-5">
                                        <div className="field is-horizontal">
                                            <div className="field-body">
                                                <div className="field">
                                                    <label className="label">First Name</label>
                                                    <input type="text" className="input" name="firstname" placeholder="First Name" defaultValue={authProps.firstname} ref={register} />
                                                    <p className="help is-danger">{errors.firstname && 'First Name is required'}</p>
                                                </div>
                                                <div className="field">
                                                    <label className="label">Last Name</label>
                                                    <input type="text" className="input" name="lastname" placeholder="Last Name" defaultValue={authProps.lastname} ref={register} />
                                                    <p className="help is-danger">{errors.lastname && 'Last Name is required'}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="field">
                                            <label className="label">Email Address</label>
                                            <input type="email" className="input" name="email" placeholder="Email Address" defaultValue={authProps.email} ref={register} />
                                            <p className="help is-danger">{errors.email && 'Email is required'}</p>
                                        </div>
                                        <div className="field">
                                            <div className="field-body">
                                                <div className="field">
                                                    <label className="label">City</label>
                                                    <input type="text" className="input" name="city" defaultValue={authProps?.city} ref={register} />
                                                    <p className="help is-danger">{errors.city && 'City is required'}</p>
                                                </div>
                                                <div className="field">
                                                    <label className="label">Postal Code</label>
                                                    <input type="text" className="input" name="postal_code" defaultValue={authProps?.postal_code} ref={register} />
                                                    <p className="help is-danger">{errors.postal_code && 'Postal Code is required'}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="field">
                                            <div className="field-body">
                                                <div className="field is-flex-basis-0">
                                                    <label className="label">State</label>
                                                    <input type="text" className="input" name="state" defaultValue={authProps?.state} ref={register} />
                                                    <p className="help is-danger">{errors.state && 'State is required'}</p>
                                                </div>
                                                <div className="field is-flex-basis-0">
                                                    <label className="label">Country</label>
                                                    <div className="select is-fullwidth">
                                                        <select name="country" ref={register} defaultValue={authProps?.country}>
                                                            <option value="">Select Country</option>
                                                            {
                                                                StaticData.ListCountries().map((country, index) => {
                                                                    return (
                                                                        <option value={country.sortname} key={index}>{country.name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <p className="help is-danger">{errors.country && 'Country is required'}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="field">
                                            <label className="label">Address</label>
                                            <input type="text" className="input" name="address" defaultValue={authProps?.address} ref={register} />
                                            <p className="help is-danger">{errors.address && 'Address is required'}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="field is-flex is-justify-content-flex-end">
                                    <button type="submit" className="button is-primary with-shadow px-5">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div> : ""
            }

            {
                !isEmpty(authProps?.bank_details) ?
                    <div className="column is-12">
                        <div className="container is-max-widescreen">
                            <div className="box box px-0 pt-0">
                                <div className="head py-4 px-5">
                                    <h3 className="title is-size-5 has-text-primary">Saved Bank Details</h3>
                                </div>
                                <div className="box-content px-5">
                                    <div className="table-container">
                                        <table className="table is-striped is-fullwidth">
                                            <thead>
                                                <tr>
                                                    <th>Bank Name</th>
                                                    <th>Account Holder Name</th>
                                                    <th>Account Number</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{authProps?.bank_details?.bank_name}</td>
                                                    <td>{authProps?.bank_details?.account_holder_name}</td>
                                                    <td>{authProps?.bank_details?.account_number}</td>
                                                    <td><button className="button is-danger is-small" onClick={() => RemoveBank()}>Remove Bank</button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : ""
            }
        </AffiliateLayout >
    )
}

export default Payments;