import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { LoaderContext } from '../loader';
import AdminLayout from '../layouts/admin-layout';

function ExportData(props) {
    const dispatch = useDispatch();
    const authProps = useSelector(state => state.auth);
    const storeProps = useSelector(state => state.stores);
    const fileProps = useSelector(state => state.file);
    const [stores, setStores] = useState({});
    const [formdata, setFormdata] = useState({});
    const { setLoading } = useContext(LoaderContext);

    const schema = yup.object().shape({
        shop: yup.string().required(),
        type: yup.string().required(),
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (form) => {
        setLoading(true);
        setFormdata(form);
        dispatch({
            type: "ADMIN_EXPORT_DATA",
            payload: form
        });
    }

    useEffect(() => {
        dispatch({
            type: "ADMIN_FETCH_STORES",
            payload: {}
        });
    }, []);

    useEffect(() => {
        if(!isEmpty(storeProps)) {
            setStores(storeProps);
        }
    }, [storeProps]);

    useEffect(() => {
        setLoading(false);
        if(!isEmpty(fileProps) && formdata.shop !== undefined && formdata.type !== undefined ) {
            window.open(fileProps.file);
        }
    }, [fileProps]);

    return (
        <AdminLayout {...props} >
            <section className="pb-3">
                <div className="container is-max-widescreen">
                    <h1 className="title is-size-4 mb-2 has-text-primary"> Export Customers/Orders </h1>
                </div>
            </section>
            <div className="container is-max-widescreen">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="box px-0 pt-0">
                        <div className="head py-4 px-5">
                            <div className="title is-size-5 has-text-primary">Export Data</div>
                        </div>
                        <div className="box-content px-5">
                            <div className="columns is-multiline">
                                <div className="column is-6">
                                    <label className="label">Select Store</label>
                                    <div className="select is-fullwidth">
                                        <select name="shop" ref={register}>
                                            <option value="Select Store">Select Store</option>
                                            {
                                                !isEmpty(stores) && Object.values(stores).map((store, index) => {
                                                    return (
                                                        <option value={store.shop} key={index}>{store.company} - {store.shop}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <p className="help is-danger">{errors.shop && 'Store is required'}</p>
                                </div>
                                <div className="column is-6">
                                    <label className="label">Select Export Type</label>
                                    <div className="select is-fullwidth">
                                        <select name="type" ref={register}>
                                            <option value="">Select Export Type</option>
                                            <option value="customers">Customers</option>
                                            <option value="orders">Orders</option>
                                        </select>
                                    </div>
                                    <p className="help is-danger">{errors.type && 'Export Type is required'}</p>
                                </div>
                            </div>
                            <div className="field is-flex is-justify-content-flex-end">
                                <button type="submit" className="button is-primary px-5 with-shadow">Submit</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </AdminLayout>
    )
}

export default ExportData;