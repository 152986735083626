import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { isEmpty } from "lodash";
import Pagination from "react-js-pagination";
import moment from "moment-timezone";

import AdminLayout from '../layouts/admin-layout';

function AdminUsers(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [users, setUsers] = useState({});
    const userProps = useSelector(state => state.users);

    // Pagination
    const limit = 250;
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        PullData();
    }, [page]);

    function PullData() {
        dispatch({
            type: "ADMIN_FETCH_USERS",
            payload: {
                page: page - 1,
                limit: limit
            }
        });
    }

    useEffect(() => {
        if (!isEmpty(userProps) && userProps.results !== undefined) {
            setUsers(userProps.results);
            setTotal(userProps.total)
        }
    }, [userProps]);

    return (
        <AdminLayout {...props} >
            <section className="pb-3">
                <div className="is-md-flex is-align-items-center is-justify-content-space-between">
                    <div>
                        <h1 className="title is-size-4 is-size-4-tablet mb-2 has-text-primary"> Users </h1>
                    </div>
                </div>
            </section>

            <div className="columns is-multiline">
                <div className="column is-12">
                    <div className="box">
                        <div className="table-container">
                            <table className="table is-striped is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Username</th>
                                        <th>Email</th>
                                        <th>Shop/Company</th>
                                        <th>Status</th>
                                        <th>Country</th>
                                        <th>Plan</th>
                                        <th>Price</th>
                                        <th>Commission</th>
                                        <th>Created At</th>
                                        <th>Updated At</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !isEmpty(users) && Object.values(users).map((user, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{user?.firstname} {user?.lastname}</td>
                                                    <td>{user?.email}</td>
                                                    <td className="has-text-weight-bold">{user?.company === undefined ? <a href={`https://${user?.shop}`} target='_blank'>{user.shop}</a> : <a href={`https://${user?.shop}`} target='_blank'>{user.company}</a>}</td>
                                                    <td>{user?.status === 'active' ? <span className="tag is-success is-light has-text-weight-semibold border border-solid border-color-success">Active</span> : <span className="tag is-danger is-light has-text-weight-semibold border border-solid border-color-danger">Inactive</span>}</td>
                                                    <td>{user?.shop_details?.country_name}</td>
                                                    <td className="has-text-weight-bold">{user?.plan_details?.name}</td>
                                                    <td className="has-text-weight-bold">${user?.plan_details?.price}</td>
                                                    <td className="has-text-weight-bold">{user?.plan_details?.commission}%</td>
                                                    <td>{moment(user?.created_at).tz('Australia/Sydney').format('D MMM, YYYY hh:mm:ss A')}</td>
                                                    <td>{moment(user?.updated_at).tz('Australia/Sydney').format('D MMM, YYYY hh:mm:ss A')}</td>
                                                    <td>
                                                        <button className="button is-primary is-small" onClick={() => history.push(`/admin/users/${user._id}`)}>View Affiliates</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className="column is-12">
                <div className="pull-left"><b>Total Users</b> : {total}</div>
                <Pagination activePage={page} itemsCountPerPage={limit} totalItemsCount={total} pageRangeDisplayed={5} onChange={(e) => setPage(e)} />
            </div>
        </AdminLayout>
    )
}

export default AdminUsers;