import React, { useContext } from "react";

import { Loader, LoaderContext } from "../loader";

function AuthLayout(props) {
    const { loading } = useContext(LoaderContext);

    return (
        <React.Fragment>
            <Loader loading={loading} />
            {props.children}
        </React.Fragment>
    )
}

export default AuthLayout;