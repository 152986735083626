import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import DashboardLayout from "../layouts/dashboard-layout";
import { isEmpty } from 'lodash';
import moment from 'moment';
import Swal from "sweetalert2";

function UpgradePlan(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const planProps = useSelector(state => state.plans);
    const authProps = useSelector(state => state.auth);
    const chargeProps = useSelector(state => state.charge);
    const [plans, setPlans] = useState({});

    let location = new URLSearchParams(useLocation().search);
    let chargeID = location.get("charge_id");

    useEffect(() => {
        dispatch({
            type: "ADMIN_GET_PLANS",
            payload: {}
        });
    }, []);

    useEffect(() => {
        if (!isEmpty(planProps)) {
            setPlans(planProps);
        }
    }, [planProps]);

    useEffect(() => {
        if (!isEmpty(chargeProps)) {
            window.location.href = chargeProps.recurring_application_charge.confirmation_url;
        }
    }, [chargeProps]);

    useEffect(() => {
        if (chargeID !== null) {
            dispatch({
                type: "SET_RECURRING_CHARGE",
                payload: {
                    id: chargeID
                }
            });

            setTimeout(() => {
                history.push('/upgrade-plan');
            }, 2000);
        }
    }, [chargeID]);

    function SelectPlan(plan) {
        let flag = false;
        if (plan.title === "Starter" && authProps?.discount?.affiliate?.payout === "bycredits") {
            Swal.fire({
                title: "Are your sure you want to downgrade?",
                html: 'Downgrading your plan will switch your payout method from in-Store credits to cash payouts',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: "No"
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch({
                        type: "SET_RECURRING_CHARGE",
                        payload: {
                            id: "Free",
                            plan: plan.title
                        }
                    });
                }
            });
        } else {
            Swal.fire({
                title: plan.title + " Plan",
                text: "Are you sure you want to select this plan?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: "No"
            }).then((result) => {
                if (result.isConfirmed) {
                    if (plan.price === 0) {
                        dispatch({
                            type: "SET_RECURRING_CHARGE",
                            payload: {
                                id: "Free",
                                plan: plan.title
                            }
                        });
                    } else {
                        dispatch({
                            type: "CREATE_RECURRING_CHARGE",
                            payload: {
                                id: plan._id,
                                page: "upgrade-plan"
                            }
                        });
                    }
                }
            });
        }
    }

    function CancelPlan() {
        Swal.fire({
            title: 'Are you sure you want to cancel?',
            html: "Cancelling your subscription will remove all the features of the Shop Share Paid app. If you wish to upgrade do not cancel, instead select an upgrade plan.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: "No"
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch({
                    type: "CANCEL_RECURRING_CHARGE",
                    payload: {
                        id: authProps.plan_details.id
                    }
                });
            }
        });
    }

    return (
        <DashboardLayout {...props}>
            <section className="pb-3">
                <div className="container is-max-widescreen">
                    <div className="is-flex">
                        <h1 className="title mr-4 is-size-4 is-size-4-tablet mb-2 is-clickable has-text-primary"> Shop Share Paid Plans </h1>
                    </div>
                </div>
            </section>
            <div className="container is-max-widescreen">
                <div className="columns">
                    <div className="column is-12 mb-3">
                        <div className="box">
                            <div className="table-container">
                                <table className="table is-striped is-fullwidth">
                                    <thead>
                                        <tr>
                                            <th className="has-text-centered">Current Plan</th>
                                            <th className="has-text-centered">Activated On</th>
                                            <th className="has-text-centered">Billed On</th>
                                            <th className="has-text-centered">Trial</th>
                                            <th className="has-text-centered">Trial Expires</th>
                                            <th className="has-text-centered">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            authProps.plan_details === undefined ?
                                                <tr>
                                                    <td colSpan="6" className="has-text-centered is-size-4 pt-5">No Plan Selected</td>
                                                </tr> :
                                                <tr>
                                                    <td className="has-text-centered">{authProps.plan_details.name}</td>
                                                    <td className="has-text-centered">{moment(authProps.plan_details.activated_on).format("D MMM, YYYY")}</td>
                                                    <td className="has-text-centered">{authProps.plan_details.billing_on === undefined ? "Not Applicable" : moment(authProps.plan_details.billing_on).format("D MMM, YYYY")}</td>
                                                    <td className="has-text-centered">{authProps.plan_details.trial_days === 0 || authProps.plan_details.trial_days === null ? "Not Applicable" : `${authProps.plan_details.trial_days} Days`} </td>
                                                    <td className="has-text-centered">{authProps.plan_details.trial_days === 0 || authProps.plan_details.trial_days === null ? "Not Applicable" : moment(authProps.plan_details.trial_ends_on).format("D MMM, YYYY")}</td>
                                                    <td className="has-text-centered"><button className="button is-danger" onClick={() => CancelPlan()}> Cancel </button></td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="plans-section pt-4 container is-max-widescreen">
                <div className="container">
                    <div className="columns">
                        <div className="column">
                            <h2 className="title is-size-3 has-text-weight-bold has-text-centered mb-4 has-text-primary">Our Plans</h2>
                        </div>
                    </div>
                    <div className="columns plans mt-5 is-multiline">
                        {
                            Object.values(plans).map((plan, index) => {
                                return (
                                    <div className="column is-4" key={index}>
                                        <div className="panel has-background-white is-link has-text-centered h-full">
                                            <p className="panel-heading py-5 mb-5 has-text-weight-bold is-size-3"> <span className="is-block py-2">{plan.title}</span></p>
                                            <div className="panel-block b-0 is-justify-content-center pt-1 mb-4 is-flex-direction-column">
                                                <p className="title has-text-weight-bold is-size-4 has-text-primary mb-2">
                                                    {
                                                        plan.title === "Enterprise" ? <React.Fragment>
                                                            <span>From <b>$</b></span>
                                                            <span>{plan.price}<small className="is-size-5m6">/Month</small></span>
                                                        </React.Fragment> : <React.Fragment>
                                                            {
                                                                plan.price === 0 ? <span>Free Plan</span> : <React.Fragment>
                                                                    <span><b>$</b></span>
                                                                    <span>{plan.price}<small className="is-size-5m6">/Month</small></span>
                                                                </React.Fragment>
                                                            }
                                                        </React.Fragment>
                                                    }
                                                </p>
                                            </div>
                                            <div className="panel-block button-wrap mb-5 is-justify-content-center px-5">
                                                {
                                                    authProps.plan_details !== undefined && authProps.plan_details.name === plan.title ?
                                                        <button className="button w-full is-size-5m6 is-primary is-outlined has-text-weight-semibold py-5 px-5"> Current Plan </button> :
                                                        plan.title === "Enterprise" ? <a href="mailto:contact@shopsharepaid.com" target="_blank" className="is-size-5m6 button w-full is-primary has-text-weight-semibold py-5 px-5" rel="noopener noreferrer"> Get in Touch </a> :
                                                            <button className="button is-size-5m6 w-full is-primary has-text-weight-semibold py-5 px-5" onClick={() => SelectPlan(plan)}> Get Started </button>
                                                }
                                            </div>
                                            <div className="px-4 pt-0 pb-5">
                                                {
                                                    plan.trial === 0 ? '' : <li className="panel-block b-0 is-justify-content-center">
                                                        <span className="has-text-primary has-text-weight-bold is-size-5m6">{plan.trial} day free trial </span>
                                                    </li>
                                                }

                                                {
                                                    plan.features.split(",").map((feature, index) => {
                                                        return (
                                                            <li className="panel-block b-0 is-justify-content-center" key={index}>
                                                                <span className="has-text-primary has-text-weight-medium is-size-5m6">{feature}</span>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}

export default UpgradePlan;