import { takeLatest, put, call } from "redux-saga/effects";
import axios from "axios";

import { Storage } from '../helpers';

//     __                _     
//    / /   ____  ____ _(_)___ 
//   / /   / __ \/ __ `/ / __ \
//  / /___/ /_/ / /_/ / / / / /
// /_____/\____/\__, /_/_/ /_/ 
//             /____/          

export function* watchAdminLogin() {
    yield takeLatest("ADMIN_LOGIN", trackAdminLogin);
}

function* trackAdminLogin(sagaData) {
    try {
        var response = yield call(callAdminLogin, sagaData.payload);

        if (response) {
            yield put({
                type: "ADMIN_LOGIN_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "ADMIN_LOGIN_ERROR",
            response: err
        });
    }
}

function callAdminLogin(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/admin/login", {
            email: saga.email,
            password: saga.password
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//    ________                              ____                                          __
//   / ____/ /_  ____ _____  ____ ____     / __ \____ ____________      ______  _________/ /
//  / /   / __ \/ __ `/ __ \/ __ `/ _ \   / /_/ / __ `/ ___/ ___/ | /| / / __ \/ ___/ __  / 
// / /___/ / / / /_/ / / / / /_/ /  __/  / ____/ /_/ (__  |__  )| |/ |/ / /_/ / /  / /_/ /  
// \____/_/ /_/\__,_/_/ /_/\__, /\___/  /_/    \__,_/____/____/ |__/|__/\____/_/   \__,_/   
//                        /____/                                                            

export function* watchAdminChangePassword() {
    yield takeLatest("ADMIN_CHANGE_PASSWORD", trackAdminChangePassword);
}

function* trackAdminChangePassword(sagaData) {
    try {
        var response = yield call(callAdminChangePassword, sagaData.payload);

        if (response) {
            yield put({
                type: "ADMIN_CHANGE_PASSWORD_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "ADMIN_CHANGE_PASSWORD_ERROR",
            response: err
        });
    }
}

function callAdminChangePassword(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/admin/change-password", {
            currentPassword: saga.currentPassword,
            password: saga.password,
            confirmPassword: saga.confirmPassword
        }, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("admin_token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//    __  __          __      __          ____             _____ __   
//   / / / /___  ____/ /___ _/ /____     / __ \_________  / __(_) /__ 
//  / / / / __ \/ __  / __ `/ __/ _ \   / /_/ / ___/ __ \/ /_/ / / _ \
// / /_/ / /_/ / /_/ / /_/ / /_/  __/  / ____/ /  / /_/ / __/ / /  __/
// \____/ .___/\__,_/\__,_/\__/\___/  /_/   /_/   \____/_/ /_/_/\___/ 
//     /_/                                                            

export function* watcAdminUpdateProfile() {
    yield takeLatest("ADMIN_UPDATE_PROFILE", tracAdminUpdateProfile);
}

function* tracAdminUpdateProfile(sagaData) {
    try {
        var response = yield call(callAdminUpdateProfile, sagaData.payload);

        if (response) {
            yield put({
                type: "ADMIN_UPDATE_PROFILE_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "ADMIN_UPDATE_PROFILE_ERROR",
            response: err
        });
    }
}

function callAdminUpdateProfile(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/admin/update-profile", {
            firstname: saga.firstname,
            lastname: saga.lastname,
            email: saga.email,
        }, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("admin_token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//    ______     __     ____  __                
//   / ____/__  / /_   / __ \/ /___ _____  _____
//  / / __/ _ \/ __/  / /_/ / / __ `/ __ \/ ___/
// / /_/ /  __/ /_   / ____/ / /_/ / / / (__  ) 
// \____/\___/\__/  /_/   /_/\__,_/_/ /_/____/  

export function* watchAdminGetPlans() {
    yield takeLatest("ADMIN_GET_PLANS", trackAdminGetPlans);
}

function* trackAdminGetPlans(sagaData) {
    try {
        var response = yield call(callAdminGetPlans, sagaData.payload);

        if (response) {
            yield put({
                type: "ADMIN_GET_PLANS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "ADMIN_GET_PLANS_ERROR",
            response: err
        });
    }
}

function callAdminGetPlans(saga) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/admin/plans", {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("admin_token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ___       __    __   ____  __          
//    /   | ____/ /___/ /  / __ \/ /___ _____ 
//   / /| |/ __  / __  /  / /_/ / / __ `/ __ \
//  / ___ / /_/ / /_/ /  / ____/ / /_/ / / / /
// /_/  |_\__,_/\__,_/  /_/   /_/\__,_/_/ /_/ 

export function* watchAdminAddPlan() {
    yield takeLatest("ADMIN_ADD_PLAN", trackAdminAddPlan);
}

function* trackAdminAddPlan(sagaData) {
    try {
        var response = yield call(callAdminAddPlan, sagaData.payload);

        if (response) {
            yield put({
                type: "ADMIN_ADD_PLAN_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "ADMIN_ADD_PLAN_ERROR",
            response: err
        });
    }
}

function callAdminAddPlan(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/admin/plan/add", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("admin_token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//    __  __          __      __          ____  __          
//   / / / /___  ____/ /___ _/ /____     / __ \/ /___ _____ 
//  / / / / __ \/ __  / __ `/ __/ _ \   / /_/ / / __ `/ __ \
// / /_/ / /_/ / /_/ / /_/ / /_/  __/  / ____/ / /_/ / / / /
// \____/ .___/\__,_/\__,_/\__/\___/  /_/   /_/\__,_/_/ /_/ 
//     /_/                                                  

export function* watchAdminUpdatePlan() {
    yield takeLatest("ADMIN_UPDATE_PLAN", trackAdminUpdatePlan);
}

function* trackAdminUpdatePlan(sagaData) {
    try {
        var response = yield call(callAdminUpdatePlan, sagaData.payload);

        if (response) {
            yield put({
                type: "ADMIN_UPDATE_PLAN_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "ADMIN_UPDATE_PLAN_ERROR",
            response: err
        });
    }
}

function callAdminUpdatePlan(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/admin/plan/update", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("admin_token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ____                                    ____  __          
//    / __ \___  ____ ___  ____ _   _____     / __ \/ /___ _____ 
//   / /_/ / _ \/ __ `__ \/ __ \ | / / _ \   / /_/ / / __ `/ __ \
//  / _, _/  __/ / / / / / /_/ / |/ /  __/  / ____/ / /_/ / / / /
// /_/ |_|\___/_/ /_/ /_/\____/|___/\___/  /_/   /_/\__,_/_/ /_/ 

export function* watchAdminRemovePlan() {
    yield takeLatest("ADMIN_REMOVE_PLAN", trackAdminRemovePlan);
}

function* trackAdminRemovePlan(sagaData) {
    try {
        var response = yield call(callAdminRemovePlan, sagaData.payload);

        if (response) {
            yield put({
                type: "ADMIN_REMOVE_PLAN_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "ADMIN_REMOVE_PLAN_ERROR",
            response: err
        });
    }
}

function callAdminRemovePlan(saga) {
    return new Promise((resolve, reject) => {
        axios.delete(process.env.REACT_APP_API_ENDPOINT + "/admin/plan/remove", {
            params: {
                id: saga.id
            },
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("admin_token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ______     __       __       __  __                   
//    / ____/__  / /______/ /_     / / / /_______  __________
//   / /_  / _ \/ __/ ___/ __ \   / / / / ___/ _ \/ ___/ ___/
//  / __/ /  __/ /_/ /__/ / / /  / /_/ (__  )  __/ /  (__  ) 
// /_/    \___/\__/\___/_/ /_/   \____/____/\___/_/  /____/  
                                                          
export function* watchAdminFetchUsers() {
    yield takeLatest("ADMIN_FETCH_USERS", trackAdminFetchUsers);
}

function* trackAdminFetchUsers(sagaData) {
    try {
        var response = yield call(callAdminFetchUsers, sagaData.payload);

        if (response) {
            yield put({
                type: "ADMIN_FETCH_USERS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "ADMIN_FETCH_USERS_ERROR",
            response: err
        });
    }
}

function callAdminFetchUsers(saga) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/admin/fetch-users", {
            params: saga,
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("admin_token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ___       __          _          ______     __       __       ___    _________ ___       __           
//    /   | ____/ /___ ___  (_)___     / ____/__  / /______/ /_     /   |  / __/ __(_) (_)___ _/ /____  _____
//   / /| |/ __  / __ `__ \/ / __ \   / /_  / _ \/ __/ ___/ __ \   / /| | / /_/ /_/ / / / __ `/ __/ _ \/ ___/
//  / ___ / /_/ / / / / / / / / / /  / __/ /  __/ /_/ /__/ / / /  / ___ |/ __/ __/ / / / /_/ / /_/  __(__  ) 
// /_/  |_\__,_/_/ /_/ /_/_/_/ /_/  /_/    \___/\__/\___/_/ /_/  /_/  |_/_/ /_/ /_/_/_/\__,_/\__/\___/____/  
                                                                                                          
export function* watchAdminFetchAffiliates() {
    yield takeLatest("ADMIN_FETCH_AFFILIATES", trackAdminFetchAffiliates);
}

function* trackAdminFetchAffiliates(sagaData) {
    try {
        var response = yield call(callAdminFetchAffiliates, sagaData.payload);

        if (response) {
            yield put({
                type: "ADMIN_FETCH_AFFILIATES_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "ADMIN_FETCH_AFFILIATES_ERROR",
            response: err
        });
    }
}

function callAdminFetchAffiliates(saga) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/admin/fetch-affiliates", {
            params: saga,
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("admin_token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ___       __          _          ______     __       __       ____                          __      
//    /   | ____/ /___ ___  (_)___     / ____/__  / /______/ /_     / __ \____ ___  ______  __  __/ /______
//   / /| |/ __  / __ `__ \/ / __ \   / /_  / _ \/ __/ ___/ __ \   / /_/ / __ `/ / / / __ \/ / / / __/ ___/
//  / ___ / /_/ / / / / / / / / / /  / __/ /  __/ /_/ /__/ / / /  / ____/ /_/ / /_/ / /_/ / /_/ / /_(__  ) 
// /_/  |_\__,_/_/ /_/ /_/_/_/ /_/  /_/    \___/\__/\___/_/ /_/  /_/    \__,_/\__, /\____/\__,_/\__/____/  
//                                                                           /____/                        

export function* watchAdminFetchPayouts() {
    yield takeLatest("ADMIN_FETCH_PAYOUTS", trackAdminFetchPayouts);
}

function* trackAdminFetchPayouts(sagaData) {
    try {
        var response = yield call(callAdminFetchPayouts, sagaData.payload);

        if (response) {
            yield put({
                type: "ADMIN_FETCH_PAYOUTS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "ADMIN_FETCH_PAYOUTS_ERROR",
            response: err
        });
    }
}

function callAdminFetchPayouts(saga) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/admin/fetch-payouts", {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("admin_token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//    ______                      __     __          ____                          __      
//   / ____/___  ____ ___  ____  / /__  / /____     / __ \____ ___  ______  __  __/ /______
//  / /   / __ \/ __ `__ \/ __ \/ / _ \/ __/ _ \   / /_/ / __ `/ / / / __ \/ / / / __/ ___/
// / /___/ /_/ / / / / / / /_/ / /  __/ /_/  __/  / ____/ /_/ / /_/ / /_/ / /_/ / /_(__  ) 
// \____/\____/_/ /_/ /_/ .___/_/\___/\__/\___/  /_/    \__,_/\__, /\____/\__,_/\__/____/  
//                     /_/                                   /____/                        

export function* watchAdminCompletePayouts() {
    yield takeLatest("ADMIN_COMPLETE_PAYOUTS", trackAdminCompletePayouts);
}

function* trackAdminCompletePayouts(sagaData) {
    try {
        var response = yield call(callAdminCompletePayouts, sagaData.payload);

        if (response) {
            yield put({
                type: "ADMIN_COMPLETE_PAYOUTS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "ADMIN_COMPLETE_PAYOUTS_ERROR",
            response: err
        });
    }
}

function callAdminCompletePayouts(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/admin/complete-payouts", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("admin_token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ____             __    __                         __   ____       __        _ __    
//    / __ \____ ______/ /_  / /_  ____  ____ __________/ /  / __ \___  / /_____ _(_) /____
//   / / / / __ `/ ___/ __ \/ __ \/ __ \/ __ `/ ___/ __  /  / / / / _ \/ __/ __ `/ / / ___/
//  / /_/ / /_/ (__  ) / / / /_/ / /_/ / /_/ / /  / /_/ /  / /_/ /  __/ /_/ /_/ / / (__  ) 
// /_____/\__,_/____/_/ /_/_.___/\____/\__,_/_/   \__,_/  /_____/\___/\__/\__,_/_/_/____/  

export function* watchAdminDashboardDetails() {
    yield takeLatest("ADMIN_DASHBOARD_DETAILS", trackAdminDashboardDetails);
}

function* trackAdminDashboardDetails(sagaData) {
    try {
        var response = yield call(callAdminDashboardDetails, sagaData.payload);

        if (response) {
            yield put({
                type: "ADMIN_DASHBOARD_DETAILS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "ADMIN_DASHBOARD_DETAILS_ERROR",
            response: err
        });
    }
}

function callAdminDashboardDetails(saga) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + `/admin/dashboard-details`, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("admin_token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ___       __          _          ______     __       __       _____ __                      
//    /   | ____/ /___ ___  (_)___     / ____/__  / /______/ /_     / ___// /_____  ________  _____
//   / /| |/ __  / __ `__ \/ / __ \   / /_  / _ \/ __/ ___/ __ \    \__ \/ __/ __ \/ ___/ _ \/ ___/
//  / ___ / /_/ / / / / / / / / / /  / __/ /  __/ /_/ /__/ / / /   ___/ / /_/ /_/ / /  /  __(__  ) 
// /_/  |_\__,_/_/ /_/ /_/_/_/ /_/  /_/    \___/\__/\___/_/ /_/   /____/\__/\____/_/   \___/____/  
                                                                                                
export function* watchAdminFetchStores() {
    yield takeLatest("ADMIN_FETCH_STORES", trackAdminFetchStores);
}

function* trackAdminFetchStores(sagaData) {
    try {
        var response = yield call(callAdminFetchStores, sagaData.payload);

        if (response) {
            yield put({
                type: "ADMIN_FETCH_STORES_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "ADMIN_FETCH_STORES_ERROR",
            response: err
        });
    }
}

function callAdminFetchStores(saga) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/admin/fetch-stores", {
            params: saga,
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("admin_token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ______                      __     ____        __       
//    / ____/  ______  ____  _____/ /_   / __ \____ _/ /_____ _
//   / __/ | |/_/ __ \/ __ \/ ___/ __/  / / / / __ `/ __/ __ `/
//  / /____>  </ /_/ / /_/ / /  / /_   / /_/ / /_/ / /_/ /_/ / 
// /_____/_/|_/ .___/\____/_/   \__/  /_____/\__,_/\__/\__,_/  
//           /_/                                               

export function* watchAdminExportData() {
    yield takeLatest("ADMIN_EXPORT_DATA", trackAdminExportData);
}

function* trackAdminExportData(sagaData) {
    try {
        var response = yield call(callAdminExportData, sagaData.payload);

        if (response) {
            yield put({
                type: "ADMIN_EXPORT_DATA_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "ADMIN_EXPORT_DATA_ERROR",
            response: err
        });
    }
}

function callAdminExportData(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/admin/export-data", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("admin_token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     __    _      __     ______      _ __         __   ______                                 __  _                 
//    / /   (_)____/ /_   / ____/___ _(_) /__  ____/ /  /_  __/________ _____  _________ ______/ /_(_)___  ____  _____
//   / /   / / ___/ __/  / /_  / __ `/ / / _ \/ __  /    / / / ___/ __ `/ __ \/ ___/ __ `/ ___/ __/ / __ \/ __ \/ ___/
//  / /___/ (__  ) /_   / __/ / /_/ / / /  __/ /_/ /    / / / /  / /_/ / / / (__  ) /_/ / /__/ /_/ / /_/ / / / (__  ) 
// /_____/_/____/\__/  /_/    \__,_/_/_/\___/\__,_/    /_/ /_/   \__,_/_/ /_/____/\__,_/\___/\__/_/\____/_/ /_/____/  
                                                                                                                   
export function* watchAdminListFailedTransactions() {
    yield takeLatest("ADMIN_LIST_FAILED_TRANSACTIONS", trackAdminListFailedTransactions);
}

function* trackAdminListFailedTransactions(sagaData) {
    try {
        var response = yield call(callAdminListFailedTransactions, sagaData.payload);

        if (response) {
            yield put({
                type: "ADMIN_LIST_FAILED_TRANSACTIONS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "ADMIN_LIST_FAILED_TRANSACTIONS_ERROR",
            response: err
        });
    }
}

function callAdminListFailedTransactions(saga) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/admin/list-failed-transactions", {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("admin_token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     __    _      __     ______                      ____         __   ______                                 __  _                 
//    / /   (_)____/ /_   / ____/___ _____  ________  / / /__  ____/ /  /_  __/________ _____  _________ ______/ /_(_)___  ____  _____
//   / /   / / ___/ __/  / /   / __ `/ __ \/ ___/ _ \/ / / _ \/ __  /    / / / ___/ __ `/ __ \/ ___/ __ `/ ___/ __/ / __ \/ __ \/ ___/
//  / /___/ (__  ) /_   / /___/ /_/ / / / / /__/  __/ / /  __/ /_/ /    / / / /  / /_/ / / / (__  ) /_/ / /__/ /_/ / /_/ / / / (__  ) 
// /_____/_/____/\__/   \____/\__,_/_/ /_/\___/\___/_/_/\___/\__,_/    /_/ /_/   \__,_/_/ /_/____/\__,_/\___/\__/_/\____/_/ /_/____/  
                                                                                                                                   
export function* watchAdminListCancelledTransactions() {
    yield takeLatest("ADMIN_LIST_CANCELLED_TRANSACTIONS", trackAdminListCancelledTransactions);
}

function* trackAdminListCancelledTransactions(sagaData) {
    try {
        var response = yield call(callAdminListCancelledTransactions, sagaData.payload);

        if (response) {
            yield put({
                type: "ADMIN_LIST_CANCELLED_TRANSACTIONS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "ADMIN_LIST_CANCELLED_TRANSACTIONS_ERROR",
            response: err
        });
    }
}

function callAdminListCancelledTransactions(saga) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/admin/list-cancelled-transactions", {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("admin_token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     __  ___                                ______                      ____         __   ____           __              
//    /  |/  /___ _____  ____ _____ ____     / ____/___ _____  ________  / / /__  ____/ /  / __ \_________/ /__  __________
//   / /|_/ / __ `/ __ \/ __ `/ __ `/ _ \   / /   / __ `/ __ \/ ___/ _ \/ / / _ \/ __  /  / / / / ___/ __  / _ \/ ___/ ___/
//  / /  / / /_/ / / / / /_/ / /_/ /  __/  / /___/ /_/ / / / / /__/  __/ / /  __/ /_/ /  / /_/ / /  / /_/ /  __/ /  (__  ) 
// /_/  /_/\__,_/_/ /_/\__,_/\__, /\___/   \____/\__,_/_/ /_/\___/\___/_/_/\___/\__,_/   \____/_/   \__,_/\___/_/  /____/  
//                          /____/                                                                                         

export function* watchAdminManageCancelledTransactions() {
    yield takeLatest("ADMIN_MANAGE_CANCELLED_TRANSACTIONS", trackAdminManageCancelledTransactions);
}

function* trackAdminManageCancelledTransactions(sagaData) {
    try {
        var response = yield call(callAdminManageCancelledTransactions, sagaData.payload);

        if (response) {
            yield put({
                type: "ADMIN_MANAGE_CANCELLED_TRANSACTIONS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "ADMIN_MANAGE_CANCELLED_TRANSACTIONS_ERROR",
            response: err
        });
    }
}

function callAdminManageCancelledTransactions(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/admin/manage-cancelled-transactions", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("admin_token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}