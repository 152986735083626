import React from "react";
import { useHistory } from "react-router-dom";

function AdminSidebar() {
    const history = useHistory();

    const moveTo = (page) => {
        history.push(page);
    }

    return (
        <aside className="menu sidebar">
            <div className="is-flex is-flex-direction-column h-full sidebar-in">
                <div className="navbar-brand is-hidden-mobile is-hidden-tablet-only has-background-primary">
                    <div className="navbar-item brand-text mx-auto" onClick={() => history.push('/dashboard')}>
                        <img src="/images/logo.png" alt="" /> <span className="has-text-white is-size-4">Shop Share Paid</span>
                    </div>
                </div>
                <div className="p-0">
                    <p className="menu-label px-4 has-text-white mt-1"> General </p>
                    <ul className="menu-list">
                        <li className={`${window.location.href.includes('dashboard') ? 'active' : ''}`}><a onClick={() => moveTo('/admin/dashboard')}><span className="icon-text"><span className="icon"><i className="fa fa-home"></i></span><span>Home</span></span></a></li>
                        <li className={`${window.location.href.includes('plans') ? 'active' : ''}`}><a onClick={() => moveTo('/admin/plans')}><span className="icon-text"><span className="icon"><i className="fa fa-file-text-o"></i></span><span>Plans</span></span></a></li>
                        <li className={`${window.location.href.includes('payouts') ? 'active' : ''}`}><a onClick={() => moveTo('/admin/payouts')}><span className="icon-text"><span className="icon"><i className="fa fa-dollar"></i></span><span>Payouts</span></span></a></li>
                    </ul>
                    <p className="menu-label px-4 has-text-white mt-5"> Customers </p>
                    <ul className="menu-list">
                        <li className={`${window.location.href.includes('users') ? 'active' : ''}`}><a onClick={() => moveTo('/admin/users')}><span className="icon-text"><span className="icon"><i className="fa fa-user"></i></span><span>Users</span></span></a></li>
                    </ul>
                    <p className="menu-label px-4 has-text-white mt-5"> Shopify Export </p>
                    <ul className="menu-list">
                        <li className={`${window.location.href.includes('export-data') ? 'active' : ''}`}><a onClick={() => moveTo('/admin/export-data')}><span className="icon-text"><span className="icon"><i className="fa fa-refresh"></i></span><span>Export Customers/Orders</span></span></a></li>
                    </ul>
                </div>
                <div className="mt-auto px-2 mb-4">
                    <ul className="menu-list">
                        <li className="my-5">
                            <img className="image max-w-140 mx-auto" src={`${process.env.REACT_APP_APP_ENDPOINT}/images/affiliate-market-graphic.svg`} alt="Affiliate MArketing" />
                        </li>
                        <li>
                            <a className="is-flex p-2" href="https://paytron.com.au/" target="_blank" rel="noopener noreferrer">
                                <img className="image max-w-80 mx-auto" src={`${process.env.REACT_APP_APP_ENDPOINT}/images/paytron-logo.png`} alt="paytron logo" />
                            </a>
                        </li>
                        <li className="pb-4">
                            <a className="is-flex p-0" href="mailto:contact@shopsharepaid.com" target="_blank" rel="noopener noreferrer">
                                <span className="icon-text">
                                    <span className="icon"><i className="fa fa-question-circle"></i></span>
                                    <span>Help</span>
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </aside>
    )
}

export default AdminSidebar;