import React, { useContext } from "react";

import { Loader, LoaderContext } from "../loader";

function AuthLayout(props) {
    const { loading } = useContext(LoaderContext);

    return (
        <React.Fragment>
            <Loader loading={loading} />
            <div className="container">
                <div className="columns is-centered">
                    <div className="column is-8" style={{position:"relative", height: "100vh"}}>
                        {props.children}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AuthLayout;