import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Pagination from "react-js-pagination";
import { isEmpty } from "lodash";
import Swal from "sweetalert2";

import AffiliateLayout from '../layouts/affiliate-layout';

function Stores(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const authProps = useSelector(state => state.auth);
    const storeProps = useSelector(state => state.stores);
    const [stores, setStores] = useState({});

    // Pagination
    const limit = 30;
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        PullData();
    }, [page]);

    useEffect(() => {
        if (!isEmpty(storeProps)) {
            setStores(storeProps.results);
            setTotal(storeProps.total);
        }
    }, [storeProps]);

    function PullData() {
        dispatch({
            type: "AFFILIATE_FETCH_STORES",
            payload: {
                page: page - 1,
                limit: limit
            }
        });
    }

    function SendRequest(store) {
        Swal.fire({
            title: `Request to join '${store?.company === "" || store.company === undefined ? store?.shop_details?.name : store?.company}' affiliate program?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: "No"
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch({
                    type: "AFFILIATE_SEND_REQUEST",
                    payload: {
                        shop: store.shop
                    }
                });
            }
        });
    }

    return (
        <AffiliateLayout {...props}>
            <div className="columns is-multiline">
                <div className="column is-12">
                    <div className="message is-info mb-3">
                        <div className="message-body">
                            Become an affiliate of other stores and increase your revenue fast. <br />
                            Once the store approves your request you will see the store name and discount code under the <a className="has-text-danger has-text-weight-bold" onClick={() => history.push('/affiliate/coupons')}>Get Started</a> section
                        </div>
                    </div>
                </div>
            </div>

            <div className="columns is-multiline">
                {
                    !isEmpty(stores) && Object.values(stores).map((store, index) => {
                        return (
                            <div className="column is-3" key={index}>
                                <div className="box">
                                    <img className="border border-1 border-solid border-color-white-bis" src={`${process.env.REACT_APP_API_ENDPOINT}/images/${store?.logo}`} />
                                    <div className="mt-2 mb-3">
                                        <p className="is-size-5m6"><span className="has-text-weight-bold">Name : </span>{store?.company === "" || store?.company === undefined ? store?.shop_details?.name : store?.company}</p>
                                        <p className="is-size-5m6"><span className="has-text-weight-bold">State : </span>{store?.state}</p>
                                        <p className="is-size-5m6"><span className="has-text-weight-bold">Country : </span>{store?.country}</p>
                                        <p className="is-size-5m6"><span className="has-text-weight-bold">Store Url : </span><a className="is-link" href={`https://${store.shop}`} target="_blank">Link</a></p>
                                    </div>
                                    <button className="button is-primary w-full" onClick={() => SendRequest(store)}>Join affiliate program</button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <div className="is-flex is-justify-content-space-between">
                <div>Showing <b>{stores.length}</b> of <b>{total}</b> Stores</div>
                <Pagination activePage={page} itemsCountPerPage={limit} totalItemsCount={total} pageRangeDisplayed={5} onChange={(e) => setPage(e)} />
            </div>
        </AffiliateLayout>
    )
}

export default Stores;