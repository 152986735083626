import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import moment from "moment";
import Swal from "sweetalert2";

import { LoaderContext } from '../loader';
import DashboardLayout from '../layouts/dashboard-layout';

function PremiumPopulateAffiliates(props) {
    const dispatch = useDispatch();
    const { setLoading } = useContext(LoaderContext);
    const authProps = useSelector(state => state.auth);
    const customerProps = useSelector(state => state.customers);
    const countProps = useSelector(state => state.count);
    const [customers, setCustomers] = useState({});
    const [pagination, setPagination] = useState({});
    const [searchPage, setSearchPage] = useState('');
    const [selectedCount, setSelectedCount] = useState(0);

    useEffect(() => {
        setLoading(true);
        dispatch({
            type: "FETCH_CUSTOMERS_COUNT",
            payload: {}
        });
    }, []);

    useEffect(() => {
        setLoading(true);
        dispatch({
            type: "FETCH_CUSTOMERS",
            payload: {
                limit: 20,
                page: searchPage
            }
        });
    }, [searchPage]);

    useEffect(() => {
        if (!isEmpty(customerProps)) {
            setLoading(false);
            setCustomers(customerProps.customers);
            setPagination(customerProps.pagination);
        }
    }, [customerProps]);

    function SelectCustomers(e) {
        let customerCheckboxes = document.getElementsByName('customers_checkbox');
        let count = 0;
        for (let i = 0; i < customerCheckboxes.length; i++) {
            if (e.target.checked) {
                count++;
                customerCheckboxes[i].checked = true;
            } else {
                customerCheckboxes[i].checked = false;
            }
        }

        setSelectedCount(count);
    }

    function checkedCustomers() {
        let customerCheckboxes = document.getElementsByName('customers_checkbox');
        let count = 0;

        for (let i = 0; i < customerCheckboxes.length; i++) {
            if (customerCheckboxes[i].checked) {
                count++;
            }
        }

        setSelectedCount(count);
    }

    function PopulateSelectedCustomers() {
        if (authProps?.plan_details.name === "Enterprise") {
            setLoading(true);

            let customerCheckboxes = document.getElementsByName('customers_checkbox');
            let checkedIndexes = [];

            for (let i = 0; i < customerCheckboxes.length; i++) {
                if (customerCheckboxes[i].checked) {
                    checkedIndexes.push({
                        id: customers[i].id,
                        last_order_id: customers[i].last_order_id,
                        firstname: customers[i].first_name,
                        lastname: customers[i].last_name,
                        email: customers[i].email,
                        address: customers[i].default_address.address1,
                        city: customers[i].default_address.city,
                        state: customers[i].default_address.province,
                        country: customers[i].default_address.country,
                        country_code: customers[i].default_address.country_code,
                        postal_code: customers[i].default_address.zip
                    });
                }
            }

            if (checkedIndexes.length > 0) {
                dispatch({
                    type: "CREATE_AFFILIATE",
                    payload: checkedIndexes
                });

                setTimeout(() => {
                    setLoading(false);

                    Swal.fire({
                        icon: "success",
                        title: "Request Successfull",
                        html: "Affiliates are being popuplated, this may take a few minutes.",
                        timer: 4000,
                        showConfirmButton: false
                    });
                }, 3000);
            } else {
                setLoading(false);

                Swal.fire({
                    icon: "error",
                    html: "Please select some customers from list below before continuing."
                });
            }
        } else {
            Swal.fire({
                icon: "error",
                title: "Back Populate Users",
                html: "This feature is only available on the Enterprise Plan. Please upgrade your plan if you wish to use this feature."
            });
        }
    }

    function PopulateAllCustomers() {
        if (authProps?.plan_details.name !== "Enterprise") { 
            Swal.fire({
                icon: "error",
                title: "Back Populate Users",
                html: "This feature is only available on the Enterprise Plan. Please upgrade your plan if you wish to use this feature."
            });
        } else {
            Swal.fire({
                title: "Do you wish to proceed?",
                html: `Your premium back populate request will be submitted to your account manager. `,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Proceed',
                cancelButtonText: "Cancel"
            }).then((result) => {
                if (result.isConfirmed) {
                    setLoading(true);
                    dispatch({
                        type: "CREATE_AFFILIATE_QUEUE",
                        payload: {}
                    });
    
                    setTimeout(() => {
                        Swal.fire({
                            icon: "success",
                            title: "Request Successful",
                            html: "We have received your premium Back Populate request and your account manager will contact you shortly to get this actioned.",
                            timer: 10000,
                            showConfirmButton: false
                        });
    
                        setLoading(false);
                    }, 2000);
                }
            });
        }
    }

    return (
        <DashboardLayout {...props} >
            <section className="pb-3">
                <div className="is-md-flex is-align-items-center is-justify-content-space-between">
                    <div className="buttons mb-0">
                        <h1 className="title is-size-4 is-size-4-tablet mb-2 has-text-primary"> Back Populate </h1>
                        <label className="checkbox mb-0 btn-select-all has-text-weight-medium button b-0 ml-5 custom-checkbox pr-4 has-background-white-bis">
                            <input type="checkbox" name="select_all" onChange={(e) => SelectCustomers(e)} /> Select all
                            <span className="checkmark"></span>
                        </label>
                        <button className="button is-info-dark with-shadow mb-0" onClick={() => PopulateSelectedCustomers()}>Back Populate Selected Users ({selectedCount})</button>
                    </div>
                    <div className="buttons mb-0">
                        <button className="button is-primary with-shadow mb-0" onClick={() => PopulateAllCustomers()}>Populate All Users</button>
                    </div>
                </div>
                <p className="pt-4 is-size-5m6 mb-4"> Here you can back populate all your existing customers into affiliates. This process can take a few hours to a few days depending on the number of customers you want to back populate.</p>
            </section>

            <div className="is-flex is-justify-content-space-between is-align-items-center mb-4">
                <div className="pb-2">
                    Showing <b>{customers?.length}</b> of <b>{countProps?.count}</b> customers
                </div>

                <div>
                    <button className={`button ${pagination?.previous === "" ? "" : "is-primary"}`} disabled={pagination?.previous === "" ? "disabled" : ""} onClick={() => setSearchPage(pagination.previous)}>
                        <span className="icon is-small">
                            <i className="fa fa-chevron-left"></i>
                        </span>
                    </button>

                    <button className={`button ${pagination?.next === "" ? "" : "is-primary"}`} disabled={pagination?.next === "" ? "disabled" : ""} onClick={() => setSearchPage(pagination.next)}>
                        <span className="icon is-small">
                            <i className="fa fa-chevron-right"></i>
                        </span>
                    </button>
                </div>
            </div>

            <div className="columns is-multiline">
                <div className="column is-12">
                    <div className="box">
                        <div className="table-container">
                            <table className="table is-striped is-fullwidth">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>First name</th>
                                        <th>Last name</th>
                                        <th>Email Address</th>
                                        <th>Status</th>
                                        <th>Created At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !isEmpty(customers) && Object.values(customers).map((customer, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    {
                                                        customer.first_name === "" ? "" : <tr>
                                                            <td><label className="custom-checkbox"><input type="checkbox" name="customers_checkbox" onChange={() => checkedCustomers()} /><span className="checkmark"></span></label></td>
                                                            <td>{customer?.first_name}</td>
                                                            <td>{customer?.last_name}</td>
                                                            <td>{customer?.email}</td>
                                                            <td>{customer?.tags.includes('affiliate') ? <span className="tag is-success is-light has-text-weight-semibold border border-solid border-color-success">Affiliate</span> : <span className="tag is-danger is-light has-text-weight-semibold border border-solid border-color-danger">Not an Affiliate</span>}</td>
                                                            <td>{moment(customer?.created_at).format('D MMM, YYYY')}</td>
                                                        </tr>
                                                    }
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className="is-flex is-justify-content-space-between is-align-items-center">
                <div className="pb-2">
                    Showing <b>{customers?.length}</b> of <b>{countProps?.count}</b> customers
                </div>

                <div>
                    <button className={`button ${pagination?.previous === "" ? "" : "is-primary"}`} disabled={pagination?.previous === "" ? "disabled" : ""} onClick={() => setSearchPage(pagination.previous)}>
                        <span className="icon is-small">
                            <i className="fa fa-chevron-left"></i>
                        </span>
                    </button>

                    <button className={`button ${pagination?.next === "" ? "" : "is-primary"}`} disabled={pagination?.next === "" ? "disabled" : ""} onClick={() => setSearchPage(pagination.next)}>
                        <span className="icon is-small">
                            <i className="fa fa-chevron-right"></i>
                        </span>
                    </button>
                </div>
            </div>
        </DashboardLayout>
    )
}

export default PremiumPopulateAffiliates;